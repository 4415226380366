import * as React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import moment from "moment";

import store from "./store";

import Menu from "./components/structure/Menu";
import Nav from "./components/structure/Nav";

// get the screens
import Home from "./components/screens/Home";
import ProfileScreen from "./components/screens/User/ProfileScreen";
import ManageSubscriptionScreen from "./components/screens/Subscription/ManageSubscriptionScreen";
import Dashboard from "./components/screens/Dashboard/Dashboard";
import Terms from "./components/screens/Terms";

import Login from "./components/screens/Login/Login";
import Signup from "./components/screens/Login/Signup";
import SignupVerify from "./components/screens/Login/SignupVerify";
import ResetPassword from "./components/screens/Login/ResetPassword";
import ResetPasswordVerify from "./components/screens/Login/ResetPasswordVerify";

import ContactsScreen from "./components/screens/Contacts/ContactsScreen";
import ContactDetailsScreen from "./components/screens/Contacts/ContactDetailsScreen";

import ActivitiesList from "./components/screens/Activities/ActivitiesList";

import ExportsScreen from "./components/screens/Exports/ExportsScreen";
import ImportsScreen from "./components/screens/Imports/ImportsScreen";

import MessagesScreen from "./components/screens/Messages/MessagesScreen";

import TrackingItems from "./components/screens/Reminders/RemindersScreen";

import ReportsScreen from "./components/screens/Reports/ReportsScreen";

import PaymentsScreen from "./components/screens/Payments/PaymentsScreen";

import MilestonesScreen from "src/components/screens/Milestones/MilestonesScreen";

import VerbatimsScreen from "src/components/screens/Verbatims/VerbatimsScreen";
import VerbatimEditScreen from "src/components/screens/Verbatims/VerbatimEditScreen";

import IntegrationsScreen from "src/components/screens/Integrations/IntegrationsScreen";

import ConnectionsScreen from "src/components/screens/Connections/ConnectionsScreen";
import ManageConnectionScreen from "src/components/screens/Connections/ManageConnectionScreen";
import ToolViewScreen from "src/components/screens/Connections/ToolView";

import PageSettings from "src/components/screens/User/PageSettings";

import Branding from "./components/screens/User/Branding";

import Referrals from "./components/screens/User/Referrals";

class App extends React.Component {


  public componentDidMount() {
    (window as any).Stripe.setPublishableKey(process.env.REACT_APP_STRIPE || "pk_test_PoPRki09y2bz26nET2u0Xo7o00If03A9c2");
  }

  public render() {
    return (
      <Provider store={store}>
        <Router>
          <Menu>
            <div className="container-fluid">
              <Nav />
              <div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-10 offset-1 justify-content-center">
                    <main>
                      <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route exact={true} path="/terms" component={Terms} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/signup" component={Signup} />
                        <Route exact={true} path="/verify" component={SignupVerify} />
                        <Route exact={true} path="/reset" component={ResetPassword} />
                        <Route exact={true} path="/reset/verify" component={ResetPasswordVerify} />
                        <Route exact={true} path="/dashboard" component={Dashboard} />
                        <Route exact={true} path="/dashboard/:view" component={Dashboard} />

                        <Route exact={true} path="/me" component={ProfileScreen} />
                        <Route exact={true} path="/subscription" component={ManageSubscriptionScreen} />

                        <Route exact={true} path="/contacts" component={ContactsScreen} />
                        <Route exact={true} path="/contacts/:id" component={ContactDetailsScreen} />
                        <Route exact={true} path="/contacts/:id/:view" component={ContactDetailsScreen} />

                        <Route exact={true} path="/activities" component={ActivitiesList} />

                        <Route exact={true} path="/exports" component={ExportsScreen} />
                        <Route exact={true} path="/imports" component={ImportsScreen} />

                        <Route exact={true} path="/messages" component={MessagesScreen} />
                        <Route exact={true} path="/messages/:messageId" component={MessagesScreen} />

                        <Route exact={true} path="/tracking" component={TrackingItems} />
                        <Route exact={true} path="/reminders" component={TrackingItems} />

                        <Route exact={true} path="/reports" component={ReportsScreen} />
                      
                        <Route exact={true} path="/payments" component={PaymentsScreen} />

                        <Route exact={true} path="/milestones" component={MilestonesScreen} />

                        <Route exact={true} path="/verbatims" component={VerbatimsScreen} />
                        <Route exact={true} path="/verbatims/:id" component={VerbatimEditScreen} />

                        <Route exact={true} path="/integrations" component={IntegrationsScreen} />
                        <Route exact={true} path="/integrations/todoist" component={IntegrationsScreen} />

                        <Route exact={true} path="/connections" component={ConnectionsScreen} />
                        <Route exact={true} path="/connections/client/:clientId" component={ManageConnectionScreen} />
                        <Route exact={true} path="/connections/client/:clientId/:toolType/submissions/:submissionId" component={ToolViewScreen} />

                        <Route exact={true} path="/page/settings" component={PageSettings} />
                        <Route exact={true} path="/branding" component={Branding} />

                        <Route exact={true} path="/referrals" component={Referrals} />

                      </Switch>
                    </main>
                  </div>
                </div>

                <footer>
                  <div className="row" style={{marginTop: 20}}>
                    <div className="col-12" style={{ "textAlign": "center" }}>
                      <p className="small">Copyright 2010-{moment().format("YYYY")} <a href="https://www.kvsstechnologies.com" target="_new">KVSS Technologies, LLC</a></p>
                      <p className="small">Use of this application constitutes agreement with our <a href="/terms" target="_new">Terms of Service and Privacy Policy</a></p>
                    </div>
                  </div>
                </footer>
              </div>


              <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={true} />
            </div>
          </Menu>
        </Router>
      </Provider>
    );
  }
}

export default App;
