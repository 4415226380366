import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { formatCurrency } from "../../../utils/currency";
import { QuotaAPI } from "../../../api";
import { IQuota, QuotaBlank, IPaymentHistory, PaymentHistoryBlank, IPlan, PlanBlank } from "../../../api/quota";
import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { success, error } from "src/components/structure/Alert";

interface IPaymentsScreenProps {
  appActions: any;
  history: any;
}

interface IPaymentsScreenState {
  loading: boolean;
  plan: IPlan;
  quota: IQuota;
  history: IPaymentHistory[];
  selectedHistory: IPaymentHistory;
}

class PaymentsScreen extends React.Component<IPaymentsScreenProps, IPaymentsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      plan: PlanBlank,
      quota: QuotaBlank,
      history: [],
      selectedHistory: PaymentHistoryBlank,
    };

    this.fetchHistory = this.fetchHistory.bind(this);
    this.fetchSubscription = this.fetchSubscription.bind(this);
    this.fetchQuota = this.fetchQuota.bind(this);
    this.resendHistory = this.resendHistory.bind(this);
  }

  componentDidMount(){
    this.fetchSubscription();
  }

  public render() {
    return (
      <div id="payments_screen" className="row">
        <div className="col-lg-8 offset-lg-2">
          <Card title="Payments" loading={this.state.loading} help="">
            {this.state.history.map((history: IPaymentHistory) => {
              return (
                <div className="row" key={history.id} style={{marginBottom: 5}}>
                  <div className="col-md-3">
                    {moment(history.attemptDate).format("MM/DD/YYYY")}
                  </div>
                  <div className="col-md-2">
                    {formatCurrency(history.amount)}
                  </div>
                  <div className="col-md-2">
                    {history.paymentType === "subscription" ? "Subscription" : "SMS Credits"}
                  </div>
                  <div className="col-md-3">
                    {this.displayStatus(history.result)}
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-block btn-primary" onClick={() => this.resendHistory(history.id)}>Resend Receipt</button>
                  </div>
                </div>
              );
            })}
          </Card>
        </div>

      </div>
    );
  }

  private fetchSubscription(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await QuotaAPI.getCurrentSubscription();
        this.setState({
          plan: result.body.data.plan
        }, () => {
          this.fetchQuota();
        });
      }catch(err){

      }
    });
  }

  private fetchQuota(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await QuotaAPI.getUserQuota();
        this.setState({
          quota: result.body.data,
        }, () => {this.fetchHistory(); });
      }catch(err){

      }
    });
  }

  private fetchHistory(){
    this.setState({ loading: true }, async () => {
      try{
        const start = "";
        const end = "";
        const result = await QuotaAPI.getPaymentHistory(start, end);
        this.setState({
          loading: false, 
          history: result.body.data,
        });
      }catch(err){

      }
    });
  }

  private displayStatus(status: string): any {
    if(status === "success"){
      return (<span className="text-success" style={{fontWeight: "bold"}}>Success</span>);
    } else if(status === "failure") {
      return (<span className="text-danger" style={{fontWeight: "bold"}}>Failed</span>);
    }

    return (<span />);
  }

  private resendHistory(historyId: number){
    this.setState({ loading: true }, async () => {
      try{
        await QuotaAPI.resendHistoryEmail(historyId);
        this.setState({ loading: false}, () => {
          success("Email resent!");
        });
      }catch(err){
        this.setState({ loading: false}, () => {
          error("Could not resend the email. Please contact support.");
        });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsScreen) as any);