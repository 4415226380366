import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";

import * as AppActions from "../../reducers/appReducer";

interface ILineGraphDataPoint {
  count: number;
  name?: string;
}

interface ILineGraphProps {
  appActions: any;
  data: ILineGraphDataPoint[];
}

interface ILineGraphState {
  loading: boolean;
}

class LineGraph extends React.Component<ILineGraphProps, ILineGraphState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };

  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <LineChart data={this.props.data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
          <Line type="monotone" dataKey="count" stroke="#8884d8" />
          <YAxis />
          <Tooltip />
          <XAxis dataKey="name" />
        </LineChart>
      </ResponsiveContainer>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineGraph) as React.ComponentType<any>);