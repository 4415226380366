import * as React from "react";
import LineGraph  from "src/components/structure/LineGraph";

import Card from "src/components/structure/Card";
import moment from "moment";

interface dataPoint {
  name: string;
  count: number;
}

interface INewToolTrendsProps {
  clientId: number;
  submissions: any[];
}

interface INewToolTrendsState {
  loading: boolean;
  getTogether: dataPoint[];
  engageInActivities: dataPoint[];
  refresh: dataPoint[];
  coreBeliefsHelpfulness: dataPoint[];
  stressSupport: dataPoint[];
  moralAndEthics: dataPoint[];
  stopAndThink: dataPoint[];
  allegiance: dataPoint[];
  caring: dataPoint[];
  profoundExperience: dataPoint[];
}

export default class NewToolTrends extends React.Component<INewToolTrendsProps, INewToolTrendsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      getTogether: [],
      engageInActivities: [],
      refresh: [],
      coreBeliefsHelpfulness: [],
      stressSupport: [],
      moralAndEthics: [],
      stopAndThink: [],
      allegiance: [],
      caring: [],
      profoundExperience: [],
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Card title="" loading={this.state.loading} help="">
        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="getTogether">1. How often do you get together with other people in wholesome activities outside of work? </label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.getTogether} />
          </div>
        </div>
        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="engageInActivities">2. How often do you engage in activities that build the human spirit?</label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.engageInActivities} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="refresh">3. How much do these kinds of activities help refresh you?</label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.refresh} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="coreBeliefsHelpfulness">4. Life brings big questions. <em> (Who am I? Why am I here? What is my purpose in life? What happens after I die? Why is there evil and suffering? etc.) </em> How helpful are your core beliefs or values in giving meaning and purpose to your life?</label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.coreBeliefsHelpfulness} />            
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="stressSupport">5. How much do your core beliefs or values provide you support in times of stress? </label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.stressSupport} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="moralAndEthics">6. How much do your core beliefs or values influence your moral and ethical decision making? </label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.moralAndEthics} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="stopAndThink">7. How much do your core beliefs or values encourage you to stop and think about who you are and who you are becoming? </label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.stopAndThink} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="allegiance">8. How much do your core beliefs or values build within you an allegiance to anyone or anything outside of yourself? (This could be God, nature, Country, Corps, community, Family, humanity, the greater good.)</label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.allegiance} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="caring">9. How much do your core beliefs or values encourage you to be caring, forgiving, patient, gentle, generous, selfless, kind? </label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.caring} />
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-2">
            <label htmlFor="profoundExperience">10. Have you ever been changed by an unusual or profound experience? (You might call this a spiritual crisis, conversion experience, mystical experience, exceptional human experience, sense of enlightenment, or a near-death experience.)</label>
          </div>
          <div className="col-12 col-lg-10">
            <LineGraph data={this.state.profoundExperience} />
          </div>
        </div>
      </Card>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      let currentState: any = this.state;
      for(const sub of this.props.submissions){
        const subDate = moment(sub.submittedOn).format("MM/DD/YY");
        currentState.getTogether.push({ name: subDate, count: sub.getTogether});
        currentState.engageInActivities.push({ name: subDate, count: sub.engageInActivities});
        currentState.refresh.push({ name: subDate, count: sub.refresh});
        currentState.coreBeliefsHelpfulness.push({ name: subDate, count: sub.coreBeliefsHelpfulness});
        currentState.stressSupport.push({ name: subDate, count: sub.stressSupport});
        currentState.moralAndEthics.push({ name: subDate, count: sub.moralAndEthics});
        currentState.stopAndThink.push({ name: subDate, count: sub.stopAndThink});
        currentState.allegiance.push({ name: subDate, count: sub.allegiance});
        currentState.caring.push({ name: subDate, count: sub.caring});
        currentState.profoundExperience.push({ name: subDate, count: sub.profoundExperience});
      }     
      currentState.loading = false;
      this.setState(currentState); 
    });
  }

}