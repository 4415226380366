import * as React from "react";
import { Link } from "react-router-dom";

import Card from "src/components/structure/Card";

interface ISubscriptionBlockProps {
  featureDisplay: string;
}

interface ISubscriptionBlockState {
}

export class SubscriptionBlock extends React.Component<ISubscriptionBlockProps, ISubscriptionBlockState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
        <Card title="Subscription Required"help="">
          {this.props.featureDisplay} requires an active subscription to use! An active subscription enables you to take your services to the next level. Learn all about the benefits of upgrading <Link to="/subscription">Here!</Link>
        </Card>
    );
  }

}