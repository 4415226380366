import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";

import { VerbatimsAPI } from "src/api";
import { BlankVerbatim, IVerbatim, IVerbatimPatient, IVerbatimEntry, IVerbatimMetaData } from "src/api/verbatims";
import VerbatimPatientEdit from "./VerbatimPatientEdit";
import VerbatimMetadataEdit from "./VerbatimMetadataEdit";
import VerbatimEntryList from "./VerbatimEntryList";
import Card from "src/components/structure/Card";
import { Translator } from "src/utils/translator";
import { error, success } from "src/components/structure/Alert";

interface IVerbatimEditScreenProps {
  appActions: any;
  match: any;
}

interface IVerbatimEditScreenState {
  loading: boolean;
  verbatim: IVerbatim;
  patients: IVerbatimPatient[];
  metadata: IVerbatimMetaData[];
  entries: IVerbatimEntry[];
  keyIncrement: number;
}

const statusHelp = Translator.getHelpText("en", "verbatimStatus");

class VerbatimEditScreen extends React.Component<IVerbatimEditScreenProps, IVerbatimEditScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      verbatim: BlankVerbatim,
      patients: [],
      metadata: [],
      entries: [],
      keyIncrement: 0,
    };

    this.updateVerbatimField = this.updateVerbatimField.bind(this);
    this.fetchVerbatim = this.fetchVerbatim.bind(this);
    this.handleCreateNewPatient = this.handleCreateNewPatient.bind(this);
    this.handleUpdatePatient = this.handleUpdatePatient.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);

    this.handleUpdateMetadata = this.handleUpdateMetadata.bind(this);
    this.handleCreateNewMetadata = this.handleCreateNewMetadata.bind(this);
    this.handleDeleteMetadata = this.handleDeleteMetadata.bind(this);

    this.handleCreateNewEntry = this.handleCreateNewEntry.bind(this);
    this.handleUpdatedEntry = this.handleUpdatedEntry.bind(this);
    this.handleDeletedEntry = this.handleDeletedEntry.bind(this);
    this.handleSetEntries = this.handleSetEntries.bind(this);
  }

  componentDidMount(){
    this.fetchVerbatim();
  }

  public render() {
    return (
      <Screen fileName="VerbatimEditScreen.tsx">
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <div className="col-12">
                <Card title="Status" help={statusHelp} loading={this.state.loading}>
                  <div className="form-group">
                    <label htmlFor="status">Current Status</label>
                    <select className="form-control" id="status" value={this.state.verbatim.status} onChange={this.updateVerbatimField}>
                      <option value="draft">Draft / In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="archived">Archived</option>
                    </select>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row" style={{marginTop: 15}}>
              <div className="col-lg-12">
                <VerbatimPatientEdit 
                  key={this.state.patients.length}
                  verbatimId={this.props.match.params.id}
                  patients={this.state.patients}
                  loading={this.state.loading}
                  onCreate={this.handleCreateNewPatient}
                  onUpdate={this.handleUpdatePatient}
                  onDelete={this.handleDeletePatient}
                />
              </div>
            </div>
            <div className="row" style={{marginTop: 15}}>
              <div className="col-lg-12">
                <VerbatimMetadataEdit
                  key={this.state.metadata.length}
                  verbatimId={this.props.match.params.id}
                  metadata={this.state.metadata}
                  loading={this.state.loading}
                  onCreate={this.handleCreateNewMetadata}
                  onUpdate={this.handleUpdateMetadata}
                  onDelete={this.handleDeleteMetadata}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            
            <VerbatimEntryList
              key={this.state.entries.length + this.state.keyIncrement + this.state.patients.length}
              loading={this.state.loading}
              verbatimId={this.props.match.params.id}
              entries={this.state.entries}
              patients={this.state.patients}

              onCreate={this.handleCreateNewEntry}
              onUpdate={this.handleUpdatedEntry}
              onDelete={this.handleDeletedEntry}
              onSetEntries={this.handleSetEntries}
            />
          </div>
        </div>
      </Screen>
    );
  }

  private updateVerbatimField(e: any){
    const verbatim = this.state.verbatim;
    const previousStatus = verbatim.status;
    verbatim[e.target.id] = e.target.value;
    this.setState({ verbatim }, async () => {
      try{
        await VerbatimsAPI.updateVerbatim(verbatim.id, this.state.verbatim);
        this.setState({ loading: false });
        success("Status updated!");
      }catch(err){
        error("Could not change the status of the verbatim.");
        verbatim.status = previousStatus;
        this.setState({ loading: false, verbatim });
      }
    });
  }

  private fetchVerbatim(){
    this.setState({ loading: true }, async () => {
      try{
        const vId = this.props.match.params.id;
        const verbatimResult = await VerbatimsAPI.getVerbatim(vId);
        const patientsResult = await VerbatimsAPI.getVerbatimPatients(vId);
        const metaResult = await VerbatimsAPI.getVerbatimMetadata(vId);
        const entriesResult = await VerbatimsAPI.getVerbatimEntries(vId);

        this.setState({
          loading: false,
          verbatim: verbatimResult.body.data,
          patients: patientsResult.body.data,
          metadata: metaResult.body.data,
          entries: entriesResult.body.data,
        })
      }catch(err){
        // wtf?
        this.setState({loading: false});
      }
    })
  }

  private handleCreateNewPatient(newPatient: IVerbatimPatient){
    const patients = this.state.patients;
    patients.push(newPatient);
    this.setState({patients});
  }

  private handleDeletePatient(deletedPatient: IVerbatimPatient){
    const patients: IVerbatimPatient[] = [];
    for(const p of this.state.patients){
      if(p.id !== deletedPatient.id){
        patients.push(p);
      }
    }
    this.setState({patients});
  }

  private handleUpdatePatient(updatedPatient: IVerbatimPatient){
    const patients: IVerbatimPatient[] = [];
    for(const p of this.state.patients){
      if(p.id === updatedPatient.id){
        patients.push(updatedPatient);
      }else{
        patients.push(p);
      }
    }
    this.setState({patients});
  }

  private handleCreateNewMetadata(field: string, value: string){
    const metadata = this.state.metadata;
    metadata.push({field, value, verbatimId: this.props.match.params.id});
    this.setState({metadata});
  }

  private handleDeleteMetadata(deletedField: string){
    const metadata: IVerbatimMetaData[] = [];
    for(const m of this.state.metadata){
      if(m.field !== deletedField){
        metadata.push(m);
      }
    }
    this.setState({metadata});
  }

  private handleUpdateMetadata(field: string, value: string){
    const metadata: IVerbatimMetaData[] = [];
    for(const m of this.state.metadata){
      if(m.field === field){
        metadata.push({field, value, verbatimId: this.props.match.params.id});
      }else{
        metadata.push(m);
      }
    }
    this.setState({metadata});
  }

  private handleCreateNewEntry(entry: IVerbatimEntry){
    const entries = this.state.entries;
    entries.push(entry);
    entries.sort((a, b) => {
      return a.verbatimOrder > b.verbatimOrder ? 1 : -1;
    });
    this.setState({entries});
  }

  private handleUpdatedEntry(entry: IVerbatimEntry){
    const entries: IVerbatimEntry[] = [];
    for(const e of this.state.entries){
      if(e.id === entry.id){
        entries.push(entry);
      } else {
        entries.push(e);
      }
    }
    entries.sort((a, b) => {
      return a.verbatimOrder > b.verbatimOrder ? -1 : 1;
    });
    this.setState({entries});
  }

  private handleDeletedEntry(entry: IVerbatimEntry){
    const entries: IVerbatimEntry[] = [];
    for(const e of this.state.entries){
      if(e.id !== entry.id){
        entries.push(e);
      }
    }
    this.setState({entries});
  }

  private handleSetEntries(entries: IVerbatimEntry[]){
    this.setState({keyIncrement: this.state.keyIncrement + 1, entries});
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerbatimEditScreen) as any);