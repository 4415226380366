import { makeCall } from "./client";
import moment from "moment";

// we are changing the end points to be called reminders
// since adblockers sometimes automatically block paths with `tracking` in the string

export interface IReminder {
  id: number;
  userId: number;
  contactId: "free" | "month" | "year";
  reminder: string;
  dateAdded: moment.Moment;
  nextReminder: moment.Moment;
  status: "open" | "closed";
  severity: "normal" | "severe" | "critical";
  frequency: "daily" | "weekly";
}

export class RemindersAPI {

  /**
   * Gets the user's reminders
   * @param otherData 
   */
  public getReminders(otherData: any = {}): Promise<any> {
    return makeCall("get", `reminders`, otherData);
  }

  /**
   * Creates a reminder
   * @param otherData 
   * @returns 
   */
  public createReminder(otherData: any = {}): Promise<any> {
    return makeCall("post", `reminders`, otherData);
  }

  /**
   * Updates a reminder
   * @param itemId 
   * @param otherData 
   * @returns 
   */
  public updateReminder(itemId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `reminders/${itemId}`, otherData);
  }

  /**
   * Deletes a reminder
   * @param itemId 
   * @param otherData 
   * @returns 
   */
  public deleteReminder(itemId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `reminders/${itemId}`, otherData);
  }
}