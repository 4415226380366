import * as React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { RemindersAPI } from "../../../api";
import { IReminder } from "../../../api/reminders";
import DatePicker from "../../structure/DatePicker";
import { error, success } from "../../structure/Alert";


interface IReminderListItemProps {
  reminder: IReminder;
  onDelete: any;
  onEdit: any;
}

interface IReminderListItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;

  reminder: string;
  nextReminder: moment.Moment;
  status: "all" | "open" | "closed";
  severity: "all" | "normal" | "severe" | "critical";
  frequency: "all" | "daily" | "weekly";
}

export default class ReminderListItem extends React.Component<IReminderListItemProps, IReminderListItemState> {

  constructor(props: IReminderListItemProps){
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
      showEditModal: false,
      reminder: props.reminder.reminder,
      nextReminder: moment(props.reminder.nextReminder),
      severity: props.reminder.severity,
      status: props.reminder.status,
      frequency: props.reminder.frequency,
    };

    this.updateField = this.updateField.bind(this);
    this.updateNextReminder = this.updateNextReminder.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  public render() {
    return (
      <div key={this.props.reminder.id} className="card activity-list-card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              {this.displaySeverity(this.props.reminder.severity)}
            </div>
            <div className="col-lg-3 col-sm-12">
              {this.props.reminder.status === "open" ? (<span>Open</span>) : (<span>Closed</span>)}
            </div>
            <div className="col-lg-5 col-sm-12">
              Added {this.props.reminder.dateAdded.format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="row" style={{marginTop: 10, marginBottom: 10}}>
            <div className="col-12">
              {this.props.reminder.reminder}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              Next Reminder on {this.props.reminder.nextReminder.format("MM/DD/YYYY")} (sent {this.props.reminder.frequency})
            </div>
            <div className="col-1">
              <span className="oi oi-pencil icon icon-primary" title="Edit Item" onClick={this.toggleEditModal} />
            </div>
            <div className="col-1">
              <span className="oi oi-x icon icon-danger" title="Delete Item" onClick={this.toggleDeleteModal} />
            </div>
          </div>
        </div>

        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Update Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="form-group">
              <label>Reminder</label>
              <input type="text" className="form-control" id="reminder" value={this.state.reminder} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Severity</label>
              <select className="form-control" id="severity" value={this.state.severity} onChange={this.updateField}>
                <option value="normal">Normal</option>
                <option value="severe">Severe</option>
                <option value="critical">Critical</option>
              </select>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select className="form-control" id="status" value={this.state.status} onChange={this.updateField}>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
            </div>
            <div className="form-group">
              <label>Frequency</label>
              <select className="form-control" id="frequency" value={this.state.frequency} onChange={this.updateField}>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
            <div className="form-group">
              <label>Next Reminder</label>
              <DatePicker 
                date={this.state.nextReminder}
                onDateSaved={this.updateNextReminder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.save}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete the following reminder? This cannot be undone! It is usually better to change the status to closed so you can keep track of past reminders.
            <p>{this.props.reminder.reminder}</p>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.delete}>Delete</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns)
  }

  private updateNextReminder(newDate: moment.Moment){
    this.setState({ nextReminder: newDate});
  }

  private displaySeverity(severity: string){
    switch(severity){
      case "normal":
        return (<span className="text-severity-normal">Normal</span>)
      case "severe":
        return (<span className="text-severity-severe">Severe</span>)
      case "critical":
        return (<span className="text-severity-critical">Critical</span>)
      default:
        return null;
    }
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private save(){
    if(this.state.reminder.trim() === ""){
      return error("Reminder cannot be blank");
    }
    const nextReminder = this.state.nextReminder;
    if(nextReminder.isBefore(moment().hour(23).minute(59).second(59))){
      return error("Next reminder must be in the future");
    }
    this.setState({ loading: true }, async () => {
      const data = {
        reminder: this.state.reminder,
        status: this.state.status,
        severity: this.state.severity,
        frequency: this.state.frequency,
        nextReminder: nextReminder.format("YYYY-MM-DD"),
      };

      try{
        const result = await RemindersAPI.updateReminder(this.props.reminder.id, data);
        const item = result.body.data;
        item.dateAdded = moment(item.dateAdded);
        item.nextReminder = moment(item.nextReminder);
        success("Item updated");
        this.setState({ showEditModal: false, loading: false });
        this.props.onEdit(result.body.data);
      }catch(e){
        error("Could not update that reminder.");
        this.setState({loading: false});
      }
    });
  }

  private delete(){
    this.setState({ loading: true }, async () => {
      try{
        await RemindersAPI.deleteReminder(this.props.reminder.id);
        success("Reminder deleted");
        this.setState({ showDeleteModal: false });
        this.props.onDelete(this.props.reminder);
      }catch(e){
        error("Could not delete that reminder. Contact support");
        this.setState({loading: false});
      }
    });
  }

}
