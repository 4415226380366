import { Typeahead } from "react-bootstrap-typeahead";
import * as React from "react";
import { connect } from "react-redux";

interface IContactSearchProps {
  contactsState: any;
  selectedContacts: any[];
  onSelect: any;
  multiple?: boolean;
}

interface IContactSearchState {
  loading: boolean;
  selectedContacts: any;
}

class ContactSearch extends React.Component<IContactSearchProps, IContactSearchState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      selectedContacts: [],
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    // find the contact
    let contacts: any = [];
    for(const sc of this.props.selectedContacts){
      for(const c of this.props.contactsState.contacts){
        if(sc.id === c.id){
          contacts.push(c);
          break;
        }
      }
    }
    this.setState({selectedContacts: contacts});
  }

  public render() {
    return (
      <Typeahead
        id="contact-search"
        key={this.props.contactsState.contacts.length}
        multiple={this.props.multiple ? true : false}
        placeholder="Enter Contact Name"
        onChange={this.handleSelect}
        selected={this.state.selectedContacts}
        options={this.props.contactsState.contacts} />
    );
  }

  private handleSelect(selectedContacts: any){
    if(this.props.multiple){
      this.props.onSelect(selectedContacts);
    } else {
      this.props.onSelect(selectedContacts[0]);
    }
    this.setState({selectedContacts})
  }


}


const mapStateToProps = function map(s: any) {
  return {
    contactsState: s.contactsState
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearch);