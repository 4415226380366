import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import { Translator } from "../../../utils/translator";
import { QuotaAPI } from "../../../api";
import { IPlan } from "../../../api/quota";
import { formatCurrency } from "../../../utils/currency";
import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { success, error } from "src/components/structure/Alert";
import moment from "moment";

interface ISubscriptionProps {
  appActions: any;
  history: any;
  plans?: IPlan[];
  title?: string;
}

interface ISubscriptionState {
  loading: boolean;
  plans: IPlan[];
  currentSubscriptionPlan: IPlan;
  monthlyPlan: IPlan;
  yearlyPlan: IPlan;
  selectedPlan: IPlan;
  showChangeModal: boolean;
  showCancelModal: boolean;
  showReactivateModal: boolean;
  upgradeStep: number;
  status: string;
  subscriptionPaidThrough: string;
  coupon: string;
}

const defaultPlan: IPlan = {
  id: 1,
  planName: "Unknown",
  term: "free",
  amount: 0,
  stripeId: "",
  active: true,
}
const subscriptionHelpText = Translator.getHelpText("en", "profileSubscription");

class Subscription extends React.Component<ISubscriptionProps, ISubscriptionState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      plans: [],
      currentSubscriptionPlan: defaultPlan,
      showChangeModal: false,
      showCancelModal: false,
      showReactivateModal: false,
      selectedPlan: defaultPlan,
      monthlyPlan: defaultPlan,
      yearlyPlan: defaultPlan,
      upgradeStep: 1,
      status: "free",
      subscriptionPaidThrough: "",
      coupon: "",
    };

    this.getPlans = this.getPlans.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleChangePlanModal = this.toggleChangePlanModal.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.changeSubscription = this.changeSubscription.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.toggleReactivateModal = this.toggleReactivateModal.bind(this);
    this.reactivateSubscription = this.reactivateSubscription.bind(this);
  }

  componentDidMount(){
    this.getPlans();
  }

  public render() {
    return (
      <Card title={this.props.title ? this.props.title : "Subscription"} loading={this.state.loading} help={subscriptionHelpText}>
        {this.state.status === "free" && (
          <div>
            <div className="form-group">
              You are currently on the <strong>Free</strong> plan. You can upgrade to unlock additional features, including:
              <ul>
                <li>Messages</li>
                <li>Clients</li>
                <li>Unlimited Verbatims</li>
                <li>Scheduled Exports</li>
                <li>3rd Party Integrations</li>
                <li>Landing Pages</li>
                <li>And more!</li>
              </ul>
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.toggleChangePlanModal}>Upgrade Now!</button>
            </div>
          </div>
        )} 
        {this.state.status === "subscribed" && (
          <div>
            <div className="form-group">
              You are currently on the <strong>Professional</strong> plan, paying {formatCurrency(this.state.currentSubscriptionPlan.amount)} each {this.state.currentSubscriptionPlan.term}. If you want
              to change your payment term to {this.state.currentSubscriptionPlan.term === "month" ? "a yearly " : "a monthly "} subscription, please contact support to properly pro-rate your new plan.
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-danger" onClick={this.toggleCancelModal}>Please Cancel My Subscription</button>
            </div>
          </div>
        )}
        {this.state.status === "cancelling" && (
          <div>
            <div className="form-group">
              You are currently on the <strong>Professional</strong> plan through {moment(this.state.subscriptionPaidThrough).format("MM/DD/YYYY")}. After that date, your account will revert to a free account.
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.toggleReactivateModal}>Please Reactivate My Subscription</button>
            </div>
          </div>
        )}
        

        <Modal show={this.state.showChangeModal} onHide={this.toggleChangePlanModal} size="lg">
          <Modal.Header closeButton={true}>
            <Modal.Title>Upgrade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p style={{fontSize: 18, color: "black"}}>Upgrading unlocks many new features and helps the platform continue to grow! You can choose to pay $4.99 each month or $49.99 for an entire year of benefits! You can cancel any time!</p>
            </div>
            <div className="form-group">
              <select className="form-control" id="selectedPlan" value={this.state.selectedPlan.id} onChange={this.selectPlan}>
                <option value={this.state.monthlyPlan.id}>Monthly, paying $4.99 each month</option>
                <option value={this.state.yearlyPlan.id}>Yearly, paying $49.99 each year</option>
              </select>
            </div>
            <div className="form-group">
              <p className="small">By clicking the subscribe button, you agree to be immediately charged {formatCurrency(this.state.selectedPlan.amount)} every {this.state.selectedPlan.term} to the payment method you have on file. You may cancel at any time. Refunds are not available for digital purchases. </p>
            </div>
            <div className="form-group">
              <input type="text" className="form-control" value={this.state.coupon} id="coupon" onChange={this.updateField} placeholder="Coupon Code, If Any" />
            </div>
            <div className="form-group">
              <button className="btn btn-success btn-block" onClick={this.changeSubscription}>Yes, Upgrade Me and Charge {formatCurrency(this.state.selectedPlan.amount)}</button>
              <button className="btn btn-block btn-secondary" onClick={this.toggleChangePlanModal}>Cancel</button>
            </div>
          </Modal.Body>
        </Modal>


        <Modal show={this.state.showCancelModal} onHide={this.toggleCancelModal} size="lg">
          <Modal.Header closeButton={true}>
            <Modal.Title>Cancel Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Warning: </strong>Canceling your subscription will put you back to the free plan after the subscription period ends.
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.cancelSubscription}>Cancel Subscription</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCancelModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showReactivateModal} onHide={this.toggleReactivateModal} size="lg">
          <Modal.Header closeButton={true}>
            <Modal.Title>Reactivate Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>By clicking the Reactivate Subscription button, you agree to be charged {formatCurrency(this.state.currentSubscriptionPlan.amount)} every {this.state.currentSubscriptionPlan.term} to the payment method you have on file. You may cancel at any time. Refunds are not available for digital purchases. </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-success" onClick={this.reactivateSubscription}>Reactivate Subscription</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCancelModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private getPlans(){
    this.setState({ loading: true }, async () => {
      try {
        let plans: IPlan[] = [];
        let selectedPlan: IPlan = defaultPlan;
        if(this.props.plans && this.props.plans.length > 0){
          plans = this.props.plans;
        } else {
          const plansResult = await QuotaAPI.getPlans();
          plans = plansResult.body.data;
        }
        const subscriptionResult = await QuotaAPI.getCurrentSubscription();
        const currentSubscriptionPlan = subscriptionResult.body.data.plan;
        const status = subscriptionResult.body.data.status;
        const subscriptionPaidThrough = subscriptionResult.body.data.subscriptionPaidThrough;
        

        let monthlyPlan: IPlan = defaultPlan;
        let yearlyPlan: IPlan = defaultPlan;
        for(const p of plans){
          if(p.term === "month"){
            monthlyPlan = p;
          }
          if(p.term === "year"){
            yearlyPlan = p;
          }
        }
        if(currentSubscriptionPlan.term === "free"){
          // set it to the monthly plan for the popup
          selectedPlan = monthlyPlan;
        }

        this.setState({
          loading: false,
          plans: plans,
          currentSubscriptionPlan,
          monthlyPlan,
          yearlyPlan,
          selectedPlan,
          status,
          subscriptionPaidThrough,
        });
      }catch(err){}
    });
  }

  private toggleChangePlanModal(){
    this.setState({ showChangeModal: !this.state.showChangeModal});
  }

  private toggleCancelModal(){
    this.setState({ showCancelModal: !this.state.showCancelModal});
  }

  private toggleReactivateModal(){
    this.setState({ showReactivateModal: !this.state.showReactivateModal});
  }

  private selectPlan(e: any){
    let plan = defaultPlan;
    const targetPlanId = parseInt(e.target.value, 10);
    for(const p of this.state.plans){
      if(p.id === targetPlanId) {
        plan = p;
      }
    }
    this.setState({ selectedPlan: plan });
  }

  private changeSubscription(){
    this.setState({loading: true}, async () => {
      try{
        await QuotaAPI.changeCurrentSubscription(this.state.selectedPlan.id, { couponCode: this.state.coupon });
        this.setState({loading: false, showChangeModal: false}, () => {
          success("We have updated your subscription!");
          this.getPlans();
        })
      }catch(err: any){
        if(err.code === 402){
          error("You must provide valid payment information before changing your subscription.");
          this.setState({loading: false, showChangeModal: false});
        }
      }
    });
  }

  private cancelSubscription(){
    this.setState({loading: true}, async () => {
      try{
        await QuotaAPI.cancelCurrentSubscription();
        this.setState({loading: false, showCancelModal: false}, () => {
          success("We have cancelled your subscription!");
          this.getPlans();
        })
      }catch(err){
        error("We could not cancel your subscription. Please contact support.");
        this.setState({loading: false, showCancelModal: false});
      }
    });
  }

  private reactivateSubscription(){
    this.setState({loading: true}, async () => {
      try{
        await QuotaAPI.changeCurrentSubscription(this.state.currentSubscriptionPlan.id);
        this.setState({loading: false, showReactivateModal: false}, () => {
          success("We have renewed your subscription!");
          this.getPlans();
        })
      }catch(err: any){
        if(err.code === 402){
          error("You must provide valid payment information before changing your subscription.");
          this.setState({loading: false, showReactivateModal: false});
        }
      }
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription) as any;