import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { Translator } from "src/utils/translator";
import { ActivitiesAPI, JournalAPI, MessagesAPI, MilestonesAPI, QuotaAPI, RemindersAPI, ToolsAPI, VerbatimsAPI } from "src/api";
import Screen from "src/components/structure/Screen";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { IMilestone } from "src/api/milestones";
import { IMessage } from "src/api/messages";

interface IDashboardProps {
  appActions: any;
  history: any;
  userState: any;
}

interface IDashboardState {
  loading: boolean;
  activities: any;
  numberOfActivities: number;
  followUpActivities: any;
  numberOfContacts: number;
  reminders: any;
  numberOfReminders: number;
  milestones: IMilestone[];
  messages: IMessage[];
  journalEntries: any[];
  sharedTools: any[];
  verbatims: any[];
}

const activityHelpText = Translator.getHelpText("en", "dashboardActivities", "Activities are events that you attended. You can track counts, time, and participants and generate reports for analysis and reporting requirements.");
const remindersHelpText = Translator.getHelpText("en", "dashboardReminders", "Reminders are things you want us to remind you about via email. They can be either daily or weekly reminders.");
// const contactsHelpText = Translator.getHelpText("en", "dashboardContacts", "Contacts are individuals you need to stay in touch with. They are useful when linking to messages and tracking critical peices of information through notes.");
const milestonesHelpText = Translator.getHelpText("en", "dashboardMilestones", "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.");
// TODO: replace the below
const messagesHelpText = "Here you can see the most recent messages sent to you from your connections!";
const journalHelpText = "If a client chooses to share a journal entry with you, you will see the most recent shares below.";
const sharedToolsHelpText = "If a client shares a tool submission with you, you can view it here.";
const verbatimHelpText = "Your most recent verbatims are shown below.";

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      activities: [],
      followUpActivities: [],
      numberOfContacts: 0,
      numberOfActivities: 0,
      reminders: [],
      numberOfReminders: 0,
      milestones: [],
      messages: [],
      journalEntries: [],
      sharedTools: [],
      verbatims: [],
    };

    this.fetch = this.fetch.bind(this);

  }

  componentDidMount() {
    this.fetch();
  }

  public render() {
    return (

      <Screen>
        <div>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <Card title="News" loading={false} help="">
                <p>Thank you for being a part of our beta test! We have new features coming all the time, so we appreciate your patience and feedback.</p>

                <p><strong>Important: </strong> ChapTrack is now Voithos! We are working at updating our branding and marketing. With this change comes a host of new features, including the ability to invite your clients so you can send and receive messages and tools for better care!</p>

                <p><strong>4/26/2023: </strong> We added Journals and rewrote our Messaging from the ground up!</p>

                <p><strong>3/01/2023: </strong> We just added several new tools for client connections. We plan to add several more throughout the year, but please contact us if you have specific requests!</p>

                <p><strong>6/24/2022: </strong> We are excited to announce a complete change to our subscription plans! They are now simpler to manage and come packed with even more features!</p>
                
              </Card>
            </div>
            <div className="col-lg-9 col-sm-12">
  
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <Card title={`Most Recent Activities (${this.state.numberOfActivities})`} loading={this.state.loading} help={activityHelpText}>
                    {this.state.activities.length === 0 && (<p><strong>No activities in the previous month</strong></p>)}
                    {this.state.activities.map((activity: any) => {
                      return (
                        <div className="row" key={activity.id}>
                          <div className="col-lg-3">{moment(activity.dateOccurred).format("MM/DD/YY")}</div>
                          <div className="col-lg-4">{ActivitiesAPI.translateCategory(activity.category)}</div>
                          <div className="col-lg-5">{activity.name}</div>
                        </div>
                      );
                    })}
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "right" }}>
                        <Link to="/activities">More</Link><br />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <Card title={`Follow Up Activities (${this.state.followUpActivities.length})`} loading={this.state.loading} help={activityHelpText}>
                    {this.state.followUpActivities.length === 0 && (<p><strong>No upcoming follow ups identified</strong></p>)}
                    {this.state.followUpActivities.map((activity: any) => {
                      return (
                        <div className="row" key={activity.id}>
                          <div className="col-lg-3">{moment(activity.followUpOn).format("MM/DD/YY")}</div>
                          <div className="col-lg-4">{activity.category}</div>
                          <div className="col-lg-5">{activity.name}</div>
                        </div>
                      );
                    })}
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "right" }}>
                        <Link to="/activities">More</Link><br />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
  
              <div className="row" style={{marginTop: 20}}>
                <div className="col-lg-6 col-sm-12">
                    <Card title={`Recent Milestones`} loading={this.state.loading} help={milestonesHelpText}>
  
                      {this.state.milestones.length === 0 && (<p><strong>No recent milestones have been set up.</strong></p>)}
                      {this.state.milestones.map((milestone: IMilestone) => {
                        return (
                          <div className="row" key={milestone.id}>
                            <div className="col-lg-4 col-sm-6">
                              {milestone.dateOccurred.format("MM/DD/YYYY")}
                            </div>
                            <div className="col-lg-4 col-sm-6">
                              {`${milestone.firstName} ${milestone.lastName}`}
                            </div>
                            <div className="col-lg-4 col-sm-12">
                              {milestone.title}
                            </div>
                          </div>
                        )
                      })}
                      <div className="row">
                        <div className="col-12" style={{ textAlign: "right" }}>
                          <Link to="/milestones">More</Link><br />
                        </div>
                      </div>
                    </Card>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <Card title={`Reminders (${this.state.reminders.length})`} loading={this.state.loading} help={remindersHelpText}>
                    {this.state.reminders.length === 0 && (<p><strong>No active reminders have been set up for tracking.</strong></p>)}
                    {this.state.reminders.map((item: any) => {
                      const style = item.status === "closed" ? "reminder-closed" : "";
                      return (
                        <div className="row" key={item.id}>
                          <div className={`col-4 ${style}`}>{this.displaySeverity(item.severity)}</div>
                          <div className={`col-8 ${style}`}>{item.reminder}</div>
                        </div>
                      );
                    })}
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "right" }}>
                        <Link to="/tracking">More</Link><br />
                      </div>
                    </div>
                    </Card>
                </div>
              </div>


              <div className="row" style={{marginTop: 20}}>
                <div className="col-lg-6 col-sm-12">
                    <Card title={`Recent Messages`} loading={this.state.loading} help={messagesHelpText}>
  
                      {this.state.messages.length === 0 && (<p><strong>No recent messages found.</strong></p>)}
                      {this.state.messages.map((message: IMessage) => {
                        return (
                          <div className="row" key={message.id}>
                            <div className="col-lg-4 col-sm-6">
                              {moment(message.deliveredOn).format("MM/DD/YY h:mm A")}
                            </div>
                            <div className="col-lg-4 col-sm-6">
                              {message.fromFirstName} {message.fromLastName}
                            </div>
                            <div className="col-lg-4 col-sm-12">
                              {message.subject}
                            </div>
                          </div>
                        )
                      })}
                      <div className="row">
                        <div className="col-12" style={{ textAlign: "right" }}>
                          <Link to="/messages">More</Link><br />
                        </div>
                      </div>
                    </Card>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <Card title={`Recent Shared Journals`} loading={this.state.loading} help={journalHelpText}>
                    {this.state.journalEntries.length === 0 && (<p><strong>No recent journal entries have been shared with you.</strong></p>)}
                    {this.state.journalEntries.map((entry: any) => {
                      const url = `/connections/client/${entry.userId}`
                      return (
                        <div className="row">
                          <div className="col-3">
                            <Link to={url}>{moment(entry.date).format("MM/DD/YY")}</Link>
                          </div>
                          <div className="col-4">
                            <Link to={url}>{entry.firstName} {entry.lastName}</Link>
                          </div>
                          <div className="col-5">
                            <Link to={url}>{entry.title}</Link>
                          </div>
                        </div>
                      );
                    })}
                    </Card>
                </div>
              </div>
  
              <div className="row" style={{marginTop: 20}}>
                <div className="col-lg-6 col-sm-12">
                    <Card title={`Recent Tool Submissions`} loading={this.state.loading} help={sharedToolsHelpText}>

                      {this.state.sharedTools.length === 0 && (<p><strong>No recent submissions have been shared with you.</strong></p>)}
                      {this.state.sharedTools.map((tool: any) => {
                        const url = `/connections/client/${tool.creatorId}`
                        return (
                          <div className="row" key={tool.id}>
                            <div className="col-lg-3 col-sm-6">
                              <Link to={url}>{moment(tool.submittedOn).format("MM/DD/YY h:mm A")}</Link>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                              <Link to={url}>{tool.creatorFirstName} {tool.creatorLastName}</Link>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                              <Link to={url}>{ToolsAPI.translateToolTypeToDisplay(tool.toolType)}</Link>
                            </div>
                          </div>
                        )
                      })}
                    </Card>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <Card title={`Verbatims`} loading={this.state.loading} help={verbatimHelpText}>
                    {this.state.verbatims.length === 0 && (<p><strong>No active recent verbatims have been created.</strong></p>)}
                    {this.state.verbatims.map((verbatim: any) => {
                      const url = `/verbatims/${verbatim.id}`;
                        return (
                          <div className="row" key={verbatim.id}>
                            <div className="col-lg-6 col-sm-6">
                              <Link to={url}>Occurred: {moment(verbatim.dateOccurred).format("MM/DD/YYYY")}</Link>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <Link to={url}>{verbatim.status === "draft" ? "In Draft" : "Completed"}</Link>
                            </div>
                          </div>
                        )
                    })}
                    </Card>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </Screen>
    );
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try {
        const start = moment().subtract(1, "months").format("YYYY-MM-DD");
        const end = moment().add(1, "days").format("YYYY-MM-DD");
        const followUpStart = moment().subtract(1, "weeks").format("YYYY-MM-DD");
        const followUpEnd = moment().add(1, "months").format("YYYY-MM-DD");
        const activitiesResult = await ActivitiesAPI.getActivities("", start, end, {});
        const followUpActivitiesResult = await ActivitiesAPI.getActivities("", followUpStart, followUpEnd, { followUp: "active" });
        const remindersResult = await RemindersAPI.getReminders();
        const milestonesResult = await MilestonesAPI.getMilestones();
        const quotaResult = await QuotaAPI.getUserQuota();
        const messagesResult = await MessagesAPI.getMessages(start, end);
        const journalResult = await JournalAPI.getSharedEntries(start, end, 5, 0);
        const toolsResult = await ToolsAPI.getSharedToolSubmissions({start, end});
        const verbatimsResult = await VerbatimsAPI.getVerbatims({start, end});

        // we only want the last three activities
        const activities = [];
        for (let i = 0; i < activitiesResult.body.data.length; i++) {
          if (i >= 3) {
            break;
          }
          activities.push(activitiesResult.body.data[i]);
        }

        const reminders = [];
        for (let i = 0; i < remindersResult.body.data.length; i++) {
          if(reminders.length >= 3){
            break;
          }
          const r = remindersResult.body.data[i];
          if(r.status === "closed"){
            continue;
          }
          reminders.push(r);
        }

        // sort the milestones
        // TODO: do we want to instead parse out those that have a nextDue?
        const milestones: IMilestone[] = milestonesResult.body.data;
        const topMilestones: IMilestone[] = [];
        for(const m of milestones){
          m.dateOccurred = moment(m.dateOccurred);
          m.nextDue = moment(m.nextDue);
        }
        milestones.sort((a: IMilestone, b: IMilestone) => {
          return a.dateOccurred.isBefore(b.dateOccurred) ? 1 : -1;
        });
        // now just the first 3
        for(let i = 0; i < milestones.length; i++){
          if(i >= 3){
            break;
          }
          topMilestones.push(milestones[i])
        }

        const messages: IMessage[] = [];
        let foundMessages = 0;
        for(let i = 0; i < messagesResult.body.data.length; i++){
          const m = messagesResult.body.data[i];
          if(m.fromId === this.props.userState.user.id){
            continue;
          }
          messages.push(m);
          foundMessages++;
          if(foundMessages >= 5){
            break;
          }
        }

        const sharedTools: any[] = [];
        toolsResult.body.data.forEach((tool: any, i: number) => {
          if(i > 5){
            return null;
          }
          sharedTools.push(tool);
          return null;
        });

        const verbatims: any[] = [];
        verbatimsResult.body.data.forEach((verbatim: any, i: number) => {
          if(i > 5){
            return null;
          }
          verbatims.push(verbatim);
          return null;
        });

        this.setState({
          loading: false,
          activities,
          numberOfActivities: activitiesResult.body.data.length,
          numberOfContacts: quotaResult.body.data.contactsCreated,
          reminders: reminders,
          numberOfReminders: remindersResult.body.data.length,
          followUpActivities: followUpActivitiesResult.body.data,
          milestones: topMilestones,
          messages,
          journalEntries: journalResult.body.data,
          sharedTools,
          verbatims,
        });

      } catch (err) { }
    });
  }

  private displaySeverity(severity: string) {
    switch (severity) {
      case "normal":
        return (<span className="text-severity-normal">Normal</span>)
      case "severe":
        return (<span className="text-severity-severe">Severe</span>)
      case "critical":
        return (<span className="text-severity-critical">Critical</span>)
      default:
        return null;
    }
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard) as any);