import * as activities from "./activities";
import * as contacts from "./contacts";
import * as exports from "./exports";
import * as imports from "./imports";
import * as incomingContact from "./incomingContact";
import * as integrations from "./integrations";
import * as jobs from "./jobs";
import * as journal from "./journal";
import * as messages from "./messages";
import * as milestones from "./milestones";
import * as quota from "./quota";
import * as reminders from "./reminders";
import * as timeline from "./timeline";
import * as tools from "./tools";
import * as user from "./user";
import * as verbatims from "./verbatims";

export const ActivitiesAPI = new activities.ActivitiesAPI();
export const ContactsAPI = new contacts.ContactsAPI();
export const ExportsAPI = new exports.ExportsAPI();
export const ImportsAPI = new imports.ImportsAPI();
export const IncomingContactAPI = new incomingContact.IncomingContactAPI();
export const IntegrationsAPI = new integrations.IntegrationsAPI();
export const JobsAPI = new jobs.JobsAPI();
export const JournalAPI = new journal.JournalAPI();
export const MessagesAPI = new messages.MessagesAPI();
export const MilestonesAPI = new milestones.MilestoneAPI();
export const QuotaAPI = new quota.QuotaAPI();
export const RemindersAPI = new reminders.RemindersAPI();
export const TimelineAPI = new timeline.TimelineAPI();
export const ToolsAPI = new tools.ToolsAPI();
export const UserAPI = new user.UserAPI();
export const VerbatimsAPI = new verbatims.VerbatimsAPI();