import * as React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { ContactsAPI } from "../../../api";
import { error, success } from "../../structure/Alert";

interface IContactNoteListItemProps {
  note: any;
  contactId: number;
  onDelete: any;
  onUpdate: any;
}

interface IContactNoteListItemState {
  loading: boolean;
  note: any;
  showEditModal: boolean;
  showDeleteModal: boolean;
}

export default class ContactNoteListItem extends React.Component<IContactNoteListItemProps, IContactNoteListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      note: props.note,
      showDeleteModal: false,
      showEditModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.saveNote = this.saveNote.bind(this);

  }

  public render() {
    return (
      <div className="row list-row">
        <div className="col-lg-3 col-sm-12">
          {moment(this.props.note.createdOn).format("MM/DD/YY")}
        </div>
        <div className="col-lg-7 col-sm-12">
          {this.props.note.notes}
        </div>
        <div className="col-lg-2 col-sm-12">
          <span className="oi oi-pencil icon icon-primary" title="Edit Contact" onClick={this.toggleEditModal} />
          <span className="oi oi-x icon icon-danger" title="Delete Contact" onClick={this.toggleDeleteModal} />
        </div>


        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Notes</label>
              <textarea rows={4} className="form-control" id="notes" value={this.state.note.notes} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.saveNote}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong>You are about to delete the following note. This cannot be undone.
            <hr />
            <p>
              {this.props.note.notes}
            </p>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteNote}>Delete Note</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state.note;
    ns[e.target.id] = e.target.value;
    this.setState({
      note: ns
    });
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private deleteNote(){
    this.setState({loading: true}, async () => {
      try{
        await ContactsAPI.deleteContactNote(this.props.contactId, this.props.note.id);
        this.setState({ loading: false, showDeleteModal: false }, () => {
          this.props.onDelete(this.props.note);
        });
      }catch(err){
        this.setState({ loading: false, showDeleteModal: false });
      }
    })
  }

  private saveNote(){
    const data = {
      notes: this.state.note.notes.trim()
    };
    if(data.notes === ""){
      return error("Notes cannot be empty");
    }
    this.setState({loading: true}, async () => {
      try{
        await ContactsAPI.updateContactNote(this.props.contactId, this.props.note.id, data);
        success("Note updated!");
        this.setState({ loading: false, showEditModal: false }, () => {
          this.props.onUpdate(this.state.note);
        });
      }catch(err){
        this.setState({ loading: false, showEditModal: false });
      }
    })
  }

}