export const PlanTable = (props: any) => {
  return (
    <table className="table">
      <thead>
        <th scope="col"></th>
        <th scope="col">Free</th>
        <th scope="col">Pro</th>
      </thead>
      <tbody>
        <tr>
          <td>Activities</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Contacts</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Milestones</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Reports</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Verbatims</td>
          <td>1</td>
          <td><strong className="plan-feature">Unlimited!</strong></td>
        </tr>
        <tr>
          <td>Messages</td>
          <td>No</td>
          <td><strong className="plan-feature">Yes!</strong></td>
        </tr>
        <tr>
          <td>Scheduled Exports</td>
          <td>No</td>
          <td><strong className="plan-feature">Yes!</strong></td>
        </tr>
        <tr>
          <td>Reminders</td>
          <td>No</td>
          <td><strong className="plan-feature">Yes!</strong></td>
        </tr>
        <tr>
          <td>SMS</td>
          <td>No</td>
          <td><strong className="plan-feature">500*</strong></td>
        </tr>
        <tr>
          <td>Integrations</td>
          <td>No</td>
          <td><strong className="plan-feature">Yes!</strong></td>
        </tr>
        <tr>
          <td>Support</td>
          <td>Email</td>
          <td><strong className="plan-feature">Priority</strong></td>
        </tr>
        <tr>
          <td>Price</td>
          <td>Free</td>
          <td><strong className="plan-feature">$4.99 / month<br />OR<br /> $49.99 / year</strong></td>
        </tr>
      </tbody>
    </table>
  );
}