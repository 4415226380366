import * as React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import { IActivity, ActivityBlank } from "src/api/activities";
import { ActivitiesAPI } from "src/api";
import DatePicker from "src/components/structure/DatePicker";
import LoadingButton from "src/components/structure/LoadingButton";
import { error, success } from "src/components/structure/Alert";

interface IBulkActivityFormProps {
  show: boolean;
  onCreate: any;
  onToggleForm: any;
  switchToSingle: any;
  activityCategories: any[];
}

interface IBulkActivityFormState {
  loading: boolean;
  // errors: any;
  activitiesToCreate: IActivity[];
}

export default class BulkActivityForm extends React.Component<IBulkActivityFormProps, IBulkActivityFormState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      // errors: {},
      activitiesToCreate: [ActivityBlank],
    };

    this.updateField = this.updateField.bind(this);
    this.createActivities = this.createActivities.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
  }

  componentDidMount() {
    // we need to add a first one
    this.setState({ activitiesToCreate: [{ ...ActivityBlank }] })
  }

  public render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onToggleForm} dialogClassName="modal-x-large" >
        <Modal.Header closeButton={true}>
          <Modal.Title>Create New Activities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <p>Please note that bulk activities do not currently support adding Contacts to an activity.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-1 icon-bulk-activity-action-container" />
            <div className="col-lg-2 col-sm-12">
              <div className="form-group">
                <span className="bulk-activity-row-header">Activity Name</span>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12">
              <div className="form-group">
                <span className="bulk-activity-row-header">Category</span>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12">
              <div className="form-group">
                <span className="bulk-activity-row-header">Date Occurs</span>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12">
              <div className="form-group">
                <span className="bulk-activity-row-header">Participants</span>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12">
              <div className="form-group">
                <span className="bulk-activity-row-header">Minutes</span>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12">
              <span className="bulk-activity-row-header">Notes</span>
            </div>
            <div className="col-lg-2 col-sm-12">
              <span className="bulk-activity-row-header">Tags</span>
            </div>
          </div>

          {this.state.activitiesToCreate.map((activity: IActivity, index: number) => {
            return (
              <div className={activity.errorMessage ? "bulk-activity-row-error" : ""} key={index}> 
                <div className={"row bulk-activity-row"} key={index} style={{marginTop: 2}}>
                  <div className="col-1 icon-bulk-activity-action-container" >
                    <span className="oi oi-x icon icon-danger icon-bulk-activity-action" title="Delete Row" onClick={() => this.removeRow(index)} />
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <input id={`name-${index}`} type="text" className="form-control" placeholder="Activity Name" value={this.state.activitiesToCreate[index].name} onChange={this.updateField} />
                    
                  </div>
                  <div className="col-lg-1 col-sm-12">
                    <select className="form-control" id={`category-${index}`} value={this.state.activitiesToCreate[index].category} onChange={this.updateField}>
                      {this.props.activityCategories.map((cat: any) => {
                        return (<option key={cat} value={cat}>{ActivitiesAPI.translateCategory(cat)}</option>)
                      })}
                    </select>
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <DatePicker
                      date={moment(this.state.activitiesToCreate[index].dateOccurred)}
                      onDateSaved={(newDate: moment.Moment) => this.updateDateOccurred(newDate, index)}
                    />
                  </div>
                  <div className="col-lg-1 col-sm-12">
                    <input id={`participantCount-${index}`} type="text" className="form-control" placeholder="Participants" value={this.state.activitiesToCreate[index].participantCount} onChange={this.updateField} />

                  </div>
                  <div className="col-lg-1 col-sm-12">
                    <input id={`lengthInMinutes-${index}`} type="text" className="form-control" placeholder="Minutes" value={this.state.activitiesToCreate[index].lengthInMinutes} onChange={this.updateField} />

                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <input id={`notes-${index}`} type="text" className="form-control" placeholder="Notes" value={this.state.activitiesToCreate[index].notes} onChange={this.updateField} />
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <input id={`tags-${index}`} type="text" className="form-control" placeholder="Tags" value={this.state.activitiesToCreate[index].tags} onChange={this.updateField} />
                  </div>
                </div>
                {activity.errorMessage && (
                  <div className="row">
                    <div className="col-11 offset-1">
                      <p className="bulk-activity-row-error-message">{activity.errorMessage}</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div className="card bulk-activity-row bulk-activity-row" style={{ border: 0 }}>
            <div className="card-body bulk-activity-row-body">
              <div className="row">
                <div className="col-1 icon-bulk-activity-action-container">
                  <span className="oi oi-plus icon icon-success icon-bulk-activity-action" style={{ fontSize: 24 }} title="Add Row" onClick={this.addRow} />
                </div>
                <div className="col-11">
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-4 col-sm-12" style={{ marginBottom: 5 }}>
              <LoadingButton className="btn btn-block btn-primary" onClick={this.createActivities} text="Create" loading={this.state.loading} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{ marginBottom: 5 }}>
              <LoadingButton className="btn btn-block btn-info" onClick={this.props.switchToSingle} text="Switch to Single" loading={this.state.loading} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{ marginBottom: 5 }}>
              <LoadingButton className="btn btn-block btn-secondary" onClick={this.props.onToggleForm} text="Cancel" loading={this.state.loading} />
            </div>
          </div>

        </Modal.Footer>
      </Modal>
    );
  }

  private updateField(e: any) {
    const split = e.target.id.split("-");
    const field = split[0];
    const index = parseInt(split[1], 10);
    let val = e.target.value;
    if(field === "lengthInMinutes" || field === "participantCount"){
      val = parseInt(val, 10);
    }
    const activities: IActivity[] = this.state.activitiesToCreate;
    activities[index][field] = val;
    this.setState({ activitiesToCreate: activities });
  }

  private updateDateOccurred(dateOccurred: moment.Moment, index: number) {
    const activities: IActivity[] = this.state.activitiesToCreate;
    activities[index].dateOccurred = dateOccurred;
    this.setState({ activitiesToCreate: activities });
  }

  private removeRow(index: number) {
    const activitiesToCreate: IActivity[] = [];
    for (let i = 0; i < this.state.activitiesToCreate.length; i++) {
      if (i !== index) {
        activitiesToCreate.push(this.state.activitiesToCreate[i]);
      }
    }
    this.setState({ activitiesToCreate });
  }

  private addRow() {
    const activitiesToCreate = this.state.activitiesToCreate;
    const lastActivity = activitiesToCreate[activitiesToCreate.length -1];
    activitiesToCreate.push({...lastActivity, errorMessage: ""});
    this.setState({ activitiesToCreate });
  }

  private createActivities() {
    // clear any previous errors so we start fresh
    const activitiesToCreate: IActivity[] = [];
    for(const a of this.state.activitiesToCreate){
      a.errorMessage = "";
      activitiesToCreate.push(a);
    }
    // we need to loop and error check
    // really, the only thing we need is the activity name and valid data types
    this.setState({loading: true, activitiesToCreate}, async () => {
      let foundErrors = false;
      for (let i = 0; i < this.state.activitiesToCreate.length; i++) {
        const a = this.state.activitiesToCreate[i];
        a.errorMessage = "";
        if (a.name === "") {
          a.errorMessage = "Name is required. "
          foundErrors = true;
        }
        
        const participantCount = parseInt((a.participantCount) as any, 10);
        if(isNaN(participantCount)){
          a.errorMessage += "Please provide a valid number for the participant count. ";
        }
        const lengthInMinutes = parseInt((a.lengthInMinutes) as any, 10);
        if(isNaN(lengthInMinutes)){
          a.errorMessage += "Please provide a valid number for the event length in minutes. ";
        }
      }
      if (foundErrors) {
        error("There were errors in your activities");
        return this.setState({loading: false});
      }
      // we should be good, so loop and create, capturing any errors
      const results: any = [];
      for(let i = 0; i < this.state.activitiesToCreate.length; i++){
        const a = this.state.activitiesToCreate[i];
        try{
          const result = await ActivitiesAPI.createActivity(a.name, a)
          results.push(result.body.data);
        }catch(err: any){
          if(err.body && err.body.data && err.body.data.message){
            a.errorMessage = `We could not create this activity: ${err.body.data.message}.`;
          } else {
            a.errorMessage = "Unknown error, please contact support.";
          }
          foundErrors = true;
        }
      }
      if(!foundErrors){
        // simply return, we're good
        return this.setState({activitiesToCreate: [{...ActivityBlank}], loading: false, }, () => {
          success(`${results.length} Activities created!`);
          this.props.onCreate(results);
        })
      }
      // so now we need some math; we need to hide the ones that were successful and error the ones that weren't
      error(`We could not create all of those activities. ${results.length} created (${this.state.activitiesToCreate.length - results.length} failed).`);
      // loop over all of the activities and remove the ones without an error message
      const activitiesToCreate: IActivity[] = [];
      for(const a of this.state.activitiesToCreate){
        if(a.errorMessage && a.errorMessage !== ""){
          activitiesToCreate.push(a);
        }
        this.setState({activitiesToCreate, loading: false});
      }
    });
    
  }

}