import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

import Profile from "./Profile";
interface IProfileScreenProps {
  appActions: any;
  history: any;
}

interface IProfileScreenState {
  loading: boolean;
}

class ProfileScreen extends React.Component<IProfileScreenProps, IProfileScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
      <div className="row">
        <div className="col-lg-4 offset-lg-4 col-sm-12">
          <Profile />
        </div>
      </div>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileScreen) as any);