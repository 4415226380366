import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { Translator } from "../../../utils/translator";
import Card from "../../structure/Card";
import DatePicker from "../../structure/DatePicker";
import * as AppActions from "../../../reducers/appReducer";
import { MessagesAPI, QuotaAPI } from "../../../api";
import {IMessage} from "../../../api/messages";
import ConnectionSearch from "src/components/structure/ConnectionSearch";

import MessageListItem from "./MessageListItem";
import { success, error } from "src/components/structure/Alert";
import { SubscriptionBlock } from "src/components/structure/SubscriptionBlock";

const messagesListHelpText = Translator.getHelpText("en", "messagesList");

interface IMessagesScreenProps {
  appActions: any;
  history: any;
  userState: any;
  match: any;
}

interface IMessagesScreenState {
  loading: boolean;
  isFirstView: boolean;
  isSubscribed: boolean;
  smsAllowed: number;
  showCreateModal: boolean;
  showDeleteModal: boolean;
  showReplyModal: boolean;
  showViewModal: boolean;
  showEditModal: boolean;
  selectedMessage: IMessage | null;
  
  start: moment.Moment;
  end: moment.Moment;
  selectedMethod: "all" | "app" | "email" | "sms";

  messages: IMessage[];
  filteredMessages: IMessage[];

  newSubject: string;
  newBody: string;
  newConnection: any;
  newSendDate: moment.Moment;
  sendSMS: "yes" | "no";
  includeBodyInEmail: "yes" | "no";  
}

class MessagesScreen extends React.Component<IMessagesScreenProps, IMessagesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      isFirstView: true,
      isSubscribed: false,
      smsAllowed: 0,
      showCreateModal: false,
      showDeleteModal: false,
      showReplyModal: false,
      showViewModal: false,
      showEditModal: false,
      selectedMessage: null,

      start: moment().subtract(1, "months"),
      end: moment().add(1, "months"),
      selectedMethod: "all",

      messages: [],
      filteredMessages: [],

      newSubject: "",
      newBody: "",
      newConnection: {},
      newSendDate: moment(),
      sendSMS: "no",
      includeBodyInEmail: "no",
    };


    this.updateField = this.updateField.bind(this);
    this.updateEditField = this.updateEditField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.updateNewSendDate = this.updateNewSendDate.bind(this);
    this.updateEditSendDate = this.updateEditSendDate.bind(this);
    this.updateNewConnection = this.updateNewConnection.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.filterMessages = this.filterMessages.bind(this);
    this.fetchQuota = this.fetchQuota.bind(this);
    this.handleDelete =  this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.reply = this.reply.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.toggleShowReplyModal = this.toggleShowReplyModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.toggleShowViewModal = this.toggleShowViewModal.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.selectMessageForDelete = this.selectMessageForDelete.bind(this);
    this.selectMessageForEdit = this.selectMessageForEdit.bind(this);
    this.selectMessageForReply = this.selectMessageForReply.bind(this);
    this.selectMessageForView = this.selectMessageForView.bind(this);
  }

  componentDidMount(){
    this.fetchQuota();
  }

  public render() {
    if(!this.state.isSubscribed){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <SubscriptionBlock featureDisplay="Sending Messages" />
          </div>
        </div>
      );
    }
    return (
      <div>       

        <div className="row" style={{marginBottom: 20}}>
          <div className="col-lg-2">
            <Card title="Messages" loading={this.state.loading} help={messagesListHelpText}>
              <div className="form-group">
                SMS Remaining: {this.state.smsAllowed}
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-primary">Buy More!</button>
              </div>
            </Card>
          </div>
          <div className="col-lg-10">
            <Card title="Messages" loading={this.state.loading} help={messagesListHelpText}>
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <label>From</label>
                  <DatePicker 
                    date={this.state.start}
                    onDateSaved={this.updateStart}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <label>To</label>
                  <DatePicker 
                    date={this.state.end}
                    onDateSaved={this.updateEnd}
                  />
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label>Method</label>
                  <select className="form-control" id="selectedMethod" value={this.state.selectedMethod} onChange={this.updateField}>
                    <option value="all">All</option>
                    <option value="app">App</option>
                    <option value="email">Email</option>
                    <option value="sms">SMS</option>
                  </select>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <button className="btn btn-primary btn-block" onClick={this.toggleShowCreateModal} style={{marginTop: 32}}>New</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.state.filteredMessages.length === 0 && (<strong>No messages fit that criteria</strong>)}
                  {this.state.filteredMessages.map((message) => {
                      return (
                        <MessageListItem 
                          key={`${message.id}`}
                          message={message}
                          onEdit={this.handleEdit}
                          onDelete={this.handleDelete}
                          user={this.props.userState.user}
                          onSelectedForDelete={this.selectMessageForDelete}
                          onSelectedForEdit={this.selectMessageForEdit}
                          onSelectedForReply={this.selectMessageForReply}
                          onSelectedForViewing={this.selectMessageForView}
                        />);
                  })}
                </div>
              </div>
            </Card>
          </div>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleShowCreateModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Send To</label>
              <ConnectionSearch selectedConnections={[]} connections={[]} onSelect={this.updateNewConnection} />
              <p className="text-small small">You can only send messages to accepted connections.</p>
            </div>
            <div className="form-group">
              <label>Send On</label>
              <DatePicker 
                date={this.state.newSendDate}
                onDateSaved={this.updateNewSendDate} />
            </div>
            <div className="form-group">
              <label>Send SMS if Available?</label>
              <select className="form-control" id="sendSMS" value={this.state.sendSMS} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" className="form-control" id="newSubject" value={this.state.newSubject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea className="form-control" id="newBody" value={this.state.newBody} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Include Body in Email Notification?</label>
              <select className="form-control" id="includeBodyInEmail" value={this.state.includeBodyInEmail} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-primary" onClick={this.create}>Create</button>
              </div>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowCreateModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showReplyModal} onHide={this.toggleShowReplyModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Send Reply</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Send On</label>
              <DatePicker 
                date={this.state.newSendDate}
                onDateSaved={this.updateNewSendDate} />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" className="form-control" id="newSubject" value={this.state.newSubject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea className="form-control" id="newBody" value={this.state.newBody} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Include Body in Email Notification?</label>
              <select className="form-control" id="includeBodyInEmail" value={this.state.includeBodyInEmail} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-primary" onClick={this.reply}>Send Reply</button>
              </div>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowReplyModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showViewModal} onHide={this.toggleShowViewModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Viewing Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              {this.state.selectedMessage?.toId === this.props.userState.user.id ? (
                <span>From {this.state.selectedMessage?.fromTitle} {this.state.selectedMessage?.fromFirstName} {this.state.selectedMessage?.fromLastName} </span>
              )
              : (
                <span>To {this.state.selectedMessage?.toTitle} {this.state.selectedMessage?.toFirstName} {this.state.selectedMessage?.toLastName} </span>
              )}
            </div>
            <div className="form-group">
              {this.state.selectedMessage && this.state.selectedMessage.status === "pending" ? (
                <span>Sending on {this.state.selectedMessage.sendDate.format("MM/DD/YY")}</span>
              ): (
                <span>Delivered on {this.state.selectedMessage?.deliveredOn.format("MM/DD/YY h:mm A")}</span>
              )}
            </div>
            <div className="form-group">
              {this.state.selectedMessage?.subject}
            </div>
            <div className="form-group">
              <div style={{ whiteSpace: "pre-wrap"}}>{this.state.selectedMessage?.body}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-primary" onClick={this.toggleShowReplyModal}>Reply</button>
              </div>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowViewModal}>Close</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong> You are requesting to delete the message to {this.state.selectedMessage?.toTitle} {this.state.selectedMessage?.toFirstName} {this.state.selectedMessage?.toLastName} scheduled to be sent on {this.state.selectedMessage?.sendDate.format("MM/DD/YY")}. This cannot be undone. Are you sure?
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteMessage}>Confirm</button>
              </div>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowViewModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>


        
        <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Send On</label>
              <DatePicker 
                date={this.state.selectedMessage ? this.state.selectedMessage.sendDate : moment()}
                onDateSaved={this.updateEditSendDate} />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" className="form-control" id="subject" value={this.state.selectedMessage?.subject} onChange={this.updateEditField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea className="form-control" id="body" value={this.state.selectedMessage?.body} onChange={this.updateEditField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-primary" onClick={this.edit}>Save</button>
              </div>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "selectedMethod"){
        this.filterMessages();
      }
    });
  }

  private updateEditField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state.selectedMessage ? this.state.selectedMessage : {};
    ns[id] = val;
    this.setState(ns);
  }

  private toggleShowCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }


  private fetchQuota(){
    this.setState({loading: true}, async () => {
      try{
        const res = await QuotaAPI.getUserQuota();
        this.setState({
          isSubscribed: res.body.data.isSubscribed,
          smsAllowed: res.body.data.smsLeft,
          loading: false,
        }, () => this.fetchMessages());
      }catch(e){
        this.setState({loading: false});
      }
    });
  }


  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate}, () => {
      this.fetchMessages();
    });
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate}, () => {
      this.fetchMessages();
    });
  }

  private updateNewSendDate(newDate: moment.Moment){
    this.setState({ newSendDate: newDate});
  }

  private updateEditSendDate(newDate: moment.Moment){
    const message: any = this.state.selectedMessage;
    message.sendDate = newDate;
    this.setState({ selectedMessage: message });
  }

  private updateNewConnection(selectedConnection: any){
    if(!selectedConnection){
      return;
    }
    this.setState({
      newConnection: selectedConnection
    });
  }

  private fetchMessages(){
    this.setState({loading: true}, async () => {
      try{
        const res = await MessagesAPI.getMessages(this.state.start.format("YYYY-MM-DD"),this.state.end.format("YYYY-MM-DD"));
        // loop and parse the dates
        const messages: IMessage[] = res.body.data;
        for(const m of messages){
          m.createdOn = moment(m.createdOn);
          m.sendDate = moment(m.sendDate).hour(23).minute(59);
          m.deliveredOn = moment(m.deliveredOn);
        }
        this.setState({
          messages,
        }, () => {
          if(this.state.isFirstView){
            const messageId = this.props.match.params.messageId;
            if(messageId && messageId !== ""){
              const mId = parseInt(messageId);
              for(const m of messages){
                if(m.id === mId){
                  this.selectMessageForView(m);
                  break;
                }
              }
            }
          }
          this.filterMessages()
        });
      }catch(e){
        this.setState({loading: false});
      }
    });
  }


  private filterMessages(){
    let filteredMessages: IMessage[] = [];
    if(this.state.selectedMethod === "all"){
      filteredMessages = this.state.messages;
    }
    this.setState({loading: false, filteredMessages});
  }

  private handleEdit(message: IMessage){
    const messages: IMessage[] = [];
    for(const m of this.state.messages){
      if(m.id !== message.id){
        messages.push(m);
      } else {
        messages.push(message);
      }
    }
    this.setState({ messages }, () => this.filterMessages());
  }

  private handleDelete(message: IMessage){
    const messages: IMessage[] = [];
    for(const m of this.state.messages){
      if(m.id !== message.id){
        messages.push(m);
      }
    }
    this.setState({ messages }, () => this.fetchQuota());
  }

  private create(){
    const data: any = {
      toId: this.state.newConnection.userId,
      subject: this.state.newSubject.trim(),
      body: this.state.newBody.trim(),
      sendDate: this.state.newSendDate.format("YYYY-MM-DD"),
      includeBodyInEmail: this.state.includeBodyInEmail,
      sendSMS: this.state.sendSMS,
    };
    if(data.subject === "" || data.body === ""){
      return error("Subject and Body are required");
    }
    if(!data.toId || data.toId === 0){
      return error("You must select a valid contact");
    }
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.createMessage(data);
        // trigger a full refetch
        success("Message has been created!");
        this.setState({
          showCreateModal: false,
          newSubject: "",
          newBody: "",
          newConnection: 0,
          newSendDate: moment().add(1, "day"),
        }, () => this.fetchQuota());
      }catch(err){
        error("Could not create that message. Make sure your chosen Send Date is today or in the future.");
        this.setState({loading: false});
      }
    })
  }

  private edit(){
    const data: any = {
      id: this.state.selectedMessage ? this.state.selectedMessage.id : 0,
      subject: this.state.selectedMessage?.subject,
      body: this.state.selectedMessage?.body,
      sendDate: this.state.selectedMessage?.sendDate.format("YYYY-MM-DD"),
    };
    console.log(data);
    this.setState({ loading: true }, async () => {
      try{
        await MessagesAPI.updateMessage(data.id, data);
        const messages: any[] = [];
        const filteredMessages: any = [];
        for(const m of this.state.messages){
          if(m.id === data.id){
            m.subject = data.subject;
            m.body = data.body;
            m.sendDate = moment(data.sendDate);
          }
          messages.push(m);
        }
        for(const m of this.state.filteredMessages){
          if(m.id === data.id){
            m.subject = data.subject;
            m.body = data.body;
            m.sendDate = moment(data.sendDate);
          }
          filteredMessages.push(m);
        }
        this.setState({ loading: false, showEditModal: false, messages, filteredMessages });
      }catch(err){
        error("Could not edit that message. Perhaps it was already delivered?");
        this.setState({ loading: false });
      }
    })
  }

  private deleteMessage(){
    this.setState({ loading: true }, async () => {
      try{
        await MessagesAPI.deleteMessage(this.state.selectedMessage ? this.state.selectedMessage.id  :0);
        const message: any = this.state.selectedMessage;
        const messages: any[] = [];
        const filteredMessages: any = [];
        for(const m of this.state.messages){
          if(m.id !== message.id){
            messages.push(m);
          }
        }
        for(const m of this.state.filteredMessages){
          if(m.id !== message.id){
            filteredMessages.push(m);
          }
        }
        success("Message deleted!");
        this.setState({ loading: false, messages, filteredMessages, selectedMessage: null, showDeleteModal: false });
      }catch(err){
        error("Could not delete that message. Perhaps it was already delivered?");
        this.setState({ loading: false });
      }
    })
  }

  private reply(){
    const data: any = {
      replyToId: this.state.selectedMessage?.id,
      toId: this.state.selectedMessage?.fromId,
      subject: this.state.newSubject,
      body: this.state.newBody,
      sendSMS: "no",
      includeBodyInEmail: this.state.includeBodyInEmail,
      sendDate: this.state.newSendDate.format("YYYY-MM-DD"),
    }
    console.log(data);
    this.setState({ loading: true }, async () => {
      try{
        await MessagesAPI.createMessage(data);
        success("Message created!");
        this.setState({ newBody: "", newSubject: "", selectedMessage: null, showReplyModal: false }, () => {
          this.fetchMessages();
        })
      }catch(err){
        error("Could not reply to that message. Please contact support.")
        this.setState({ loading: false });
      }
    })
  }

  private toggleShowViewModal(){
    this.setState({ showViewModal: !this.state.showViewModal });
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private toggleShowReplyModal(){
    this.setState({ showReplyModal: !this.state.showReplyModal, showViewModal: false, });
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal });
  }

  private selectMessageForView(message: IMessage) {
    this.setState({
      selectedMessage: message,
      showViewModal: true,
      isFirstView: false,
    }, async () => {
      if(message.status === "sent"){
        const messages: any[] = [];
        const filteredMessages: any = [];
        for(const m of this.state.messages){
          if(m.id === message.id){
            m.status = "viewed";
          }
          messages.push(m);
        }
        for(const m of this.state.filteredMessages){
          if(m.id === message.id){
            m.status = "viewed";
          }
          filteredMessages.push(m);
        }
        try{
          await MessagesAPI.updateMessage(message.id, {status: "viewed"});
        }catch(err){}
        finally{
          this.setState({ messages, filteredMessages });
        }
      }
    });
  }

  private selectMessageForEdit(message: IMessage) {
    this.setState({
      selectedMessage: message,
      showEditModal: true,
    });
  }

  private selectMessageForDelete(message: IMessage) {
    this.setState({
      selectedMessage: message,
      showDeleteModal: true,
    });
  }

  private selectMessageForReply(message: IMessage) {
    this.setState({
      newSubject: "Re: " + message.subject,
      newBody: "\n-------------------------\n" + message.body,
      selectedMessage: message,
      showReplyModal: true,
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesScreen) as any);