import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dropzone from 'react-dropzone'

import { QuotaAPI, UserAPI } from "src/api";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { SubscriptionBlock } from "src/components/structure/SubscriptionBlock";
import { error, success } from "src/components/structure/Alert";

interface IPageSettingsScreenProps {
  appActions: any;
}

interface IPageSettingsScreenState {
  loading: boolean;
  isSubscribed: boolean;
  displayName: string;
  visibility: string;
  showTitle: "yes" | "no";
  showFirst: "yes" | "no";
  showLast: "yes" | "no";
  takingNewClients: "yes" | "no";
  meetInPerson: "yes" | "no";
  meetPhone: "yes" | "no";
  meetVideo: "yes" | "no";
  meetChat: "yes" | "no";
  contactEmail: string;
  showActivityCounts: "30" | "90" | "365" | "all" | "no";
  showActivityCountsByCategory: "yes" | "no";
  showClientCounts: "yes" | "no";
  bio: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  focusAreas: string;
  training: string;
  profileImagePath: string;
  file: any;
}

const isDemo = process.env.REACT_APP_ENV === "demo";

class PageSettingsScreen extends React.Component<IPageSettingsScreenProps, IPageSettingsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      isSubscribed: false,
      displayName: "",
      visibility: "private",
      showTitle: "yes",
      showFirst: "yes",
      showLast: "yes",
      takingNewClients: "yes",
      meetInPerson: "yes",
      meetPhone: "yes",
      meetVideo: "yes",
      meetChat: "yes",
      contactEmail: "",
      showActivityCounts: "no",
      showActivityCountsByCategory: "yes",
      showClientCounts: "yes",
      bio: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      focusAreas: "",
      training: "",
      profileImagePath: "",
      file: null,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.save = this.save.bind(this);

  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    if(!this.state.isSubscribed){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <SubscriptionBlock featureDisplay="A Custom Landing Page" />
          </div>
        </div>
      );
    }
    if(isDemo){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            Sorry, this is not available in the demo.
          </div>
        </div>
      );
    }
    return (
      <Screen fileName="PageSettings.tsx">
        <div className="row">
            <div className="col-3">
                <Card title="About" loading={this.state.loading} help="">
                    <p>As a Pro Member, you are offered a custom landing page that is public. With it, you can show off your services and provide an easy way for others to contact you, safely and securely. You can turn it off at any time and customize what exactly is shown on your page!</p>
                    <p>Any fields left blank will not be shown on your page.</p>
                    <p><strong>Warning!</strong> Changing your display name will require you to change anywhere that you provided a link to your public page unless you used your unique user id. Change at your own risk!</p>
                </Card>
                <Card title="Branding" loading={this.state.loading}>
                  You can view your personalized links <Link to="/branding">here!</Link>
                </Card>
            </div>
            <div className="col-9">
                <Card title="Page Settings" loading={this.state.loading} help="">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group page-settings-row">
                                <label>Visibility</label>
                                <select className="form-control" id="visibility" value={this.state.visibility} onChange={this.updateField}>
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Show Title</label>
                                <select className="form-control" id="showTitle" value={this.state.showTitle} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Show First Name</label>
                                <select className="form-control" id="showFirst" value={this.state.showFirst} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Show Last Name</label>
                                <select className="form-control" id="showLast" value={this.state.showLast} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Contact Email if Different</label>
                                <input type="text" className="form-control" id="contactEmail" value={this.state.contactEmail} onChange={this.updateField} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Street</label>
                                <input type="text" className="form-control" id="street" value={this.state.street} onChange={this.updateField} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>City</label>
                                <input type="text" className="form-control" id="city" value={this.state.city} onChange={this.updateField} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>State</label>
                                <input type="text" className="form-control" id="state" value={this.state.state} onChange={this.updateField} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Zip</label>
                                <input type="text" className="form-control" id="zip" value={this.state.zip} onChange={this.updateField} />
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="form-group page-settings-row">
                                <label>Display Name for URL</label>
                                <input type="text" className="form-control" id="displayName" value={this.state.displayName} onChange={this.updateField} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Taking New Clients?</label>
                                <select className="form-control" id="takingNewClients" value={this.state.takingNewClients} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Do You Meet In-Person?</label>
                                <select className="form-control" id="meetInPerson" value={this.state.meetInPerson} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Do You Meet via Phone?</label>
                                <select className="form-control" id="meetPhone" value={this.state.meetPhone} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Do You Meet via Video Calls?</label>
                                <select className="form-control" id="meetVideo" value={this.state.meetVideo} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Do You Meet via Chat?</label>
                                <select className="form-control" id="meetChat" value={this.state.meetChat} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Show Current Connection Count</label>
                                <select className="form-control" id="showClientCounts" value={this.state.showClientCounts} onChange={this.updateField}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Show Activity Counts</label>
                                <select className="form-control" id="showActivityCounts" value={this.state.showActivityCounts} onChange={this.updateField}>
                                    <option value="all">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group page-settings-row">
                                <label>A Short Bio or Background</label>
                                <textarea className="form-control" id="bio" value={this.state.bio} onChange={this.updateField} rows={5} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Specialized Training</label>
                                <textarea className="form-control" id="training" value={this.state.training} onChange={this.updateField} rows={5} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Your Focus Areas</label>
                                <textarea className="form-control" id="focusAreas" value={this.state.focusAreas} onChange={this.updateField} rows={5} />
                            </div>
                            <div className="form-group page-settings-row">
                                <label>Your Public Image</label>
                                <img src={this.state.profileImagePath} style={{"width": "100%"}} />
                                <Dropzone onDrop={acceptedFiles => this.setState({ file: acceptedFiles[0]}, () => this.uploadFile())}>
                                  {({getRootProps, getInputProps}) => (
                                    <section className="dropzone">
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} className="form-control"/>
                                        <p>Click or Drop a new image here or click to select a file. It can take up to 20 minutes to update throughout the system.</p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-block btn-primary" onClick={this.save}>Save Settings</button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }


  private uploadFile(){
    this.setState({loading: true}, async () => {
      try{
        const result = await UserAPI.saveProfileImage(this.state.file);
        success("Upload has been sent and will be processed. This could take up to 20 minutes to be updated throughout the system.");
        this.setState({loading: false, file: null, profileImagePath: result.body.data.profileImagePath});
      }catch(e){
        error("We could not process that file. Please try again or contact support");
      }
    })
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      let isSubscribed = true;
      let displayName = "";
      
      try{
        const quotaResult = await QuotaAPI.getUserQuota();
        isSubscribed = quotaResult.body.data.isSubscribed;
        if(!isSubscribed){
            return this.setState({ loading: false, isSubscribed });
        }
      }catch(err){
        return this.setState({ loading: false, isSubscribed });
      }

      try{
        const profileResult = await UserAPI.getUserProfile();
        displayName = profileResult.body.data.displayName;
      }catch(err){

      }

      try{
        const result = await UserAPI.getProviderPageSettings();
        this.setState({ loading: false, isSubscribed, displayName, ...result.body.data });
      }catch(err){
        // in this case, we don't really care about an error, it's just nothing will be pre-filled
        this.setState({ loading: false, isSubscribed, displayName });
      }
    });
  }

  private save(){
    this.setState({ loading: true }, async () => {
        try{
            await UserAPI.updateUserProfile({ displayName: this.state.displayName });
        }catch(err){
            this.setState({ loading: false});
            return error("Could not update your display name");
        }

        try{
            await UserAPI.saveProviderPageSettings({...this.state});
            success("Successfully saved! It may take a few minutes to reflect any updates everywhere.")
        }catch(err){
            error("Could not save your page settings");
        } finally {
            this.setState({ loading: false});
        }
    })
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageSettingsScreen) as any);