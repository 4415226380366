import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActivitiesAPI } from "src/api";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import ActivityListItem from "src/components/screens/Activities/ActivityListItem";
import { IActivity } from "src/api/activities";
import { Translator } from "src/utils/translator";
import DatePicker from "src/components/structure/DatePicker";
import SingleActivityForm from "../Activities/SingleActivityForm";
import { IContact } from "src/api/contacts";

interface IContactDetailsActivityListProps {
  appActions: any;
  contact: IContact;
}

interface IContactDetailsActivityListState {
  loading: boolean;
  selectedCategory: string;
  start: moment.Moment;
  end: moment.Moment;
  activityCategories: string[];
  activities: IActivity[];
  showCreateModal: boolean;
}


const activitiesHelpText = Translator.getHelpText("en", "activities", "An Activity is something that you did, such as a counseling session or training event. Tags are used for analysis and reporting. You can optionally assign contacts to activities for tracking purposes.");

// TODO: For MVP, just make the calls, but in the future we probably want a filteredActivities and when the category changes, 
// update that rather than making another network call

class ContactDetailsActivityList extends React.Component<IContactDetailsActivityListProps, IContactDetailsActivityListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      start: moment().subtract(3, "months"),
      end: moment(),
      selectedCategory: "",
      activityCategories: [],
      activities: [],
      showCreateModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.fetchActivityCategories = this.fetchActivityCategories.bind(this);
    this.fetchActivities = this.fetchActivities.bind(this);
    this.handleCreatedActivity = this.handleCreatedActivity.bind(this);
    this.handleDeletedActivity = this.handleDeletedActivity.bind(this);
    this.handleUpdatedActivity = this.handleUpdatedActivity.bind(this);
    this.toggleCreateSingleActivityModal = this.toggleCreateSingleActivityModal.bind(this);
  }

  componentDidMount(){
    this.fetchActivityCategories();
  }

  public render() {
    return (
      <Screen fileName="Contacts/ContactDetailsActivityList.tsx">
        <Card title="Activities" loading={this.state.loading} help={activitiesHelpText}>
          <div className="row" style={{ marginBottom: 20}}>
            <div className="col-lg-3 col-sm-12">
              <label>From</label>
              <DatePicker 
                date={this.state.start}
                onDateSaved={this.updateStart}
              />
            </div>
            <div className="col-lg-3 col-sm-12">
              <label>To</label>
              <DatePicker 
                date={this.state.end}
                onDateSaved={this.updateEnd}
              />
            </div>
            <div className="col-lg-3 col-sm-12">
              <label>Category</label>
              <select className="form-control" id="selectedCategory" value={this.state.selectedCategory} onChange={this.updateField}>
                <option value="all">Show: All</option>
                {this.state.activityCategories.map((cat: string) => {
                  return ( <option key={cat} value={cat}>Show: {ActivitiesAPI.translateCategory(cat)}</option>)
                })}
              </select>
            </div>
            <div className="col-lg-3 col-sm-12">
              <button className="btn btn-primary btn-block" onClick={this.toggleCreateSingleActivityModal} style={{marginTop: 32}}>New</button>
            </div>
          </div>
          {this.state.activities.length === 0 && (<div className="row"><div className="col-12" style={{textAlign: "center", fontWeight: "bold"}}>None</div></div>)}
          {this.state.activities.map((activity: IActivity) => {
            return (
              <ActivityListItem 
                key={activity.id} 
                activity={activity} 
                categories={this.state.activityCategories} 
                onDelete={this.handleDeletedActivity} 
                onEdit={this.handleUpdatedActivity} />
            )
          })}
        </Card>
        <SingleActivityForm
          show={this.state.showCreateModal}
          mode="create"
          onSaveActivity={this.handleCreatedActivity}
          onToggleForm={this.toggleCreateSingleActivityModal}
          activityCategories={this.state.activityCategories}
          initialContact={this.props.contact}
        />
      </Screen>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "selectedCategory"){
        this.fetchActivities();
      }
    });
  }

  private updateStart(newDate: moment.Moment){
    this.setState({start: newDate}, () => {this.fetchActivities()});
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({end: newDate}, () => {this.fetchActivities()});
  }

  private toggleCreateSingleActivityModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private fetchActivityCategories(){
    this.setState({loading: true}, async () => {
      try{
        const res = await ActivitiesAPI.getActivityCategories();
        const categories = res.body.data;
        this.setState({
          activityCategories: categories,
        }, () => {
          this.fetchActivities();
        });
      }catch(e){
        // ?????
        this.setState({loading: false});
      }
    });
  }

  private fetchActivities(){
    this.setState({loading: true}, async () => {
      try{
        const data = {
          start: this.state.start.format("YYYY-MM-DD"),
          end: this.state.end.format("YYYY-MM-DD"),
          category: this.state.selectedCategory,
        }
        const result = await ActivitiesAPI.getAllActivitiesForContact(this.props.contact.id, data);
        const activities = result.body.data;
        this.setState({ loading: false, activities});
      }catch(err){
        // shouldn't be a big deal I guess?
        this.setState({ loading: false });
      }
    });
  }

  private handleDeletedActivity(activity: IActivity){
    const activities: IActivity[] = [];
    for(const a of this.state.activities){
      if(a.id !== activity.id){
        activities.push(a);
      }
    }
    this.setState({ activities });
  }

  private handleUpdatedActivity(activity: IActivity){
    const activities: IActivity[] = [];
    for(const a of this.state.activities){
      if(a.id === activity.id){
        activities.push(activity);
      } else {
        activities.push(a);
      }
    }
    this.setState({ activities });
  }

  private handleCreatedActivity(activity: IActivity){
    const activities: IActivity[] = this.state.activities;
    activities.push(activity);
    this.setState({ activities, showCreateModal: false });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsActivityList);