import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import ContactDetailsInfoAndNotes from "./ContactDetailsInfoAndNotes";
import ContactDetailsActivityList from "./ContactDetailsActivityList";
import ContactDetailsMilestoneList from "./ContactDetailsMilestonesList";
import * as ContactActions from "../../../reducers/contactsReducer";
import { BlankContact, IContact } from "src/api/contacts";
import { ContactsAPI } from "src/api";

interface IContactDetailsScreenProps {
  contactActions: any;
  contactState: any;
  history: any;
  match: any;
}

interface IContactDetailsScreenState {
  loading: boolean;
  contact: IContact;
}

// TODO: we should change the milestones to take the contact and use a callback handler for updates on the contact
// to propogate info

class ContactDetailsScreen extends React.Component<IContactDetailsScreenProps, IContactDetailsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      contact: BlankContact,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  public render() {
    return (
      <Screen fileName="src/components/screens/Contacts/ContactDetailsScreen.tsx">
        {this.state.loading ? ( null ) : (
          <div>
            <div className="row" style={{ marginBottom: 20 }}>
              <div id="contactsNavBar" className="col-lg-8 offset-lg-2 col-md-12">
                <nav className="navbar navbar-expand-lg navbar-dark" style={{ borderRadius: "5px" }}>
                  <div className="navbar-header">
                    <Link
                      to={`contacts/${this.props.match.params.id}"`}
                      className="navbar-brand"
                      style={{fontWeight: "bold", marginRight: 50}}>
                      {this.state.contact.title} {this.state.contact.firstName} {this.state.contact.lastName}
                    </Link>                    
                  </div>

                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContactSupportedContent" aria-controls="navbarContactSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarContactSupportedContent">
                    {this.menu}
                  </div>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.component}
              </div>
            </div>
          </div>
        )}
        
      </Screen>
    );
  }



  get menu() {
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "info";
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to={`/contacts/${this.props.match.params.id}/`}
            className={active === "info" ? "nav-link sub-nav-link-active" : "nav-link subNavLink"}>Info and Notes</Link>
        </li>
        <li className="nav-item">
          <Link to={`/contacts/${this.props.match.params.id}/activities`}
            className={active === "activities" ? "nav-link sub-nav-link-active" : "nav-link subNavLink"}>Activities</Link>
        </li>
        <li className="nav-item">
          <Link to={`/contacts/${this.props.match.params.id}/milestones`}
            className={active === "milestones" ? "nav-link sub-nav-link-active" : "nav-link subNavLink"}>Milestones</Link>
        </li>
      </ul>
    );
  }

  private get component() {
    if (!this.props.match.params.view) {
      return <ContactDetailsInfoAndNotes />;
    }
    switch (this.props.match.params.view) {
      case "activities":
        return <ContactDetailsActivityList contact={this.state.contact} />;
      case "milestones":
        return <ContactDetailsMilestoneList contactId={this.state.contact.id} />
      case "info":
      default:
        return <ContactDetailsInfoAndNotes />;
    }

  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try {
        const contactResult = await ContactsAPI.getContact(this.props.match.params.id);
        this.setState({ loading: false, contact: contactResult.body.data });
      } catch (err) {
        // weird....
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    contactState: s.contactsState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    contactActions: bindActionCreators(ContactActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactDetailsScreen) as any);