import { createAction, createReducer } from "redux-act";

export const setContacts: any = createAction("contacts - set");
export const addContact: any = createAction("contacts - add");
export const removeContact: any = createAction("contacts - remove");
export const setContactGroups: any = createAction("contact groups - set");
export const addContactGroup: any = createAction("contact groups - add");
export const removeContactGroup: any = createAction("contact groups - remove");
export const updateContactGroup: any = createAction("contact groups - update");

export default createReducer({
  [setContacts]: (state: any, contacts: any[]) => {
    for(const c of contacts){
      c.label = `${c.title} ${c.firstName} ${c.lastName}`;
    }
    contacts.sort((a, b) => {
      if(a.lastName === b.lastName){
        return a.firstName > b.firstName ? 1 : -1;
      }
      return a.lastName > b.lastName ? 1 : -1;
    })
    return {
      ...state, 
      contacts,
    };
  },
  [removeContact]: (state: any, contactToRemoveId: number) => {
    const contacts: any[] = [];
    for(const c of state.contacts){
      if(c.id !== contactToRemoveId){
        c.label = `${c.title} ${c.firstName} ${c.lastName}`;
        contacts.push(c);
      }
    }
    return {
      ...state, 
      contacts,
    };
  },
  [addContact]: (state: any, newContact: any) => {
    const contacts: any[] = state.contacts;
    contacts.push(newContact);
    contacts.sort((a, b) => {
      if(a.lastName === b.lastName){
        return a.firstName > b.firstName ? 1 : -1;
      }
      return a.lastName > b.lastName ? 1 : -1;
    })
    return {
      ...state, 
      contacts,
    };
  },
  [setContactGroups]: (state: any, contactGroups: any[]) => {
    for(const c of contactGroups){
      c.label = c.groupName;
    }
    contactGroups.sort((a, b) => {
      return a.groupName > b.groupName ? 1 : -1;
    })
    return {
      ...state, 
      contactGroups
    };
  },
  [removeContactGroup]: (state: any, groupToRemoveId: number) => {
    const contactGroups: any[] = [];
    for(const c of state.contactGroups){
      if(c.id !== groupToRemoveId){
        c.label = c.groupName;
        contactGroups.push(c);
      }
    }
    return {
      ...state, 
      contactGroups,
    };
  },
  [addContactGroup]: (state: any, newContactGroup: any) => {
    const contactGroups: any[] = state.contactGroups;
    contactGroups.push(newContactGroup);
    contactGroups.sort((a, b) => {
      return a.groupName > b.groupName ? 1 : -1;
    })
    return {
      ...state, 
      contactGroups,
    };
  },
  [updateContactGroup]: (state: any, updatedContactGroup: any) => {
    const contactGroups: any[] = [];
    for(const c of contactGroups){
      if(c.id === updatedContactGroup.id){
        contactGroups.push(updatedContactGroup);
      } else {
        contactGroups.push(c);
      }
    }
    contactGroups.sort((a, b) => {
      return a.groupName > b.groupName ? 1 : -1;
    })
    return {
      ...state, 
      contactGroups,
    };
  },
}, {
    contacts: [],
    contactGroups: [],
});