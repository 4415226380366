import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { UserAPI } from "src/api";
import { IUserToUserConnection } from "src/api/user";
import { error, success } from "src/components/structure/Alert";
import { Modal } from "react-bootstrap";

interface IConnectionsScreenProps {
  userState: any;
}

interface IConnectionsScreenState {
  loading: boolean;
  connections: IUserToUserConnection[];
  inviteEmail: string;
  showInviteModal: boolean;
}

class ConnectionsScreen extends React.Component<IConnectionsScreenProps, IConnectionsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      connections: [],
      inviteEmail: "",
      showInviteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.toggleShowInviteModal = this.toggleShowInviteModal.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ConnectionsScreen.tsx">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-12">
            <Card title="Connections" loading={this.state.loading} help="Connections are other users who have been invited to create an account and connect with you. Connections can fill out tools and inventories then share them with you. You can also request that they fill out tools and inventories on a schedule.">
              {this.state.connections.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <strong>No connections have been requested or established</strong>
                  </div>
                </div>
              )}
              {this.state.connections.length !== 0 && (
                <div className="row list-row-header">
                  <div className="col-lg-5">
                    Name or Email
                  </div>
                  <div className="col-lg-3">
                    Status
                  </div>
                  <div className="col-lg-3">
                    Your Role
                  </div>
                </div>
              )}
              {this.state.connections.map((c) => {
                const firstName = c.providerId === this.props.userState.user.id ? c.userFirstName : c.providerFirstName;
                const lastName = c.providerId === this.props.userState.user.id ? c.userLastName : c.providerLastName;
                const display = firstName === "" && lastName === "" ? c.email === "" ? "Unknown" : c.email : `${c.userFirstName} ${c.userLastName}`
                return (
                  <div key={c.id} className="row list-item" style={{ marginTop: 5 }}>
                    <div className="col-lg-5">
                      {display}
                    </div>
                    <div className="col-lg-3">
                      {c.status}
                    </div>
                    <div className="col-lg-3">
                      {c.providerId === this.props.userState.user.id ? ("Provider") : ("Client")}
                    </div>
                    <div className="col-lg-1">
                      {c.status === "accepted" && (
                        <Link to={`/connections/client/${c.userId}`}><i className="fas fa-arrow-right" aria-hidden={true} /></Link>
                      )}
                    </div>
                  </div>
                )
              })}

              <div className="form-group" style={{ marginTop: 10 }}>
                <button className="btn btn-block btn-primary" onClick={this.toggleShowInviteModal}>Invite a User to Connect</button>
              </div>
            </Card>
          </div>
        </div>

        <Modal show={this.state.showInviteModal} onHide={this.toggleShowInviteModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Invite a User to Connect</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Connecting with other users unlocks new ways to help and support them. You will be able to share tools and inventories with them and see their results when shared. It's completely free for them! Upon submitting, an email will be sent to the email address entered inviting the user to either create an account or respond to the invitation.</p>
            <div className="form-group">
              <label htmlFor="inviteEmail">Email</label>
              <input type="text" className="form-control" id="inviteEmail" value={this.state.inviteEmail} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-12">
                <button className="btn btn-block btn-primary" onClick={this.inviteUser}>Invite!</button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button className="btn btn-block btn-secondary" onClick={this.toggleShowInviteModal}>Cancel</button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleShowInviteModal() {
    this.setState({ showInviteModal: !this.state.showInviteModal });
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try {
        const connectionsResult = await UserAPI.getUserConnections();
        const connections: IUserToUserConnection[] = [];
        for (const c of connectionsResult.body.data) {
          c.created = moment(c.created);
          c.updated = moment(c.updated);
          connections.push(c);
        }
        this.setState({ loading: false, connections });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private inviteUser() {
    const email = this.state.inviteEmail.trim();
    if (email === "") {
      return error("Email cannot be blank.");
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.createUserConnection({
          email,
          providerId: this.props.userState.user.id,
        });
        success("Invited!");
        this.setState({ loading: false, showInviteModal: false }, () => this.setup());
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectionsScreen) as any);