import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";

import Card from "src/components/structure/Card";
import { IntegrationsAPI } from "src/api";
import { error, success } from "src/components/structure/Alert";
import { Modal } from "react-bootstrap";

interface ITodoistIntegrationProps {
  userState: any;
  location: any;
}

interface ITodoistIntegrationState {
  loading: boolean;
  url: string;
  status: "inactive" | "active" | "setting_up";
  code: string;
  oAuthState: string;
  showDisconnectModal: boolean;
}

// BEFORE GOING LIVE:
// - Have a better code
// - Update logos, etc
// - Check for subscription status
// - Check if they are already connected
// - Put in copy for why we need these permissions

class TodoistIntegration extends React.Component<ITodoistIntegrationProps, ITodoistIntegrationState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      url: "https://todoist.com/oauth/authorize?",
      status: "inactive",
      code: "",
      oAuthState: this.props.userState.user.id,
      showDisconnectModal: false,
    };

    this.setup = this.setup.bind(this);
    this.toggleDisconnectModal = this.toggleDisconnectModal.bind(this);
    this.finishSetup = this.finishSetup.bind(this);
    this.disconnectTodoist = this.disconnectTodoist.bind(this);

  }

  componentDidMount() {
    this.setup()
  }

  public render() {
    if(this.state.status === "inactive"){
      return (
        <div className="row">
          <div className="col-6 offset-lg-3">
            <Card title="Set Up Todoist!" loading={this.state.loading} help="">
              <p><a href="https://todoist.com" target="_new">Todoist</a> is a To Do tracking app that makes it easy to keep track of outstanding tasks on the go. By linking Todoist to your account, you
              will be able to sync your Reminders and Activity Follow Up to Todoist!</p>
              <p>When you click Set Up below, you will go to the Todoist app to link your account. We need permission to read, write, and delete tasks and projects. We will only ever read, write, or modify projects or tasks created
                as a result of this integration. If you have specific questions, you can always contact support!
              </p>
              <a className="btn btn-block btn-primary" href={this.state.url}>Set Up</a>
            </Card>
          </div>
        </div>
      );
    }
    if(this.state.status === "active"){
      return (
        <div className="row">
          <div className="col-6 offset-lg-3">
            <Card title="Todoist" loading={this.state.loading} help="">
              <p>Todoist is connected! Your reminders and tasks should sync with Todoist.</p>
              <div className="form-group">
                <button className="btn btn-block btn-danger" onClick={this.toggleDisconnectModal}>Disconnect</button>
              </div>
            </Card>
          </div>
          <Modal show={this.state.showDisconnectModal} onHide={this.toggleDisconnectModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Disconnect Todoist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong className="text-danger">Warning! </strong> This will delete the tasks, sections, labels, and projects associated with Voithos. This cannot be undone. Reconnecting Voithos will re-create all Todoist entries as if it was the first time being set up.
              
            </Modal.Body>
            <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.disconnectTodoist}>Disconnect Todoist</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDisconnectModal}>Cancel</button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    if(this.state.status === "setting_up"){
      return (
        <div className="row">
          <div className="col-6 offset-lg-3">
            <Card title="Finishing Set Up" loading={this.state.loading} help="">
              <p>Hold tight, we are finishing setting up the link...</p>
              <div style={{textAlign: "center"}}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )
    }
    return null;
  }


  private setup() {
    let code = "";
    let oAuthState = this.props.userState.user.id;
    const url = `${this.state.url}client_id=e28711ec451d4305a230ffecfd83b0bc&scope=data:read_write,data:delete,project:delete&state=${oAuthState}`;

    this.setState({ loading: true, oAuthState, url }, async () => {
      // first, see if it is already set up; if so, just stop
      try{
        const connResult = await IntegrationsAPI.getIntegration("todoist");
        const data = connResult.body.data;
        if(data && data.status && data.status === "active"){
          return this.setState({ loading: false, status: "active" });
        }
        return;
      }catch(err){}
      let status: "active" | "inactive" | "setting_up" = "inactive";
      const params = qs.parse(this.props.location.search);
      if(params.state && params.code){

        const st: string = typeof params.state === "object" && params.state.length > 0 ? params.state[0] : params.state.toString();
        const stInt = parseInt(st, 10);
        console.log(typeof stInt, stInt, typeof this.props.userState.user.id, this.props.userState.user.id)
        if(stInt !== this.props.userState.user.id){
          // uh oh?
          console.log("State manipulated!");
          this.setState({ loading: false});
          return error("Your challenge seems to have been manipulated, so we have stopped the connection.");
        }
        status = "setting_up";
        const c: string = typeof params.code === "object" && params.code.length > 0 ? params.code[0] : params.code.toString();
        return this.finishSetup(c);
      }else {
        try {
          const statusResult = await IntegrationsAPI.getIntegration("todoist");
          status = statusResult.body.data.status;
        } catch (err) { }
        this.setState({
          loading: false,
          status,
          code,
        });
      }
    })
  }

  private finishSetup(code: string){
    // this could take a while so we set loading to false, then when done, we change the state
    this.setState({ status: "setting_up", loading: false}, async () => {
      try{
        await IntegrationsAPI.setUpIntegration("todoist", {
          code,
        });
        this.setState({ loading: false, status: "active"});
      }catch(err){
        error("We could not complete the set up process. Please try again or contact support.");
        this.setState({ status: "inactive", loading: false});
      }
    })
  }

  private toggleDisconnectModal(){
    this.setState({ showDisconnectModal: !this.state.showDisconnectModal});
  }

  private disconnectTodoist(){
    this.setState({ loading: true }, async () => {
      try{
        await IntegrationsAPI.disconnectIntegration("todoist");
        success("Todoist disconnected!");
        this.setState({ showDisconnectModal: false, loading: false, status: "inactive"});
      }catch(err){
        error("Could not disconnect. You could try again or contact support.");
        this.setState({ loading: false });
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TodoistIntegration) as any);