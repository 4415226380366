import * as React from "react";
import { Link } from "react-router-dom";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import DatePicker from "src/components/structure/DatePicker";
import { ToolsAPI } from "src/api";
import { BlankTool, ITool, IToolRequest, IToolShare } from "src/api/tools";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";

interface IManageConnectionSubmissionsProps {
  clientId: number;
  tools: ITool[];
  availableCategories: string[];
}

interface IManageConnectionSubmissionsState {
  loading: boolean;
  sharedSubmissions: IToolShare[];
  requests: IToolRequest[];
  showDeleteRequestModal: boolean;
  selectedRequestId: number;
  
  showNewRequestModal: boolean;

  newRequestCategory: string;
  newRequestTool: ITool;
  newRequestSchedule: "once" | "daily" | "weekly";
  newRequestDueOn: moment.Moment;
}

export default class ManageConnectionSubmissions extends React.Component<IManageConnectionSubmissionsProps, IManageConnectionSubmissionsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      sharedSubmissions: [],
      requests: [],
      showDeleteRequestModal: false,
      selectedRequestId: 0,

      showNewRequestModal: false,

      newRequestCategory: "",
      newRequestTool: BlankTool,
      newRequestSchedule: "once",
      newRequestDueOn: moment().add(1, "days"),
    };

    this.updateField = this.updateField.bind(this);
    this.updateNewRequestDueOn = this.updateNewRequestDueOn.bind(this);
    this.toggleDeleteRequestModal = this.toggleDeleteRequestModal.bind(this);
    this.toggleCreateRequestModal = this.toggleCreateRequestModal.bind(this);
    this.createRequest = this.createRequest.bind(this);
    this.deleteRequest = this.deleteRequest.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ManageConnectionSubmissions.tsx">
        <div className="row">
          <div className="col-lg-6">
            <Card title="Requests" loading={this.state.loading} help="">
              {this.state.requests.length === 0 && (
                <p><strong>You have no requests initiated</strong></p>
              )}
              {this.state.requests.length !== 0 && (
                <div className="row list-row-header">
                  <div className="col-lg-3 col-12">
                    User
                  </div>
                  <div className="col-lg-4 col-12">
                    Tool
                  </div>
                  <div className="col-lg-2 col-12">
                    Due On
                  </div>
                  <div className="col-lg-2 col-12">
                    Status
                  </div>
                </div>
              )}
              {this.state.requests.map((req) => {
                return (
                  <div className="row" key={req.id} style={{marginBottom: 5}}>
                    <div className="col-lg-3 col-12">
                      {req.userFirstName} {req.userLastName}
                    </div>
                    <div className="col-lg-4 col-12">
                      {ToolsAPI.translateToolTypeToDisplay(req.toolType)}
                    </div>
                    <div className="col-lg-2 col-12">
                      {req.dueOn.format("MM/DD/YYYY")}
                    </div>
                    <div className="col-lg-2 col-12">
                      {req.status}
                    </div>
                    <div className="col-lg-1 col-12">
                      {req.status === "active" && (
                        <i className="fas fa-trash text-danger" aria-hidden={true} onClick={() => {
                          this.setState({ showDeleteRequestModal: true, selectedRequestId: req.id});
                        }}/>
                      )}
                    </div>
                  </div>
                )
              })}
              <div className="form-group" style={{marginTop: 15}}>
                <button className="btn btn-block btn-primary" onClick={this.toggleCreateRequestModal}>New Request</button>
              </div>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card title="Submissions" loading={this.state.loading} help="">
              {this.state.sharedSubmissions.length === 0 && (
                <p><strong>You have no submissions shared with you</strong></p>
              )}
              {this.state.sharedSubmissions.length !== 0 && (
                <div className="row list-row-header">
                  <div className="col-lg-4 col-12">
                    User
                  </div>
                  <div className="col-lg-4 col-12">
                    Tool
                  </div>
                  <div className="col-lg-3 col-12">
                    Submitted
                  </div>
                  <div className="col-lg-1 col-12">
                  </div>
                </div>
              )}
              {this.state.sharedSubmissions.map((sub) => {
                return (
                  <div className="row" key={sub.submissionId} style={{marginBottom: 5}}>
                    <div className="col-lg-4 col-12">
                      {sub.creatorFirstName} {sub.creatorLastName}
                    </div>
                    <div className="col-lg-4 col-12">
                      {ToolsAPI.translateToolTypeToDisplay(sub.toolType)}
                    </div>
                    <div className="col-lg-3 col-12">
                      {sub.submittedOn.format("MM/DD/YYYY")}
                    </div>
                    <div className="col-lg-1 col-12">
                      <Link to={`/connections/client/${sub.creatorId}/${sub.toolType}/submissions/${sub.submissionId}`}><i className="fas fa-arrow-right" aria-hidden={true} /></Link>
                    </div>
                  </div>
                )
              })}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showNewRequestModal} onHide={this.toggleCreateRequestModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="newRequestCategory">Category:</label>
              <select className="form-control" id="newRequestCategory" value={this.state.newRequestCategory} onChange={this.updateField}>
                <option key="0" value="" disabled={true}>Select a Category</option>
                {this.props.availableCategories.map((cat) => {
                  return <option key={cat}>{cat}</option>
                })}
              </select>
            </div>
            {this.state.newRequestCategory !== "" && (
              <div className="form-group">
                <label htmlFor="newRequestTool">Tool:</label>
                <select className="form-control" id="newRequestTool" value={this.state.newRequestTool.toolType} onChange={this.updateField}>
                  <option key="0" value="" disabled={true}>Select a Tool</option>
                  {this.props.tools.map((tool) => {
                    if(tool.active && tool.category === this.state.newRequestCategory){
                      return <option key={tool.toolType} value={tool.toolType}>{tool.display}</option>
                    }
                    return null;
                  })}
                </select>
              </div>
            )}
            {this.state.newRequestCategory !== "" && this.state.newRequestTool.toolType && (
              <div className="form-group">
                <p>{this.state.newRequestTool.description}</p>
              </div>
            )}
            {this.state.newRequestCategory !== "" && this.state.newRequestTool.toolType && (
              <div className="form-group">
                <label htmlFor="newRequestDueOn">Due On</label>
                <DatePicker date={this.state.newRequestDueOn} onDateSaved={this.updateNewRequestDueOn} />
              </div>
            )}
            {this.state.newRequestCategory !== "" && this.state.newRequestTool.toolType && (
              <div className="form-group">
                <label htmlFor="newRequestSchedule">Schedule:</label>
                <select className="form-control" id="newRequestSchedule" value={this.state.newRequestSchedule} onChange={this.updateField}>
                  <option key="once" value="once">Just Once</option>
                  <option key="daily" value="daily">Every Day</option>
                  <option key="weekly" value="weekly">Every Week</option>
                </select>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createRequest}>Begin this Request</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-secondary" onClick={this.toggleCreateRequestModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteRequestModal} onHide={this.toggleDeleteRequestModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Warning: </strong> you are about to delete this request. It will not remove previously shared submissions but will no longer request the user to complete this tool. This cannot be undone!</p>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteRequest}>Yes, Delete This Request</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-secondary" onClick={this.toggleDeleteRequestModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns: any = this.state;

    if(id === "newRequestCategory" && ns[id] !== val){
      ns[id] = val;
      ns["newRequestTool"] = BlankTool;
      this.setState(ns);
    } else if(id === "newRequestTool"){
      // update the tool as well
      let tool: any = {};
      for(const t of this.props.tools){
        if(t.toolType === val){
          tool = t;
          break;
        }
      }
      ns.newRequestTool = tool;
      this.setState(ns);
    } else {
      ns[id] = val;
      this.setState(ns);
    }
  }

  private updateNewRequestDueOn(newDate: moment.Moment){
    this.setState({ newRequestDueOn: newDate });
  }

  private toggleDeleteRequestModal(){
    this.setState({ showDeleteRequestModal: !this.state.showDeleteRequestModal});
  }

  private toggleCreateRequestModal(){
    this.setState({ showNewRequestModal: !this.state.showNewRequestModal});
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{

        // we need to get the requested submissions and shared submissions
        // eventually, we would probably want to get the timeline?
        const shareResults = await ToolsAPI.getAllSubmissionSharesForClient(this.props.clientId);
        const sharedSubmissions: IToolShare[] = [];
        for(const s of shareResults.body.data){
          s.sharedOn = moment(s.sharedOn);
          s.submittedOn = moment(s.submittedOn);
          sharedSubmissions.push(s);
        }

        const requestResults = await ToolsAPI.getRequests(this.props.clientId);
        const requests: IToolRequest[] = [];
        for(const r of requestResults.body.data){
          r.dueOn = moment(r.dueOn);
          r.requestedOn = moment(r.requestedOn);
          requests.push(r);
        }

        

        this.setState({ 
          loading: false, 
          sharedSubmissions, 
          requests,
        });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private createRequest(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          dueOn: this.state.newRequestDueOn.format("YYYY-MM-DD"),
          scheduleType: this.state.newRequestSchedule,
        }
        const result = await ToolsAPI.requestSubmission(this.state.newRequestTool.toolType, this.props.clientId, data);
        success("Request successfully submitted!");
        const requests: IToolRequest[] = this.state.requests;
        const newRequest = result.body.data;
        newRequest.dueOn = moment(newRequest.dueOn);
        newRequest.requestedOn = moment(newRequest.requestedOn);
        requests.push(newRequest);
        requests.sort((a, b) => {
          return a.dueOn.isBefore(b.dueOn) ? 1 : -1;
        })
        this.setState({ 
          showNewRequestModal: false, 
          loading: false, 
          requests,
          newRequestCategory: "",
          newRequestTool: BlankTool,
          newRequestSchedule: "once",
          newRequestDueOn: moment().add(1, "days"),
        });
        
      }catch(err){
        error("Could not create that request. Please try again or contact support.")
        this.setState({ loading: false });
      }
    });
  }

  private deleteRequest(){
    this.setState({ loading: true }, async () => {
      try{
        await ToolsAPI.deleteRequest(this.props.clientId, this.state.selectedRequestId);
        const requests: IToolRequest[] = [];
        for(const r of this.state.requests){
          if(r.id !== this.state.selectedRequestId){
            requests.push(r);
          }
        }
        this.setState({ loading: false, requests, showDeleteRequestModal: false });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

}