import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { Translator } from "../../../utils/translator";
import { UserAPI } from "../../../api";
import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";

interface IProfileProps {
  appActions: any;
  history: any;
}

interface IProfileState {
  loading: boolean;
  id: number;
  title: string;
  firstName:  string;
  lastName:  string;
  branch:  string;
  email: string;
  password: string; // used for password changes
  smsNumber: string;
  voiceNumber: string;
  created:  moment.Moment;
  updated: moment.Moment;
  lastLogin: moment.Moment;
  membership:  string;
  currentPlanId: number;
  status:  string;
  shortNameDisplay:  string;
  displayName: string;
  timezone:  string;
  allowContactForm: string;
}

const branches = ["Army", "Air Force", "Navy", "Marines", "Coast Guard", "Civil Air Patrol", "Other", "Civilian"];
const timezones = [
  "America/Puerto_Rico",
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "Pacific/Honolulu",
];
const profileHelpText = Translator.getHelpText("en", "profileForm");

class Profile extends React.Component<IProfileProps, IProfileState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      id: 0,
      title: "",
      firstName: "",
      lastName: "",
      branch: "",
      email: "",
      password: "",
      smsNumber: "",
      voiceNumber: "",
      created: moment(),
      updated: moment(),
      lastLogin: moment(),
      membership: "free",
      currentPlanId: 0,
      status: "verified",
      shortNameDisplay: "",
      displayName: "",
      timezone: "America/New_York",
      allowContactForm: "no",
    };

    this.updateField = this.updateField.bind(this);
    this.fetch = this.fetch.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <Card title="Profile" loading={this.state.loading} help={profileHelpText}>
        
        <div className="form-group">
          <label>Rank / Title</label>
          <input type="text" id="title" className="form-control" value={this.state.title} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>First Name</label>
          <input type="text" id="firstName" className="form-control" value={this.state.firstName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" id="lastName" className="form-control" value={this.state.lastName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Cell Phone</label>
          <input type="text" id="smsNumber" className="form-control" value={this.state.smsNumber} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Display Name</label>
          <input type="text" id="displayName" className="form-control" value={this.state.displayName} onChange={this.updateField} />
          <span className="text-small" style={{fontSize: 10}}>A unique alphanumeric string used for pages and quick messages. No spaces or punctuation. At least 5 characters.</span>
        </div>
        <div className="form-group">
          <label>Branch</label>
          <select id="branch" className="form-control" value={this.state.branch} onChange={this.updateField}>
            {branches.map((branch: string) => {
              return (<option value={branch} key={branch}>{branch}</option>);
            })}
          </select>
        </div>
        <div className="form-group">
          <label>Timezone</label>
          <select id="timezone" className="form-control" value={this.state.timezone} onChange={this.updateField}>
            {timezones.map((tz: string) => {
              return (<option value={tz} key={tz}>{tz}</option>);
            })}
          </select>
        </div>
        <div className="form-group">
          <label>Allow Contact Form</label>
          <select id="allowContactForm" className="form-control" value={this.state.allowContactForm} onChange={this.updateField}>
            <option value="yes">Yes, Allow A Public Contact Form</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="form-group">
          Member since {this.state.created.format("MM/DD/YY")}< br />
          Last logged in on {this.state.lastLogin.format("MM/DD/YY")}
        </div>
        <div className="form-group">
          <button className="btn btn-block btn-primary" onClick={this.updateUser}>Save</button>
        </div>
      </Card>
    );
  }

  private updateField(e: any){
    const id: string = e.target.id;
    let val = e.target.value;
    const ns = {...this.state};
    if(id === "displayName"){
      if(/^[A-Za-z0-9]*$/.test(val)){
        const newVal = val.toLowerCase();
        ns[id] = newVal;
      }
    } else {
      ns[id] = val;
    }
    this.setState(ns);
  }

  private fetch(){
    this.setState( {loading: true }, async () => {
      try{
        const result = await UserAPI.getUserProfile();
        // break it apart
        const userData = result.body.data;
        userData.created = moment(userData.created);
        userData.lastLogin = userData.lastLogin === "" ? moment() : moment(userData.lastLogin);

        this.setState({ loading: false, ...userData});
      }catch(err){}
    });
  }

  private updateUser(){
    const data = {
      title: this.state.title.trim(),
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      branch: this.state.branch.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      smsNumber: this.state.smsNumber.trim(),
      voiceNumber: this.state.voiceNumber.trim(),
      timezone: this.state.timezone.trim(),
      displayName: this.state.displayName.trim(),
      allowContactForm: this.state.allowContactForm.trim(),
    }
    if(data.firstName === "" || data.lastName === "" || data.email === ""){
      return error("First name, last name, and email are all required");
    }
    this.setState({loading: false}, async () => {
      try{
        await UserAPI.updateUserProfile(data);
        success("Saved!")
        this.setState({loading: false});
      }catch(err){
        error("Could not save that information. Please check your data and try again.")
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile) as any);