import { makeCall } from "./client";

export class JournalAPI {

  public getJournalEntries(start: string, end: string, themeId: number = 0, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end,
      themeId,
    };
    return makeCall("get", `journal/entries`, data);
  }

  public createJournalEntry(otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("post", `journal/entries`, data);
  }
  
  public getJournalEntry(entryId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `journal/entries/${entryId}`, data);
  }
  
  public updateJournalEntry(entryId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("patch", `journal/entries/${entryId}`, data);
  }
  
  public deleteJournalEntry(entryId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `journal/entries/${entryId}`, data);
  }

  //
  // themes
  //
  
  public createJournalThemes(otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("gepostt", `journal/themes`, data);
  }

  public getJournalThemes(otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `journal/themes`, data);
  }

  public updateJournalTheme(themeId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("patch", `journal/themes/${themeId}`, data);
  }

  public deleteJournalTheme(themeId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `journal/themes/${themeId}`, data);
  }

  //
  // theme links
  //

  public linkEntryAndTheme(entryId: number, themeId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("put", `journal/entries/${entryId}/themes/${themeId}`, data);
  }

  public unlinkEntryAndTheme(entryId: number, themeId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `journal/entries/${entryId}/themes/${themeId}`, data);
  }

  //
  // shares
  //

  public getSharedEntries(start: string = "2020-01-01", end: string = "2080-01-01", count: number = 1000, offset: number = 0, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end,
      count,
      offset
    };
    return makeCall("get", `journal/entries/shared`, data);
  }

  public getSharedEntriesByCreator(creatorId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `journal/entries/shared/${creatorId}`, data);
  }

  
  public shareJournalEntry(entryId: number, targetId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("put", `journal/entries/${entryId}/shares/${targetId}`, data);
  }

  public unshareJournalEntry(entryId: number, targetId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `journal/entries/${entryId}/shares/${targetId}`, data);
  }
  
  public getJournalEntryShares(entryId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `journal/entries/${entryId}/shares`, data);
  }
}