import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { Translator } from "../../../utils/translator";
import { ActivitiesAPI } from "src/api";
import { IActivity } from "src/api/activities";
import ActivityListItem from "./ActivityListItem";
import ActivityStats from "./ActivityStats";
import SingleActivityForm from "./SingleActivityForm";
import BulkActivityForm from "./BulkActivityForm";

import Card from "../../structure/Card";
import DatePicker from "../../structure/DatePicker";
import * as AppActions from "../../../reducers/appReducer";

// TODO: For MVP, just make the calls, but in the future we probably want a filteredActivities and when the category changes, 
// update that rather than making another network call
const listHelpText = Translator.getHelpText("en", "activitiesList");

interface IActivitiesListScreenProps {
  appActions: any;
  history: any;
}

interface IActivitiesListScreenState {
  loading: boolean;
  showCreateSingleModal: boolean;
  showCreateBulkModal: boolean;
  activities: any;

  activityCategories: string[];
  selectedCategory: string;
  start: moment.Moment;
  end: moment.Moment;
}

class ActivitiesListScreen extends React.Component<IActivitiesListScreenProps, IActivitiesListScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      activities: [],

      showCreateSingleModal: false,
      showCreateBulkModal: false,
      activityCategories: [],
      selectedCategory: "",
      start: moment().subtract(1, "month"),
      end: moment().add(1, "day"),
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.fetchActivityCategories = this.fetchActivityCategories.bind(this);
    this.toggleCreateSingleActivityModal = this.toggleCreateSingleActivityModal.bind(this);
    this.toggleCreateBulkActivityModal = this.toggleCreateBulkActivityModal.bind(this);
    this.switchToBulk = this.switchToBulk.bind(this);
    this.switchToSingle = this.switchToSingle.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCreateActivity = this.handleCreateActivity.bind(this);
    this.handleCreateBulk = this.handleCreateBulk.bind(this);
  }

  public componentDidMount(){
    this.fetchActivityCategories();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <ActivityStats
              categories={this.state.activityCategories}
              activities={this.state.activities} />
          </div>
          <div className="col-lg-9 col-sm-12">
            <Card title="Activities" loading={this.state.loading} help={listHelpText}>
              
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <label>From</label>
                  <DatePicker 
                    date={this.state.start}
                    onDateSaved={this.updateStart}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>To</label>
                  <DatePicker 
                    date={this.state.end}
                    onDateSaved={this.updateEnd}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Category</label>
                  <select className="form-control" id="selectedCategory" value={this.state.selectedCategory} onChange={this.updateField}>
                    <option value="all">Show: All</option>
                    {this.state.activityCategories.map((cat: any) => {
                      return ( <option key={cat} value={cat}>Show: {ActivitiesAPI.translateCategory(cat)}</option>)
                    })}
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <button className="btn btn-primary btn-block" onClick={this.toggleCreateSingleActivityModal} style={{marginTop: 32}}>New</button>
                </div>
              </div>

              <hr />
              {this.state.activities.length === 0 && (<strong>No activities match that criteria</strong>)}
              {this.state.activities.map((activity: IActivity) => {
                return (<ActivityListItem
                  key={activity.id}
                  activity={activity}
                  categories={this.state.activityCategories}
                  onDelete={this.handleDelete}
                  onEdit={this.handleEdit} />);
              })}
            </Card>
          </div>
        </div>

        <SingleActivityForm 
          activityCategories={this.state.activityCategories}
          show={this.state.showCreateSingleModal}
          onToggleForm={this.toggleCreateSingleActivityModal}
          switchToBulk={this.switchToBulk}
          onSaveActivity={this.handleCreateActivity}
          mode="create"
        />

        <BulkActivityForm
          activityCategories={this.state.activityCategories}
          show={this.state.showCreateBulkModal}
          onToggleForm={this.toggleCreateBulkActivityModal}
          switchToSingle={this.switchToSingle}
          onCreate={this.handleCreateBulk}
        />
        
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "selectedCategory"){
        this.fetchActivities();
      }
    });
  }

  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate}, () => {
      this.fetchActivities();
    });
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate}, () => {
      this.fetchActivities();
    });
  }

  private fetchActivityCategories(){
    this.setState({loading: true}, async () => {
      try{
        const res = await ActivitiesAPI.getActivityCategories();
        const categories = res.body.data;
        this.setState({
          activityCategories: categories,
        }, () => {
          this.fetchActivities();
        });
      }catch(e){
        // ?????
        this.setState({loading: false});
      }
    });
  }

  private async fetchActivities(){
    this.setState({loading: true}, async () => {
      try{
        const category = this.state.selectedCategory === "all" ? "" : this.state.selectedCategory;
        const res = await ActivitiesAPI.getActivities(category, this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        const activities: IActivity[] = [];
        for(const a of res.body.data){
          a.followUpOn = moment(a.followUpOn);
          a.lastFollowedUpOn = moment(a.lastFollowedUpOn);
          activities.push(a);
        }
        this.setState({
          loading: false,
          activities: activities
        });
      }catch(e){
        // ?????
        this.setState({loading: false});
      }
    });
  }

  private toggleCreateSingleActivityModal(){
    this.setState({ showCreateSingleModal: !this.state.showCreateSingleModal});
  }

  private toggleCreateBulkActivityModal(){
    this.setState({ showCreateBulkModal: !this.state.showCreateBulkModal});
  }

  private switchToBulk(){
    this.setState({ showCreateBulkModal: true, showCreateSingleModal: false});
  }

  private switchToSingle(){
    this.setState({ showCreateBulkModal: false, showCreateSingleModal: true});
  }

  private handleDelete(activity: IActivity){
    const activities = [];
    for(const act of this.state.activities){
      if(act.id !== activity.id){
        activities.push(act);
      }
    }
    this.setState({ activities });
  }

  private handleEdit(activity: IActivity){
    const activities = [];
    for(const act of this.state.activities){
      if(act.id === activity.id){
        activities.push(activity);
      } else {
        activities.push(act);
      }
    }
    this.setState({ activities });
  }

  private handleCreateActivity(activity: IActivity){
    this.setState({showCreateSingleModal: false}, () => this.fetchActivities());
  }

  private handleCreateBulk(activities: IActivity){
    // we need to fetch the quota, since some activities could be reported in batches due to errors
    this.setState({showCreateBulkModal: false}, () => this.fetchActivities());
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivitiesListScreen) as any);