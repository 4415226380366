import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "../../structure/Card";
import Screen from "src/components/structure/Screen";
import { success, error } from "../../structure/Alert";
import { ContactsAPI } from "../../../api";
import * as ContactActions from "../../../reducers/contactsReducer";

import ContactNotes from "./ContactNotes";

interface IContactDetailsInfoAndNotesProps {
  contactActions: any;
  contactState: any;
  history: any;
  match: any;
}

interface IContactDetailsInfoAndNotesState {
  loading: boolean;
  contactTitle: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactCell: string;
  contactType: "general" | "direct_report" | "manager";
  contactCreatedOn: moment.Moment;
  groups: any[];
  availableGroups: any[];
}

class ContactDetailsInfoAndNotes extends React.Component<IContactDetailsInfoAndNotesProps, IContactDetailsInfoAndNotesState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      contactTitle: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactCell: "",
      contactType: "general",
      contactCreatedOn: moment(),
      groups: [],
      availableGroups: [],
    };

    this.updateField = this.updateField.bind(this);
    this.fetchContact = this.fetchContact.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  componentDidMount() {
    this.fetchContact();
  }

  public render() {
    return (
      <Screen fileName="src/components/screens/Contacts/ContactDetailsInfoAndNotes.tsx">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <Card title="Info" loading={this.state.loading} help="">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control" id="contactTitle" value={this.state.contactTitle} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input type="text" className="form-control" id="contactFirstName" value={this.state.contactFirstName} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input type="text" className="form-control" id="contactLastName" value={this.state.contactLastName} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input type="text" className="form-control" id="contactEmail" value={this.state.contactEmail} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Cell Phone Number</label>
                <input type="text" className="form-control" id="contactCell" value={this.state.contactCell} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Contact Type</label>
                <select className="form-control" id="contactType" value={this.state.contactType} onChange={this.updateField}>
                  <option value="general">General</option>
                  <option value="direct_report">Direct Report</option>
                  <option value="manager">My Manager</option>
                </select>
              </div>
              <div className="form-group">
                <label>Groups</label>
                <Typeahead
                  id="contact-search"
                  key={this.state.availableGroups.length}
                  multiple={true}
                  placeholder="Select Groups"
                  onChange={this.handleSelectGroup}
                  selected={this.state.groups}
                  labelKey="groupName"
                  options={this.state.availableGroups} />
              </div>

              <button className="btn btn-block btn-primary" onClick={this.updateContact}>Save</button>
            </Card>
          </div>
          <div className="col-lg-8 col-sm-12">
            <ContactNotes contactId={this.props.match.params.id} />
          </div>
        </div>
      </Screen>
    );
  }


  private updateField(e: any) {
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private handleSelectGroup(selectedGroups: any){
    this.setState({
      groups: selectedGroups
    });
  }

  private fetchContact() {
    this.setState({ loading: true }, async () => {
      try {
        // grab all the groups, then the contact, then the contact groups
        const availableGroups: any[] = [];
        const groups: any[] = [];

        const availableGroupsResult = await ContactsAPI.getContactGroups();
        for(const ag of availableGroupsResult.body.data){
          ag.createdOn = moment(ag.createdOn);
          availableGroups.push(ag);
        }

        const contactGroupsResult = await ContactsAPI.getGroupsForContact(this.props.match.params.id);
        for(const g of contactGroupsResult.body.data){
          g.createdOn = moment(g.createdOn);
          groups.push(g);
        }
        
        const contactResult = await ContactsAPI.getContact(this.props.match.params.id);
        this.setState({
          loading: false,
          contactTitle: contactResult.body.data.title,
          contactFirstName: contactResult.body.data.firstName,
          contactLastName: contactResult.body.data.lastName,
          contactEmail: contactResult.body.data.email,
          contactCell: contactResult.body.data.cell,
          contactType: contactResult.body.data.contactType,
          contactCreatedOn: moment(contactResult.body.data.createdOn),
          availableGroups,
          groups
        });
      } catch (err) {
        // show an error and redirect
        error("Could not find that contact");
        this.props.history.push("/contacts");
      }
    });
  }

  private updateContact() {
    const contactId = this.props.match.params.id;
    const data = {
      title: this.state.contactTitle,
      firstName: this.state.contactFirstName,
      lastName: this.state.contactLastName,
      email: this.state.contactEmail,
      contactType: this.state.contactType,
      cell: this.state.contactCell,
    };
    if (data.firstName === "" || data.lastName === "") {
      return error("First Name and Last Name are required");
    }
    this.setState({ loading: true }, async () => {
      try {
        const contactResult = await ContactsAPI.updateContact(contactId, data);
        // update the groups
        await ContactsAPI.removeAllGroupsFromContact(contactId);
        for(const g of this.state.groups){
          await ContactsAPI.addContactToGroup(g.id, contactId);
        }
        // update the reducer
        const allContacts: any = [];
        for (const c of this.props.contactState.contacts) {
          if (c.id !== contactResult.body.data.id) {
            allContacts.push(c);
          } else {
            allContacts.push(contactResult.body.data);
          }
        }
        success("Saved!");
        this.setState({ loading: false }, () => {
          this.props.contactActions.setContacts(allContacts);
        });
      } catch (err) {
        this.setState({ loading: false });
        return error("Could not update that contact");
      }
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    contactState: s.contactsState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    contactActions: bindActionCreators(ContactActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactDetailsInfoAndNotes) as any);