import moment from "moment";
import { makeCall } from "./client";

export interface IMilestone {
  id: number;
  userId: number;
  milestoneType: "user" | "contact";
  contactId: number;
  dateOccurred: moment.Moment;
  title: string;
  notes: string;
  milestoneRepeat: "single" | "repeats";
  nextDue: moment.Moment;

  firstName: string;
  lastName: string;
}

export const BlankMilestone: IMilestone = {
  id: 0,
  userId: 0,
  milestoneType: "user", 
  contactId: 0,
  dateOccurred: moment(),
  title: "",
  notes: "",
  milestoneRepeat: "single",
  nextDue: moment(),
  firstName: "",
  lastName: "",
};

export class MilestoneAPI {

  public getMilestones(otherData: any = {}): Promise<any> {
    return makeCall("get", `milestones`, otherData);
  }
  
  public createMilestone(milestone: any, otherData: any = {}): Promise<any> {
    const data = {
      ...milestone,
      ...otherData
    };
    return makeCall("post", `milestones`, data);
  }
  
  public updateMilestone(milestoneId: number, milestone: any, otherData: any = {}): Promise<any> {
    const data = {
      ...milestone,
      ...otherData
    };
    return makeCall("patch", `milestones/${milestoneId}`, data);
  }
  
  public deleteMilestone(milestoneId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData
    };
    return makeCall("delete", `milestones/${milestoneId}`, data);
  }

  public getMilestone(milestoneId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `milestones/${milestoneId}`, otherData);
  }

  public getMilestonesForContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/${contactId}/milestones`, otherData);
  }
}