import moment from "moment";
import { makeCall } from "./client";

export interface IContactGroup {
  id: number;
  createdBy: number;
  createdOn: moment.Moment;
  groupDescription: string;
  groupName: string;
  label: string;
  parentGroup: number;
  contacts: any[];
}

export interface IContact {
  groupID: number;
  cell: string;
  cellStatus: "pending" | "verified" | "invalid" | "blocked";
  contactType: "general" | "direct_report" | "manager";
  createdBy: number;
  createdOn: string;
  email: string;
  emailStatus: "pending" | "verified" | "invalid" | "blocked";
  firstName: string;
  id: number;
  label: string;
  lastName: string;
  title: string;
}

export const BlankContact: IContact = {
  groupID: 0,
  cell: "",
  cellStatus: "pending",
  contactType: "general",
  createdBy: 0,
  createdOn: "",
  email: "",
  emailStatus: "pending",
  firstName: "",
  id: 0,
  label: "",
  lastName: "",
  title: "",
}

export class ContactsAPI {

  /**
   * Gets all of the contact groups for a user
   * @param otherData 
   */
  public getContactGroups(otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/groups`, otherData);
  }

  /**
   * Create a new group
   * @param otherData 
   */
  public createContactGroup(otherData: any = {}): Promise<any> {
    return makeCall("post", `contacts/groups`, otherData);
  }

  /**
   * Update an existing group
   * @param groupId 
   * @param otherData 
   */
  public updateContactGroup(groupId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `contacts/groups/${groupId}`, otherData);
  }

  /**
   * Delete a group and all connections
   * @param groupId 
   * @param otherData 
   */
  public deleteContactGroup(groupId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `contacts/groups/${groupId}`, otherData);
  }
  
  /**
   * Get the contacts in a group
   * @param groupId 
   * @param otherData 
   */
  public getContactsInGroup(groupId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/groups/${groupId}/contacts`, otherData);
  }
  
  /**
   * Get all contacts for a user
   * @param otherData 
   */
  public getAllContactsForUser(otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts`, otherData);
  }

  /**
   * Create a contact
   * @param otherData 
   */
  public createContact(otherData: any = {}): Promise<any> {
    return makeCall("post", `contacts`, otherData);
  }

  /**
   * Update a contact
   * @param contactId 
   * @param otherData 
   */
  public updateContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `contacts/${contactId}`, otherData);
  }

  /**
   * Delete a contact
   * @param contactId 
   * @param otherData 
   */
  public deleteContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `contacts/${contactId}`, otherData);
  }

  /**
   * Add a contact to a group
   * @param groupId 
   * @param contactId 
   * @param otherData 
   */
  public addContactToGroup(groupId: number, contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("put", `/contacts/groups/${groupId}/contacts/${contactId}`, otherData);
  }

  /**
   * Remove a contact from a group
   * @param groupId 
   * @param contactId 
   * @param otherData 
   */
  public removeContactFromGroup(groupId: number, contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `/contacts/groups/${groupId}/contacts/${contactId}`, otherData);
  }

  /**
   * Removes all of the groups from a contact
   * @param contactId 
   * @param otherData 
   * @returns 
   */
  public removeAllGroupsFromContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `/contacts/${contactId}/groups`, otherData);
  }

  /**
   * Get the groups for a contact
   * @param contactId 
   * @param otherData 
   * @returns 
   */
  public getGroupsForContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/${contactId}/groups`, otherData);
  }

  /**
   * Gets the data for a contact
   * @param contactId 
   * @param otherData 
   */
  public getContact(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/${contactId}`, otherData);
  }

  /**
   * Get the notes for a contact
   * @param contactId 
   * @param otherData 
   */
  public getContactNotes(contactId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `contacts/${contactId}/notes`, otherData);
  }

  /**
   * Create a new note on a contact
   * @param contactId 
   * @param data 
   */
  public createContactNote(contactId: number, data: any = {}): Promise<any> {
    return makeCall("post", `contacts/${contactId}/notes`, data);
  }

  /**
   * Delete a note from a contact's record
   * @param contactId 
   * @param noteId 
   * @param data 
   */
  public deleteContactNote(contactId: number, noteId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `contacts/${contactId}/notes/${noteId}`, data);
  }

  /**
   * Update a note for a contact
   * @param contactId 
   * @param noteId 
   * @param data 
   */
  public updateContactNote(contactId: number, noteId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `contacts/${contactId}/notes/${noteId}`, data);
  }

}