import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { QuotaAPI, UserAPI } from "src/api";

const messageBaseURL = "https://message.voithosapp.com/";
const profilePageBaseURL = "https://me.voithosapp.com/providers/";

interface IBrandingProps {
  appActions: any;
}

interface IBrandingState {
  loading: boolean;
  isSubscribed: boolean;
  displayName: "",
}

class Branding extends React.Component<IBrandingProps, IBrandingState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      isSubscribed: false,
      displayName: "",
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Branding.tsx">
        <div className="row">
            <div className="col-lg-4">
                <Card title="Generate Branding" loading={this.state.loading} help="">
                    As a member of Voithos, you can optionally allow contacts through a secured web form for outreach. 
                    {this.state.isSubscribed ? <span>Since you are a Pro subscriber, you also get a free landing page as well as the contact form.</span> : <span>As a free user, you do not get access to the free landing page. <Link to="/subscription">Subscribe now!</Link></span>}
                </Card>
            </div>
            <div className="col-lg-8">
                <Card title="Message Link Branding" loading={this.state.loading} help="">
                    <input type="text" className="form-control" readOnly={true} value={`<a href="${messageBaseURL}${this.state.displayName}">Message me on Voithos!</a>`} />
                    <a href={`${messageBaseURL}${this.state.displayName}`}>Message me on Voithos!</a>
                </Card>
                <Card title="Message Banner Branding" loading={this.state.loading} help="">
                    <input type="text" className="form-control" readOnly={true} value={`<a href="${messageBaseURL}${this.state.displayName}"><img src="https://cdn.voithosapp.com/images/contact_transparent.png" style="width: '100%'" /></a>`} />
                    <a href={`${messageBaseURL}${this.state.displayName}`}><img src="https://cdn.voithosapp.com/images/contact_transparent.png" style={{width: "100%"}} /></a>
                </Card>
                <Card title="Message Icon Branding" loading={this.state.loading} help="">
                    <input type="text" className="form-control" readOnly={true} value={`<a href="${messageBaseURL}${this.state.displayName}"><img src="https://cdn.voithosapp.com/images/icon_transparent.png" style="width: '100%'" /></a>`} />
                    <a href={`${messageBaseURL}${this.state.displayName}`}><img src="https://cdn.voithosapp.com/images/icon_transparent.png" style={{width: "128px"}} /></a>
                </Card>
                {this.state.isSubscribed && (
                    <div>
                        <Card title="Landing Link Branding" loading={this.state.loading} help="">
                            <input type="text" className="form-control" readOnly={true} value={`<a href="${profilePageBaseURL}${this.state.displayName}">Connect on Voithos!</a>`} />
                            <a href={`${profilePageBaseURL}${this.state.displayName}`}>Connect on Voithos!</a>
                        </Card>
                        <Card title="Landing Banner Branding" loading={this.state.loading} help="">
                            <input type="text" className="form-control" readOnly={true} value={`<a href="${profilePageBaseURL}${this.state.displayName}"><img src="https://cdn.voithosapp.com/images/connect_transparent.png" style="width: '100%'" /></a>`} />
                            <a href={`${profilePageBaseURL}${this.state.displayName}`}><img src="https://cdn.voithosapp.com/images/connect_transparent.png" style={{width: "100%"}} /></a>
                        </Card>
                        <Card title="Landing Icon Branding" loading={this.state.loading} help="">
                            <input type="text" className="form-control" readOnly={true} value={`<a href="${profilePageBaseURL}${this.state.displayName}"><img src="https://cdn.voithosapp.com/images/icon_transparent.png" style="width: '100%'" /></a>`} />
                            <a href={`${profilePageBaseURL}${this.state.displayName}`}><img src="https://cdn.voithosapp.com/images/icon_transparent.png" style={{width: "128px"}} /></a>
                        </Card>
                    </div>
                )}
            </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        const quotaResult = await QuotaAPI.getUserQuota();
        const isSubscribed = quotaResult.body.data.isSubscribed;
        const userResult = await UserAPI.getUserProfile();
        const display = userResult.body.data.displayName === "" ? userResult.body.data.id : userResult.body.data.displayName;
        this.setState({ loading: false, isSubscribed, displayName: display });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Branding) as any);