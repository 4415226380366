/**
 * So, this file became really large after I added a bunch of features. We will want to componentize this a bit better to make it more manageable
 * 
 * Also, since we moved to use a reducer for the search, we can probably reduce a lot of the api calls and filters to look at that list
 * 
 * We still use the API calls for the contacts, since we don't store it by group in the reducer right now
 */
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";

import { Translator } from "../../../utils/translator";
import { ContactsAPI } from "../../../api";
import * as ContactActions from "../../../reducers/contactsReducer";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import ContactListItem from "./ContactListItem";
import ContactSearch from "src/components/structure/ContactSearch";
import { IContact } from "src/api/contacts";
import Screen from "src/components/structure/Screen";

const groupsHelpText = Translator.getHelpText("en", "contactGroups");
const searchContactsHelpText = Translator.getHelpText("en", "searchContacts");
const allContactsHelpText = Translator.getHelpText("en", "contactsList");

interface IContactsScreenProps {
  userState: any;
  history: any;
  contactActions: any;
  contactState: any;
}

interface IContactsScreenState {
  loading: boolean;
  contactsInGroup: any;
  selectedGroupId: number;
  groupName: string;
  groupDescription: string;

  contactTitle: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactCell: string;
  contactType: "general" | "direct_report" | "manager";
  contactDefaultGroup: number;

  showCreateGroupModal: boolean;
  showCreateContactModal: boolean;
  showEditGroupModal: boolean;
  showDeleteGroupModal: boolean;
}

class ContactsScreen extends React.Component<IContactsScreenProps, IContactsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      contactsInGroup: [],
      selectedGroupId: 0,
      groupName: "",
      groupDescription: "",

      contactTitle: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactCell: "",
      contactType: "general",
      contactDefaultGroup: 0,

      showCreateGroupModal: false,
      showCreateContactModal: false,
      showEditGroupModal: false,
      showDeleteGroupModal: false,
    };

    this.init = this.init.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.selectAllContactsGroup = this.selectAllContactsGroup.bind(this);
    this.updateField = this.updateField.bind(this);

    this.createGroup = this.createGroup.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);

    this.createContact = this.createContact.bind(this);
    this.editContact = this.editContact.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
    this.selectContact = this.selectContact.bind(this);
    this.selectContactFromSearch = this.selectContactFromSearch.bind(this);

    // toggles
    this.toggleCreateGroupModal = this.toggleCreateGroupModal.bind(this);
    this.toggleCreateContactModal = this.toggleCreateContactModal.bind(this);
    this.toggleEditGroupModal = this.toggleEditGroupModal.bind(this);
    this.toggleDeleteGroupModal = this.toggleDeleteGroupModal.bind(this);
  }

  public componentDidMount() {
    this.init();
  }

  public render() {
    return (
      <Screen fileName="screens/Contacts/ContactsScreen.tsx">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <div className="row">
              <div className="col-12">
                <Card title="Groups" loading={this.state.loading} help={groupsHelpText}>
                  <div className="form-group">
                    <select id="selected-group" className="form-control" value={this.state.selectedGroupId} onChange={this.selectGroup}>
                      <option value={0}>All Contacts</option>
                      {this.props.contactState.contactGroups.map((group: any) => {
                        return <option key={group.id} value={group.id}>{group.groupName}</option>
                      })}
                    </select> 
                  </div>
                  {this.state.selectedGroupId !== 0 && (
                    <div>
                      <div className="form-group">
                        <p>{this.state.groupDescription}</p>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-block btn-primary" onClick={this.toggleEditGroupModal}>Update Group</button>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-block btn-danger" onClick={this.toggleDeleteGroupModal}>Delete Group</button>
                      </div>
                    </div>
                  )}
                  <button className="btn btn-block btn-primary" onClick={this.toggleCreateGroupModal}>Create Group</button>
                </Card>
              </div>
            </div>
            <div className="row" style={{marginTop: 20}}>
              <div className="col-12">
                <Card title="Search Contacts" loading={this.state.loading} help={searchContactsHelpText}>
                  <div className="form-group">
                    <ContactSearch onSelect={this.selectContactFromSearch} multiple={false} selectedContacts={[]} />
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12">
            <Card title={this.state.selectedGroupId !== 0 ? `${this.state.groupName} Contacts` : `All Contacts`} loading={this.state.loading} help={allContactsHelpText}>
              {this.state.contactsInGroup.length === 0 && (<strong>No contacts in this group</strong>)}
              {this.state.contactsInGroup.length !== 0 && this.state.contactsInGroup.map((contact: any) => {
                return (
                  <ContactListItem
                    key={contact.id}
                    contact={contact}
                    onEditContact={this.editContact}
                    onDeleteContact={this.deleteContact}
                    onSelectContact={this.selectContact}
                  />
                );
              })}
              <button className="btn btn-block btn-primary" onClick={this.toggleCreateContactModal}>Create Contact</button>
            </Card>
          </div>
        </div>

        <Modal show={this.state.showCreateGroupModal} onHide={this.toggleCreateGroupModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Group Name</label>
              <input type="text" className="form-control" id="groupName" value={this.state.groupName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Group Description</label>
              <input type="text" className="form-control" id="groupDescription" value={this.state.groupDescription} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createGroup}>Submit</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateGroupModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showCreateContactModal} onHide={this.toggleCreateContactModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" id="contactTitle" value={this.state.contactTitle} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" className="form-control" id="contactFirstName" value={this.state.contactFirstName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" className="form-control" id="contactLastName" value={this.state.contactLastName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input type="text" className="form-control" id="contactEmail" value={this.state.contactEmail} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Cell Phone Number</label>
              <input type="text" className="form-control" id="contactCell" value={this.state.contactCell} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Contact Type</label>
              <select className="form-control" id="contactType" value={this.state.contactType} onChange={this.updateField}>
                <option value="general">General</option>
                <option value="direct_report">Direct Report</option>
                <option value="manager">My Manager</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createContact}>Create</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateContactModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditGroupModal} onHide={this.toggleEditGroupModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Group Name</label>
              <input type="text" className="form-control" id="groupName" value={this.state.groupName} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>Group Description</label>
              <input type="text" className="form-control" id="groupDescription" value={this.state.groupDescription} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.saveGroup}>Update Group</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditGroupModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteGroupModal} onHide={this.toggleDeleteGroupModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{`Delete ${this.state.groupName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong>You are about to delete the group {this.state.groupName}. This cannot be undone. This will NOT delete any of the contacts in the group.
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteGroup}>Delete Group</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteGroupModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any) {
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private init() {
    this.setState({ loading: true }, async () => {
      try {
        const groupsResult = await ContactsAPI.getContactGroups();
        const contactsResult = await ContactsAPI.getAllContactsForUser();

        // set in the reducers
        this.props.contactActions.setContactGroups(groupsResult.body.data);
        this.props.contactActions.setContacts(contactsResult.body.data);
        this.setState({loading: false, 
          contactsInGroup: contactsResult.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private createGroup() {
    const name = this.state.groupName;
    const description = this.state.groupDescription;
    if (name === "" || description === "") {
      return error("Name and description are both required");
    }
    this.setState({ loading: true }, async () => {
      try {
        const res = await ContactsAPI.createContactGroup({ groupName: name, groupDescription: description });
        this.props.contactActions.addContactGroup(res.body.data);
        // select the group
        const selectedGroupId = res.body.data.id;
        this.setState({ loading: false, showCreateGroupModal: false, selectedGroupId, contactsInGroup: []});
      } catch (err) {
        error("Could not create that group");
        this.setState({ loading: false });
      }
    });
  }

  private createContact() {
    const data = {
      title: this.state.contactTitle,
      firstName: this.state.contactFirstName,
      lastName: this.state.contactLastName,
      email: this.state.contactEmail,
      cell: this.state.contactCell,
      contactType: this.state.contactType,
    };
    if (data.firstName === "" || data.lastName === "") {
      return error("First Name and Last Name are required");
    }
    this.setState({ loading: true }, async () => {
      try {
        // create the contact
        const result = await ContactsAPI.createContact(data);
        const createdContact = result.body.data;

        // if a specific group is selected, add them to the group
        const currentContacts = this.state.contactsInGroup;
        if (this.state.selectedGroupId > 0) {
          await ContactsAPI.addContactToGroup(this.state.selectedGroupId, createdContact.id);
          currentContacts.push(createdContact);
          currentContacts.sort((a: any, b: any) => {
            return a.lastName > b.lastName ? 1 : -1;
          });
        }


        const allContacts = this.props.contactState.contacts;
        allContacts.push(createdContact);

        this.setState({ 
          loading: false, 
          contactsInGroup: currentContacts, 
          showCreateContactModal: false, 
          // reset the form
          contactTitle: "",
          contactFirstName: "",
          contactLastName: "",
          contactEmail: "",
          contactCell: "",
          contactType: "general",
          contactDefaultGroup: 0,
        }, () => {this.props.contactActions.setContacts(allContacts)});
      } catch (err) {
        error("Could not create that contact");
        this.setState({ loading: false, showCreateContactModal: false });
      }
    });
  }

  private selectAllContactsGroup(){
    this.setState({ loading: true }, async () => {
      try {
        const res = await ContactsAPI.getAllContactsForUser();
        this.setState({ loading: false, selectedGroupId: 0, contactsInGroup: res.body.data, groupName: "", groupDescription: ""});
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private selectGroup(e: any) {
    let groupId = e.target.value;
    if(typeof groupId === "string"){
      groupId = parseInt(groupId, 10);
    }
    // we need to get the contacts in the new group
    if(groupId === 0){
      return this.selectAllContactsGroup();
    }
    let group = {
      id: 0,
      groupName: "",
      groupDescription: "",
    };
    for(const g of this.props.contactState.contactGroups){
      if(g.id === groupId){
        group = g;
        break;
      }
    }
    if(group.id === 0){
      return;
    }
    this.setState({ loading: true, selectedGroupId: groupId }, async () => {
      try {
        const res = await ContactsAPI.getContactsInGroup(groupId);
        this.setState({ loading: false, selectedGroupId: groupId, contactsInGroup: res.body.data, groupDescription: group.groupDescription, groupName: group.groupName });
      } catch (err) {
        this.setState({ loading: false, contactsInGroup: [], selectedGroupId: group.id, groupDescription: group.groupDescription, groupName: group.groupName });
      }
    });
  }

  private deleteGroup() {
    this.setState({loading: true}, async () => {
      try{
        await ContactsAPI.deleteContactGroup(this.state.selectedGroupId);
        this.props.contactActions.removeContactGroup(this.state.selectedGroupId);
        this.setState({loading: false, showDeleteGroupModal: false}, () => {
          
          success("Group deleted");
          this.selectAllContactsGroup();
        });
      }catch(err){
        this.setState({loading: false, showDeleteGroupModal: false});
        error("Could not delete that group");
      }
    });
  }

  private saveGroup(){
    if(this.state.groupName === "" || this.state.groupDescription === ""){
      return error("Name and Description are required");
    }
    this.setState({ loading: true}, async () => {
      try{
        const data = {
          groupName: this.state.groupName,
          groupDescription: this.state.groupDescription
        };
        const result = await ContactsAPI.updateContactGroup(this.state.selectedGroupId, data);
        this.props.contactActions.updateContactGroup(result.body.data);
        this.setState({loading: false, showEditGroupModal: false});
        success("Group updated");
      }catch(err){
        this.setState({loading: false, showEditGroupModal: false});
        return error("Could not update that group");
      }
    });
  }

  private toggleCreateGroupModal() {
    this.setState({
      showCreateGroupModal: !this.state.showCreateGroupModal
    });
  }

  private toggleCreateContactModal() {
    this.setState({
      showCreateContactModal: !this.state.showCreateContactModal
    });
  }

  private toggleEditGroupModal() {
    this.setState({
      showEditGroupModal: !this.state.showEditGroupModal
    });
  }

  private toggleDeleteGroupModal() {
    this.setState({
      showDeleteGroupModal: !this.state.showDeleteGroupModal
    });
  }

  private editContact(contact: any) {
    const contacts = [];
    const allContacts: any = [];
    for (const c of this.state.contactsInGroup) {
      if (c.id !== contact.id) {
        contacts.push(c);
      } else {
        contacts.push(contact);
      }
    }
    for (const c of this.props.contactState.contacts) {
      if (c.id !== contact.id) {
        allContacts.push(c);
      } else {
        allContacts.push(contact);
      }
    }
    this.setState({ contactsInGroup: contacts }, () => {
      this.props.contactActions.setContacts(allContacts);
    });
  }

  private deleteContact(contact: any) {
    // remove it from the state, reduce the quota
    const contacts = [];
    for(const c of this.state.contactsInGroup){
      if(c.id !== contact.id){
        contacts.push(c)
      }
    }
    const allContacts: any = [];
    for(const c of this.props.contactState.contacts){
      if(c.id !== contact.id){
        allContacts.push(c);
      }
    }
    this.setState({
      contactsInGroup: contacts
    }, () => {
      this.props.contactActions.setContacts(allContacts);
    });
  }

  private selectContact(contact: IContact){
    this.props.history.push(`/contacts/${contact.id}`);
  }

  private selectContactFromSearch(contact: IContact){
    this.props.history.push(`/contacts/${contact.id}`);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
    contactState: s.contactsState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    contactActions: bindActionCreators(ContactActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactsScreen) as any);