import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContactsAPI, MilestonesAPI } from "src/api";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { IMilestone } from "src/api/milestones";
import moment from "moment";
import MilestoneList from "../Milestones/MilestoneList";
import { BlankContact, IContact } from "src/api/contacts";
import { Translator } from "src/utils/translator";

interface IContactDetailsMilestonesListProps {
  appActions: any;
  contactId: number;
}

interface IContactDetailsMilestonesListState {
  loading: boolean;
  contact: IContact;
  milestones: IMilestone[];
}

const milestonesHelpText = Translator.getHelpText("en", "contactMilestones", "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.");

class ContactDetailsMilestonesList extends React.Component<IContactDetailsMilestonesListProps, IContactDetailsMilestonesListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      contact: BlankContact,
      milestones: [],
    };

    this.fetch = this.fetch.bind(this);
    this.onMilestoneCreated = this.onMilestoneCreated.bind(this);
    this.onMilestoneDeleted = this.onMilestoneDeleted.bind(this);
    this.onMilestoneUpdated = this.onMilestoneUpdated.bind(this);
  }

  componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <Screen fileName="Contacts/ContactDetailsMilestonesList.tsx">
        <Card title="Milestones" loading={this.state.loading} help={milestonesHelpText}>
          <MilestoneList 
            milestones={this.state.milestones}
            onMilestoneCreated={this.onMilestoneCreated}
            onMilestoneDeleted={this.onMilestoneDeleted}
            onMilestoneUpdated={this.onMilestoneUpdated}
            contactId={this.props.contactId}
            firstName={this.state.contact.firstName}
            lastName={this.state.contact.lastName}
          />
        </Card>
      </Screen>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const contactResult = await ContactsAPI.getContact(this.props.contactId)
        const contact = contactResult.body.data;
        const milestonesResult = await MilestonesAPI.getMilestonesForContact(this.props.contactId);
        const milestones = milestonesResult.body.data;
        this.setState({ loading: false, contact, milestones });
      }catch(err){
        // shouldn't be a big deal I guess?
        this.setState({ loading: false });
      }
    });
  }

  private onMilestoneCreated(milestone: IMilestone){
    const milestones: IMilestone[] = this.state.milestones;
    milestones.push(milestone);
    milestones.sort((a: IMilestone, b: IMilestone) => {
      const aD = moment(a.dateOccurred);
      const bD = moment(b.dateOccurred);
      return aD.isBefore(bD) ? 1 : -1;
    });
    this.setState({milestones});
  }

  private onMilestoneUpdated(milestone: IMilestone){
    const milestones: IMilestone[] = [];
    for(const m of this.state.milestones){
      if(m.id === milestone.id){
        milestones.push(milestone);
      } else {
        milestones.push(m);
      }
    }

    milestones.sort((a: IMilestone, b: IMilestone) => {
      const aD = moment(a.dateOccurred);
      const bD = moment(b.dateOccurred);
      return aD.isBefore(bD) ? 1 : -1;
    });
    this.setState({milestones});
  }

  private onMilestoneDeleted(milestone: IMilestone){
    const milestones: IMilestone[] = [];
    for(const m of this.state.milestones){
      if(m.id !== milestone.id){
        milestones.push(m);
      }
    }
    this.setState({milestones});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsMilestonesList);