import * as React from "react";

import Card from "../../structure/Card";
import { ActivitiesAPI } from "../../../api";
import { Translator } from "../../../utils/translator";

interface IActivityStatsProps {
  activities: any;
  categories: any;
}

interface IActivityStatsState {
  loading: boolean;
  data: any;
}

// TODO: memoize this for performance

const statsHelpText = Translator.getHelpText("en", "activityStats");

export default class ActivityStats extends React.Component<IActivityStatsProps, IActivityStatsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: {},
    };
    this.process();
  }

  public render() {
    const data = this.process();
    return (
      <Card title="Stats" loading={this.state.loading} help={statsHelpText}>
        {Object.keys(data).map((key: string) => {
          return (
            <div key={key}>{key}: {data[key]}</div>
          );
        })}
      </Card>
    );
  }

  private process(){
    const data: any = {};
    for(const c of this.props.categories){
      data[ActivitiesAPI.translateCategory(c)] = 0;
    }
    for(const a of this.props.activities){
      data[ActivitiesAPI.translateCategory(a.category)] += 1;
    }
    data.Total = this.props.activities.length;
    return data;
  }

}