import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { saveAs } from "file-saver";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import DatePicker from "src/components/structure/DatePicker";
import * as AppActions from "src/reducers/appReducer";
import { QuotaAPI, VerbatimsAPI } from "src/api";
import { BlankVerbatim, IVerbatim } from "src/api/verbatims";
import { Modal } from "react-bootstrap";
import { error } from "src/components/structure/Alert";
import { Translator } from "src/utils/translator";

interface IVerbatimsScreenProps {
  appActions: any;
  history: any;
}

interface IVerbatimsScreenState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  newVerbatimDateOccurred: moment.Moment;
  selectedStatus: "all" | "draft" | "completed" | "archived";
  verbatims: IVerbatim[];
  selectedVerbatim: IVerbatim;
  showNewVerbatimModal: boolean;
  showPrintModal: boolean;
  showDeleteModal: boolean;
  printOption: "pdf" | "word";
  canCreateAnother: boolean;
}

const helpText = Translator.getHelpText("en", "verbatims");
class VerbatimsScreen extends React.Component<IVerbatimsScreenProps, IVerbatimsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      start: moment().subtract(1, "years"),
      end: moment(),
      newVerbatimDateOccurred: moment(),
      selectedStatus: "all",
      verbatims: [],
      selectedVerbatim: BlankVerbatim,
      showNewVerbatimModal: false,
      showPrintModal: false,
      showDeleteModal: false,
      printOption: "word",
      canCreateAnother: false,
    };

    this.fetchVerbatims = this.fetchVerbatims.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.updateNewVerbatimDateOccurred = this.updateNewVerbatimDateOccurred.bind(this);
    this.toggleShowNewVerbatimModal = this.toggleShowNewVerbatimModal.bind(this);
    this.toggleShowDeleteVerbatimModal = this.toggleShowDeleteVerbatimModal.bind(this);
    this.toggleShowPrintVerbatimModal = this.toggleShowPrintVerbatimModal.bind(this);
    this.createVerbatim = this.createVerbatim.bind(this);
    this.deleteVerbatim = this.deleteVerbatim.bind(this);
    this.printVerbatim = this.printVerbatim.bind(this);
  }

  

  componentDidMount(){
    this.fetchVerbatims();
  }

  public render() {
    return (
      <Screen fileName="VerbatimsScreen.tsx">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <Card title="Verbatims" loading={this.state.loading} help={helpText}>
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <label>From</label>
                  <DatePicker 
                    date={this.state.start}
                    onDateSaved={this.updateStart}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>To</label>
                  <DatePicker 
                    date={this.state.end}
                    onDateSaved={this.updateEnd}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Category</label>
                  <select className="form-control" id="selectedCategory" value={this.state.selectedStatus} onChange={this.updateField}>
                    <option value="all">All</option>
                    <option value="draft">Draft</option>
                    <option value="completed">Completed</option>
                    <option value="archived">Archived</option>
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <button className="btn btn-primary btn-block" onClick={this.toggleShowNewVerbatimModal} style={{marginTop: 32, marginBottom: 12}}>New</button>
                </div>
              </div>

              {this.state.verbatims.length !== 0 && (
                <div className="row list-row-header">
                  <div className="col-lg-3">
                    Date Occurred
                  </div>
                  <div className="col-lg-4">
                    Patient
                  </div>
                  <div className="col-lg-3">
                    Status
                  </div>
                  <div className="col-lg-2">
                  </div>
                </div>
              )}
              
              {this.state.verbatims.length === 0 && (
                <div className="row">
                  <div className="col-12" style={{textAlign: "center", fontWeight: "bold"}}>
                    No Verbatims Match That Criteria
                  </div>
                </div>
              )}

              {this.state.verbatims.map((verbatim) => {
                return (
                  <div className="row list-row" key={verbatim.id}>
                    <div className="col-lg-3 col-sm-12">
                      {moment(verbatim.dateOccurred).format("MM/DD/YYYY")}
                    </div>
                    <div className="col-lg-4 col-sm-12">
                      {verbatim.display}
                    </div>
                    <div className="col-lg-2 col-sm-12">
                      {verbatim.status}
                    </div>
                    <div className="col-lg-1 col-sm-12">
                      <span className="oi oi-print icon icon-primary" title="Print" onClick={() => {
                        this.setState({ selectedVerbatim: verbatim}, () => {
                          this.toggleShowPrintVerbatimModal();
                        })
                      }} />
                    </div>
                    <div className="col-lg-1 col-sm-12">
                      <span className="oi oi-pencil icon icon-primary" title="Edit" onClick={() => {
                        this.props.history.push(`/verbatims/${verbatim.id}`);
                      }} />
                    </div>
                    <div className="col-md-1">
                      <span className="oi oi-x icon icon-danger" title="Delete" onClick={() => {
                        this.setState({ selectedVerbatim: verbatim}, () => {
                          this.toggleShowDeleteVerbatimModal();
                        })
                      }}/>
                    </div>
                  </div>
                );
              })}

              <Modal show={this.state.showNewVerbatimModal} onHide={this.toggleShowNewVerbatimModal} >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Create New Verbatim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.canCreateAnother ? (
                    <div>
                      <p>To create a new verbatim, we first need to know when the session occurred. Upon creating, you will be redirected to the edit screen for the verbatim.</p>
                      <div className="form-group">
                        <label htmlFor="newVerbatimDateOccurred">Date Occurred</label>
                        <DatePicker
                          date={this.state.newVerbatimDateOccurred}
                          onDateSaved={this.updateNewVerbatimDateOccurred}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      As a Free User, you can only create one verbatim. If you would like to create more, please upgrade your subscription.
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                {this.state.canCreateAnother ? (
                  <button className="btn btn-block btn-primary" onClick={this.createVerbatim}>Create New Verbatim</button>
                ) : (
                  <Link to="/subscription" className="btn btn-block btn-primary">Upgrade Now!</Link>
                )}
                  <button className="btn btn-block btn-info" onClick={this.toggleShowNewVerbatimModal}>Cancel</button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteVerbatimModal} >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Delete Verbatim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete the verbatim {this.state.selectedVerbatim.display === "None" ? "" : `with ${this.state.selectedVerbatim.display}`} that 
                  occurred on {moment(this.state.selectedVerbatim.dateOccurred).format("MM/DD/YYYY")}? This cannot be undone! </p>
                </Modal.Body>
                <Modal.Footer>
                  <div className="col-6">
                    <button className="btn btn-block btn-danger" onClick={this.deleteVerbatim}>Delete This Verbatim</button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-block btn-info" onClick={this.toggleShowDeleteVerbatimModal}>Cancel</button>
                  </div>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showPrintModal} onHide={this.toggleShowPrintVerbatimModal} >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Delete Verbatim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Please choose the format for the verbatim. Selecting Word will allow you to edit the verbatim, for example, if you need to submit it with a specific format. PDF is read-only and useful for archiving and distribution.</p>
                  <div className="form-group">
                    <label>Format</label>
                    <select className="form-control" id="printOption" value={this.state.printOption} onChange={this.updateField}>
                      <option value="word">MS Word (docx)</option>
                      <option value="pdf">PDF</option>
                    </select>
                  </div>
                </Modal.Body>
                <Modal.Footer>
              <div className="col-6">
                  <button className="btn btn-block btn-primary" onClick={this.printVerbatim}>Print This Verbatim</button>
              </div>
              <div className="col-6">
                  <button className="btn btn-block btn-info" onClick={this.toggleShowPrintVerbatimModal}>Cancel</button>
              </div>
                </Modal.Footer>
              </Modal>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  private async fetchVerbatims(){
    this.setState({ loading: true }, async () => {
      try{
        const verbatimsResult = await VerbatimsAPI.getVerbatims({
          start: this.state.start.format("YYYY-MM-DDTHH:mm:SSZ"),
          end: this.state.end.format("YYYY-MM-DDTHH:mm:SSZ"),
          status: this.state.selectedStatus,
        });
        const verbatims: IVerbatim[] = [];
        for(const v of verbatimsResult.body.data){
          v.createdOn = moment(v.createdOn);
          v.lastUpdated = moment(v.lastUpdated);
          v.dateOccurred = moment(v.dateOccurred);
          verbatims.push(v);
        }
        const quotaResult = await QuotaAPI.getUserQuota();
        const isSubscribed = quotaResult.body.data;
        const canCreateAnother = isSubscribed.canCreateAnotherVerbatim;
        this.setState({loading: false, verbatims, canCreateAnother});
      }catch(err){
        this.setState({loading: false});
      }
    })
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "selectedStatus"){
        this.fetchVerbatims();
      }
    });
  }

  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate}, () => {
      this.fetchVerbatims();
    });
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate}, () => {
      this.fetchVerbatims();
    });
  }

  private updateNewVerbatimDateOccurred(newDate: moment.Moment){
    this.setState({ newVerbatimDateOccurred: newDate});
  }

  private toggleShowNewVerbatimModal(){
    this.setState({ showNewVerbatimModal: !this.state.showNewVerbatimModal});
  }

  private toggleShowDeleteVerbatimModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private toggleShowPrintVerbatimModal(){
    this.setState({ showPrintModal: !this.state.showPrintModal});
  }

  private createVerbatim(){
    // we send up the basic info, get the ID, and redirect
    this.setState({ loading: true, showNewVerbatimModal: false }, async () => {
      try{
        const result = await VerbatimsAPI.createVerbatim({ dateOccurred: this.state.newVerbatimDateOccurred.format("YYYY-MM-DDTHH:mm:ssZ")})
        const id = result.body.data.id;
        this.props.history.push(`/verbatims/${id}`);
      }catch(err){
        this.setState({ loading: false, showNewVerbatimModal: true }, () => {
          error("Could not create that verbatim. Try again and if it still doesn't work, please contact support.");
        })
      }
    });
  }

  private deleteVerbatim(){
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.deleteVerbatim(this.state.selectedVerbatim.id);
        this.setState({ loading: false, showDeleteModal: false }, () => {
          this.fetchVerbatims();
        });
      }catch(err){
        this.setState({ loading: false, showDeleteModal: false }, () => {
          error("Could not delete that verbatim. Try again and if it still doesn't work, please contact support.");
        })
      }
    });
  }

  private printVerbatim(){
    this.setState({ loading: true }, async () => {
      try{
        const fileType = this.state.printOption === "word" ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf";
        const extension = this.state.printOption === "word" ? "docx" : "pdf";
        const fileName = `verbatim-${moment(this.state.selectedVerbatim.dateOccurred).format("YYYY-MM-DD")}.${extension}`;
        const options = { asDownload: true, accept: fileType};

        const result = await VerbatimsAPI.printVerbatim(this.state.selectedVerbatim.id, this.state.printOption, {}, options);
        const blob = new Blob([result.body], {type: fileType});
        saveAs(blob, fileName);
        this.setState({ loading: false, showPrintModal: false});
      }catch(err){
        error("Could not print that verbatim.");
        this.setState({ loading: false });
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerbatimsScreen) as any);