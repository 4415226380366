import * as React from "react";
import { Collapse } from "react-bootstrap";

import {IExportHistory} from "src/api/exports";
import { ExportsAPI } from "src/api/";

interface IExportsHistoryItemProps {
  history: IExportHistory;
}

interface IExportsHistoryItemState {
  loading: boolean;
  isExpanded: boolean;
}

export default class ExportsHistoryItem extends React.Component<IExportsHistoryItemProps, IExportsHistoryItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      isExpanded: false,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.displayTo = this.displayTo.bind(this);
  }

  public render() {
    return (
      <div style={{marginBottom: 5}}>
        <div className="row">
          <div className="col-lg-3 col-sm-12">{this.props.history.sentOn.format("MM/DD/YY")}</div>
          <div className="col-lg-7 col-sm-12">Sent {ExportsAPI.translateFrequency(this.props.history.frequency, false)} {this.props.history.exportType}</div>
          <div className="col-lg-2 col-sm-12" onClick={this.toggleExpand}>
            {this.state.isExpanded ? (<span className="oi oi-minus" />) : (<span className="oi oi-plus" />)}
          </div>
        </div>
        <Collapse in={this.state.isExpanded}>
          <div className="row">
            <div className="col-11 offset-1">
              {this.displayTo()}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  private toggleExpand(){
    this.setState({isExpanded: !this.state.isExpanded});
  }

  private displayTo(){
    return this.props.history.sentTo.split(",").join(", ");
  }
}