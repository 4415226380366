import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import ContactSearch from "src/components/structure/ContactSearch";
import * as AppActions from "src/reducers/appReducer";
import { IVerbatimPatient, BlankVerbatimPatient } from "src/api/verbatims";
import { error, success } from "src/components/structure/Alert";
import { VerbatimsAPI } from "src/api";
import { Translator } from "src/utils/translator";

interface IVerbatimPatientEditProps {
  appActions: any;
  verbatimId: number;
  patients: IVerbatimPatient[];
  loading: boolean;
  onDelete: any;
  onCreate: any;
  onUpdate: any;
}

interface IVerbatimPatientEditState {
  loading: boolean;
  patients: IVerbatimPatient[];
  selectedPatient: IVerbatimPatient;
  showDeleteModal: boolean;
  showCreateModal: boolean;
  showEditModal: boolean;

  newPatientType: "anonymous" | "contact";
  newPatientSelectedContact: any;
  newPatientFirstName: string;
  newPatientLastName: string;
  newPatientDisplay: string;
}

const patientHelp = Translator.getHelpText("en", "verbatimPatients");

class VerbatimPatientEdit extends React.Component<IVerbatimPatientEditProps, IVerbatimPatientEditState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      patients: props.patients,
      selectedPatient: BlankVerbatimPatient,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,

      newPatientType: "anonymous",
      newPatientSelectedContact: {},
      newPatientFirstName: "",
      newPatientLastName: "",
      newPatientDisplay: "",
    };

    this.updateField = this.updateField.bind(this);
    this.updateEditField = this.updateEditField.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.selectNewContact = this.selectNewContact.bind(this);
    this.selectPatient = this.selectPatient.bind(this);
    this.createPatient = this.createPatient.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.deletePatient = this.deletePatient.bind(this);
  }

  public render() {
    return (
      <div>
        <Card title="Patients" loading={this.state.loading || this.props.loading} help={patientHelp}>
          <div className="form-group">
            {this.state.patients.length === 0 && (<strong>No patients have been assigned to this verbatim.</strong>)}
            {this.props.patients.map((p) => {
              return(
                <div className="row" key={p.id}>
                  <div className="col-md-4">
                    {p.firstName} {p.lastName}
                  </div>
                  <div className="col-md-6">
                    {p.verbatimDisplay}
                  </div>
                  <div className="col-md-1">
                    <span className="oi oi-pencil icon icon-primary" title="Edit" onClick={() => {
                      this.setState({selectedPatient: p}, () => {
                        this.toggleShowEditModal();
                      })
                    }} />
                  </div>
                  <div className="col-md-1">
                    <span className="oi oi-x icon icon-danger" title="Delete" onClick={() => {
                      this.setState({selectedPatient: p}, () => {
                        this.toggleShowDeleteModal();
                      })
                    }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateModal}>Create New Patient</button>
          </div>
        </Card>

        <Modal show={this.state.showCreateModal} onHide={this.toggleShowCreateModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Create Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="newPatientType">Patient Type</label>
              <select id="newPatientType" className="form-control" onChange={this.updateField} value={this.state.newPatientType}>
                <option value="anonymous">Completely Anonymous</option>
                <option value="contact">Tied to a Contact</option>
              </select>
            </div>

            {this.state.newPatientType === "contact" && (
              <div>
                <ContactSearch
                  onSelect={this.selectNewContact}
                  selectedContacts={[this.state.newPatientSelectedContact]}
                  multiple={false}
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="">First Name</label>
              <input type="text" className="form-control" id="newPatientFirstName" onChange={this.updateField} value={this.state.newPatientFirstName}></input>
            </div>

            <div className="form-group">
              <label htmlFor="">Last Name</label>
              <input type="text" className="form-control" id="newPatientLastName" onChange={this.updateField} value={this.state.newPatientLastName}></input>
            </div>

            <div className="form-group">
              <label htmlFor="">Display</label>
              <input type="text" className="form-control" id="newPatientDisplay" onChange={this.updateField} value={this.state.newPatientDisplay}></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createPatient}>Create Patient</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowCreateModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Update Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Note that you currently cannot unlink a contact and a patient. If you wish to change the patient link, you must delete the patient. This will remove the entries for that patient.</p>
            <div className="form-group">
              <label htmlFor="">First Name</label>
              <input type="text" className="form-control" id="firstName" onChange={this.updateEditField} value={this.state.selectedPatient.firstName}></input>
            </div>

            <div className="form-group">
              <label htmlFor="">Last Name</label>
              <input type="text" className="form-control" id="lastName" onChange={this.updateEditField} value={this.state.selectedPatient.lastName}></input>
            </div>

            <div className="form-group">
              <label htmlFor="">Display</label>
              <input type="text" className="form-control" id="verbatimDisplay" onChange={this.updateEditField} value={this.state.selectedPatient.verbatimDisplay}></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.updatePatient}>Update Patient</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>Are you absolutely sure you want to delete {`${this.state.selectedPatient.firstName} ${this.state.selectedPatient.lastName}`}? This cannot be
              undone and will also delete all entries for that patient in the verbatim.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deletePatient}>Delete Patient</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateEditField(e: any){
    const selectedPatient = this.state.selectedPatient;
    selectedPatient[e.target.id] = e.target.value;
    this.setState({selectedPatient});
  }

  private toggleShowCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private selectNewContact(contact: any){
    this.setState({
      newPatientSelectedContact: contact,
      newPatientFirstName: contact.firstName,
      newPatientLastName: contact.lastName,
      newPatientDisplay: `Patient ${contact.firstName[0]}. ${contact.lastName[0]}.`,
    });
  }

  private deletePatient(){
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.deleteVerbatimPatient(this.props.verbatimId, this.state.selectedPatient.id);
        const p = this.state.selectedPatient;
        this.setState({ loading: false, selectedPatient: BlankVerbatimPatient}, () => {
          success("Patient deleted.");
          this.props.onDelete(p);
        })
      }catch(err){
        error("Could not delete that patient");
        this.setState({ loading: false });
      }
    });
  }

  private selectPatient(){

  }

  private updatePatient(){
    const sp = this.state.selectedPatient;
    if(sp.firstName === "" || sp.lastName === "" || sp.verbatimDisplay === ""){
      return error("All fields are required");
    }this.setState({loading: true}, async () => {
      try{
        const result = await VerbatimsAPI.updateVerbatimPatient(this.props.verbatimId, sp.id, sp);
        this.setState({ 
          loading: false,
          showEditModal: false,
          selectedPatient: BlankVerbatimPatient
        });
        this.props.onUpdate(result.body.data);
        success("Patient updated successfully!");
      }catch(err){
        error("Could not update that patient");
        this.setState({ loading: false });
      }
    });
  }

  private createPatient(){
    const data = {
      contactId: this.state.newPatientSelectedContact.id,
      firstName: this.state.newPatientFirstName,
      lastName: this.state.newPatientLastName,
      verbatimDisplay: this.state.newPatientDisplay
    };
    if(data.verbatimDisplay === "" || data.firstName === "" || data.lastName === ""){
      return error("All fields are required");
    }
    this.setState({loading: true}, async () => {
      try{
        const result = await VerbatimsAPI.createVerbatimPatient(this.props.verbatimId, data);
        this.setState({ 
          loading: false,
          showCreateModal: false,
          newPatientDisplay: "",
          newPatientFirstName: "",
          newPatientType: "anonymous",
          newPatientSelectedContact: BlankVerbatimPatient,
          newPatientLastName: "",
        });
        this.props.onCreate(result.body.data);
        success("Patient created successfully!");
      }catch(err){
        error("Could not create that patient");
        this.setState({ loading: false });
      }
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerbatimPatientEdit);