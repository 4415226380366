// Please note that while largely the same as those found in the client app, this file is stripped down to
// only show the results of the submission

import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { ToolsAPI } from "src/api";

import ToolDisplay from "./ToolDisplay";

interface IToolViewProps {
  userState: any;
  match: any;
}

interface IToolViewState {
  loading: boolean;
  toolType: string;
  submissionId: number;
  submissionData: any;
}

class ToolView extends React.Component<IToolViewProps, IToolViewState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      toolType: this.props.match.params.toolType,
      submissionId: this.props.match.params.submissionId,
      submissionData: {},
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ToolView.tsx">
        <Card title="Manage Submission" loading={this.state.loading} help="">
          <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} mode="view" submissionData={this.state.submissionData} />
        </Card>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const submissionResult = await ToolsAPI.getSubmissionById(this.state.toolType, this.state.submissionId);
        const submissionData: any = {
          ...submissionResult.body.data,
          submissionId: this.state.submissionId,
          toolType: this.state.toolType,
        }
        this.setState({loading: false, submissionData})
      }catch(error){
        this.setState({loading: false});
      }
    })
  }


}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolView) as any);