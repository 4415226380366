import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";

import * as AppActions from "src/reducers/appReducer";
import { IMilestone } from "src/api/milestones";
import DatePicker from "src/components/structure/DatePicker";
import { error } from "src/components/structure/Alert";
import { MilestonesAPI } from "src/api";

interface IMilestoneListItemProps {
  appActions: any;
  milestone: IMilestone;
  firstName: string;
  lastName: string;
  onMilestoneDeleted: any;
  onMilestoneUpdated: any;
}

interface IMilestoneListItemState {
  loading: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  title: string;
  notes: string;
  dateOccurred: moment.Moment;
  milestoneRepeat: string;
  nextDue: moment.Moment;
}

class MilestoneListItem extends React.Component<IMilestoneListItemProps, IMilestoneListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showEditModal: false,
      showDeleteModal: false,
      title: "",
      notes: "",
      dateOccurred: moment(),
      milestoneRepeat: "single",
      nextDue: moment().add(1, "years"),
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.updateDateOccurred = this.updateDateOccurred.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateMilestone = this.updateMilestone.bind(this);
    this.updateNextDue = this.updateNextDue.bind(this);
    this.deleteMilestone = this.deleteMilestone.bind(this);

  }

  componentDidMount(){
    this.setState({
      title: this.props.milestone.title,
      notes: this.props.milestone.notes,
      dateOccurred: moment(this.props.milestone.dateOccurred),
      milestoneRepeat: this.props.milestone.milestoneRepeat,
      nextDue: moment(this.props.milestone.nextDue),
    })
  }

  public render() {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          {this.props.milestone.firstName} {this.props.milestone.lastName}
        </div>
        <div className="col-lg-3 col-sm-12">
          {this.props.milestone.title}
        </div>
        <div className="col-lg-2 col-sm-12">
          {moment(this.props.milestone.dateOccurred).format("MM/DD/YYYY")}
        </div>
        <div className="col-lg-2 col-sm-12">
          {this.props.milestone.milestoneRepeat === "repeats" ? (
            <span>{moment(this.props.milestone.nextDue).format("MM/DD/YYYY")}</span>
          ): (<span>None</span>)}
        </div>
        {this.state.loading ? (
          <div style={{textAlign: "center"}}>
            <div className="spinner-border" role="status" style={{width: "1rem", height: "1rem"}}>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-lg-2 col-sm-12">
            <span className="oi oi-pencil icon icon-primary" title="Edit Contact" onClick={this.toggleEditModal} />
            <span className="oi oi-x icon icon-danger" title="Delete Contact" onClick={this.toggleDeleteModal} />
          </div>
        )}       

        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Milestone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ?
              (
                <div style={{ textAlign: "center" }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) :
              (
                <div>
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>Date Occurred</label>
                    <DatePicker
                      date={this.state.dateOccurred}
                      onDateSaved={this.updateDateOccurred}
                    />
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea className="form-control" id="notes" value={this.state.notes} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>Does This Repeat?</label>
                    <select className="form-control" id="milestoneRepeat" value={this.state.milestoneRepeat} onChange={this.updateField}>
                      <option value="single">No</option>
                      <option value="repeats">Yes, Next Due On:</option>
                    </select>
                    {this.state.milestoneRepeat === "repeats" && (
                      <DatePicker
                        date={this.state.nextDue}
                        onDateSaved={this.updateNextDue}
                      />
                    )}
                  </div>
                </div>
              )}

          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.updateMilestone}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Milestone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ?
              (
                <div style={{ textAlign: "center" }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) :
              (
                <div>
                  Are you absolutely sure you want to delete the milestone "{this.props.milestone.title}"? This cannot be undone!
                </div>
              )}

          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteMilestone}>Yes, Delete This Milestone</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateDateOccurred(newDate: moment.Moment){
    this.setState({dateOccurred: newDate});
  }

  private updateNextDue(newDate: moment.Moment){
    this.setState({nextDue: newDate});
  }

  private toggleEditModal(){
    this.setState({showEditModal: !this.state.showEditModal });
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal });
  }

  private updateMilestone(){
    const data = {
      title: this.state.title,
      notes: this.state.notes,
      dateOccurred: this.state.dateOccurred.format("YYYY-MM-DD"),
      milestoneRepeat: this.state.milestoneRepeat,
      nextDue: this.state.nextDue.format("YYYY-MM-DD"),
    };
    this.setState({ loading: true }, async () => {
      try{
        const result = await MilestonesAPI.updateMilestone(this.props.milestone.id, data);
        const milestone = result.body.data;
        
        this.props.onMilestoneUpdated(milestone);
      }catch(err){
        error("We could not update that milestone.");
        this.setState({loading: false});
      }
    });
  }

  private deleteMilestone(){
    this.setState({ loading: true }, async () => {
      try{
        await MilestonesAPI.deleteMilestone(this.props.milestone.id);
        this.props.onMilestoneDeleted(this.props.milestone);
      }catch(err){
        error("We could not delete that milestone.");
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneListItem)