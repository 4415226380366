import * as React from "react";
import { Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { ActivitiesAPI, ExportsAPI } from "../../../api";

import LineGraph from "../../structure/LineGraph";
import BarGraph from "../../structure/BarGraph";
import PieGraph from "../../structure/PieGraph";

import Card from "../../structure/Card";
import DatePicker from "../../structure/DatePicker";
import * as AppActions from "../../../reducers/appReducer";
import { success, error } from "src/components/structure/Alert";
import { IActivity } from "src/api/activities";

interface IReportsScreenProps {
  appActions: any;
  history: any;
}

interface IReportsScreenState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  showModal: boolean;

  byDay: any;
  byCategory: any;
  byTag: any;
  minutesByCategory: any;
  participantsByCategory: any;
}

class ReportsScreen extends React.Component<IReportsScreenProps, IReportsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      start: moment().subtract(1, "months"),
      end: moment(),
      showModal: false,

      byDay: [],
      byCategory: [],
      byTag: [],
      minutesByCategory: [],
      participantsByCategory: [],
    };

    this.fetchActivities = this.fetchActivities.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.toggleExport = this.toggleExport.bind(this);
    this.sendExport = this.sendExport.bind(this);
  }

  componentDidMount(){
    this.fetchActivities();
  }

  public render() {
    // the graphs we want
    // PIE - activities by category
    // BAR - activities by tag
    // LINE - activity count by date
    // BAR - participant count by category
    // BAR - minutes by category
    return (
      <div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-12">
            <Card title="Filters" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-lg-5">
                  <label>From</label>
                  <DatePicker 
                    date={this.state.start}
                    onDateSaved={this.updateStart}
                  />
                </div>
                <div className="col-lg-5">
                  <label>To</label>
                  <DatePicker 
                    date={this.state.end}
                    onDateSaved={this.updateEnd}
                  />
                </div>
                <div className="col-lg-2">
                  <button className="btn btn-primary btn-block" style={{marginTop: 32}} onClick={this.toggleExport}>Export</button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-md-4">
            <Card title="Activities By Category" loading={this.state.loading} help="">
              <PieGraph data={this.state.byCategory} />
            </Card>
          </div>
          <div className="col-md-8">
            <Card title="Activities By Tag" loading={this.state.loading} help="">
              <BarGraph data={this.state.byTag} />
            </Card>
          </div>
        </div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-md-12">
            <Card title="Activities by Date" loading={this.state.loading} help="">
              <LineGraph data={this.state.byDay} />
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Card title="Participants by Category" loading={this.state.loading} help="">
              <BarGraph data={this.state.participantsByCategory} />
            </Card>
          </div>
          <div className="col-md-6">
            <Card title="Minutes by Category" loading={this.state.loading} help="">
              <BarGraph data={this.state.minutesByCategory} />
            </Card>
          </div>
        </div>

        

        <Modal show={this.state.showModal} onHide={this.toggleExport}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Export</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to export these activities to you? You can schedule routine exports by visiting the <Link to="/exports"></Link>Exports screen.</p>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.sendExport}>Export These Activities</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleExport}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate}, () => {
      this.fetchActivities();
    });
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate}, () => {
      this.fetchActivities();
    });
  }

  private toggleExport(){
    this.setState({showModal: !this.state.showModal})
  }

  private fetchActivities(){
    this.setState({ loading: true }, async () => {
      try{
        const res = await ActivitiesAPI.getActivities("", this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        const catRes = await ActivitiesAPI.getActivityCategories();
        // for the graphs, each data set needs to be an array with objects looking like
        // { name: "X Value", count: #}
        const data = this.processActivities(res.body.data, catRes.body.data);
        this.setState({loading: false, ...data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private processActivities(activities: IActivity[], categories: string[]) {
    let byDayData: any = [];
    const byDayMap: any = {};
    const byCategoryData: any = [];
    const byCategoryMap: any = {};
    const participantsByCategoryData: any = [];
    const participantsByCategoryMap: any = {};
    const minutessByCategoryData: any = [];
    const minutesByCategoryMap: any = {};
    const byTagData: any = [];
    const byTagMap: any = {};

    // setup the initial categories
    for(const c of categories){
      byCategoryMap[c] = 0;
      participantsByCategoryMap[c] = 0;
      minutesByCategoryMap[c] = 0;
    }

    for(const a of activities){
      const d = moment(a.dateOccurred).format("YYYY-MM-DD");
      if(!byDayMap[d]){
        byDayMap[d] = 0;
      }
      byDayMap[d] += 1;

      for(let tag of a.tags){
        tag = tag.trim();
        if(tag !== ""){
          if(!byTagMap[tag]){
            byTagMap[tag] = 0;
          }
          byTagMap[tag] += 1;
        }
      }

      // this shouldn't happen as the categories should have been
      // initialized above
      if(!byCategoryMap[a.category]){
        byCategoryMap[a.category] = 0;
      }
      byCategoryMap[a.category] += 1;
      participantsByCategoryMap[a.category] += a.participantCount;
      minutesByCategoryMap[a.category] += a.lengthInMinutes;
    }

    // now put into an array
    for(const k of Object.keys(byDayMap)){
      byDayData.push({ "name": k, "count": byDayMap[k]})
    }
    for(const k of Object.keys(byCategoryMap)){
      const converted = ActivitiesAPI.translateCategory(k);
      byCategoryData.push({ "name": converted, "count": byCategoryMap[k]})
    }
    for(const k of Object.keys(byTagMap)){
      byTagData.push({ "name": k, "count": byTagMap[k]})
    }
    for(const k of Object.keys(participantsByCategoryMap)){
      const converted = ActivitiesAPI.translateCategory(k);
      participantsByCategoryData.push({ "name": converted, "count": participantsByCategoryMap[k]})
    }
    for(const k of Object.keys(minutesByCategoryMap)){
      const converted = ActivitiesAPI.translateCategory(k);
      minutessByCategoryData.push({ "name": converted, "count": minutesByCategoryMap[k]})
    }

    // fill in any missing days in the byDayData
    const days = this.state.end.diff(this.state.start, "days");
    const start = this.state.start.clone().subtract(1, "day");
    const temp: any = [];
    for(let i = 0; i <= days; i++){
      // moment is mutable, so we cloned it and we just add one day each loop
      const current = start.add(1, "days").format("YYYY-MM-DD");
      // if it isn't found, we add it
      let found = false;
      for(let byDay of byDayData){
        if(byDay.name === current){
          found = true;
          temp.push(byDay);
          break;
        }
      }
      if(!found){
        temp.push({name: current, count: 0});
      }
    }
    byDayData = temp;

    // sort the arrays
    byDayData.sort((a: any, b: any) => {
      return a.name < b.name ? -1 : 1;
    });
    byCategoryData.sort((a: any, b: any) => {
      return a.name < b.name ? -1 : 1;
    });
    byTagData.sort((a: any, b: any) => {
      return a.name < b.name ? -1 : 1;
    });

    // now transform the text of the days to be MM/DD
    for(const point of byDayData){
      point.name = moment(point.name).format("MM/DD");
    }

    return {
      byDay: byDayData,
      byTag: byTagData,
      byCategory: byCategoryData,
      minutesByCategory: minutessByCategoryData,
      participantsByCategory: participantsByCategoryData,
    };
  }

  private sendExport(){
    const data: any = {
      exportType: "activities",
      frequency: "single",
      sendNow: true,
      startTime: this.state.start.format("YYYY-MM-DDT00:00:00")+"Z",
      endTime: this.state.end.add(1, "day").format("YYYY-MM-DDT00:00:00")+"Z",
    };
    this.setState({ loading: true }, async () => {
      try{
        await ExportsAPI.createExport(data);
        success("Export scheduled!");
        this.setState({ 
          loading: false,
          showModal: false,
        });
      }catch(err){
        error("Could not create that scheduled export.");
        this.setState({ loading: false, showModal: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsScreen) as any);