import { makeCall } from "./client";

export interface IPlan {
  id: number;
  planName: string;
  term: "free" | "month" | "year";
  amount: number;
  stripeId: string;
  active: boolean;
}

export const PlanBlank: IPlan = {
  id: 0,
  planName: "",
  term: "free",
  amount: 0,
  stripeId: "",
  active: true,
}

export interface IQuota {
  userId: number;
  isSubscribed: boolean;
  smsLeft: number;
}

export const QuotaBlank: IQuota = {
  userId: 0,
  isSubscribed: false,
  smsLeft: 0,
}

export interface IPaymentHistory {
  amount: number;
  attemptDate: string;
  eventId: string;
  id: number;
  notes: string;
  planId: number;
  result: "success" | "failure" | "other";
  subscriptionId: string;
  userId: number;
  paymentType: "subscription" | "sms";
}

export const PaymentHistoryBlank: IPaymentHistory = {
  amount: 0,
  attemptDate: "2020-01-01",
  eventId: "",
  id: 0,
  notes: "",
  planId: 0,
  result: "success",
  subscriptionId: "",
  userId: 0,
  paymentType: "subscription"
}

export class QuotaAPI {

  /**
   * Gets the user's quota
   * @param otherData 
   */
  public getUserQuota(otherData: any = {}): Promise<any> {
    return makeCall("get", `quota`, otherData);
  }

  /**
   * Get the plans for the platform
   * @param data 
   */
  public getPlans(data: any = {}): Promise<any> {
    return makeCall("get", `plans`, data);
  }

  /**
   * Get the user's current payment method
   * @param data 
   */
  public getPaymentMethod(data: any = {}): Promise<any> {
    return makeCall("get", `me/payments/method`, data);
  }

  /**
   * Change the user's payment method by sending in a new Stripe token
   * @param data 
   */
  public changePaymentMethod(data: any): Promise<any> {
    return makeCall("post", `me/payments/method`, data);
  }

  /**
   * Get the user's current subscription
   * @param data 
   */
  public getCurrentSubscription(data: any = {}): Promise<any> {
    return makeCall("get", `me/subscription`, data);
  }

  /**
   * Cancel the user's current subscription at the end of the current paid period
   * @param data 
   */
  public cancelCurrentSubscription(data: any = {}): Promise<any> {
    return makeCall("delete", `me/subscription`, data);
  }

  /**
   * Change the user's subscription
   * @param planId 
   * @param data 
   */
  public changeCurrentSubscription(planId: number, data: any = {}): Promise<any> {
    return makeCall("post", `plans/${planId}`, data);
  }

  /**
   * Get the payment history for a user between two dates
   * @param start 
   * @param end 
   */
  public getPaymentHistory(start: string, end: string): Promise<any> {
    const data = {
      start,
      end
    };
    return makeCall("get", `me/payments/history`, data);
  }

  /**
   * Resend the email
   * @param historyId 
   */
  public resendHistoryEmail(historyId: number): Promise<any> {
    return makeCall("post", `me/payments/history/${historyId}/resend`, {});
  }
}