import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { VerbatimsAPI } from "src/api";
import { BlankVerbatimEntry, IVerbatimEntry, IVerbatimPatient } from "src/api/verbatims";
import { error } from "src/components/structure/Alert";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { Translator } from "src/utils/translator";

interface IVerbatimeEntryListProps {
  appActions: any;
  verbatimId: number;
  entries: IVerbatimEntry[];
  patients: IVerbatimPatient[];
  loading: boolean;

  onCreate: any;
  onUpdate: any;
  onDelete: any;
  onSetEntries: any;
}

interface IVerbatimeEntryListState {
  loading: boolean;
  entries: IVerbatimEntry[];
  selectedEntry: IVerbatimEntry;

  newEntrySourceId: number;
  newEntryType: "dialog" | "action" | "thought" | "note" | "other";
  newEntryText: string;

  showEditModal: boolean;
  showDeleteModal: boolean;
}


const entriesHelp = Translator.getHelpText("en", "verbatimEntries");

class VerbatimeEntryList extends React.Component<IVerbatimeEntryListProps, IVerbatimeEntryListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      entries: props.entries,
      selectedEntry: BlankVerbatimEntry,
      newEntrySourceId: 0,
      newEntryType: "dialog",
      newEntryText: "",
      showEditModal: false,
      showDeleteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateEditField = this.updateEditField.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.editEntry = this.editEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);

    this.moveEntryUp = this.moveEntryUp.bind(this);
    this.moveEntryDown = this.moveEntryDown.bind(this);

  }

  public render() {
    return (
      <Card title="Entries" loading={this.state.loading || this.props.loading} help={entriesHelp}>
        {this.props.patients.length === 0 && (
          <div className="row">
            <div className="col-12">
              <strong>To get started, you must first create a Patient, which can be tied to a contact or remain completely anonymous. Note: You can always change the display of the Patient for this Verbatim.</strong>
            </div>
          </div>
        )}

        {this.props.patients.length > 0 && this.state.entries.length === 0 && (
          <div className="row">
            <div className="col-12">
              <strong>No entries have been created yet.</strong>
            </div>
          </div>
        )}

        {this.state.entries.map((entry, index) => {
          return (
            <div className={
              "verbatim-entry-container " 
              + (entry.source === "chaplain" ? "verbatim-entry-container-chaplain" : "verbatim-entry-container-patient")
              + ` verbatim-entry-container-${entry.entryType}`
              } key={entry.id}>
              <div className="row">
                <div className="col-lg-1">
                  <div className="row">
                    <div className="col-12">
                      {index !== 0 && (<span className="oi oi-data-transfer-upload icon icon-add" title="Up" onClick={() => {this.moveEntryUp(index)}} />)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {index !== this.state.entries.length - 1 && (<span className="oi oi-data-transfer-download icon icon-add" title="Down" onClick={() => {this.moveEntryDown(index)}}  />)}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <strong>{entry.display}</strong>                  
                </div>
                <div className="col-lg-5">
                  {entry.verbatimText}
                </div>
                <div className="col-lg-2">
                  {entry.entryType}
                </div>
                <div className="col-lg-1">
                  <div className="row">
                    <div className="col-12">
                      <span className="oi oi-pencil icon icon-add" title="Edit" onClick={() => {
                      this.setState({ selectedEntry: entry }, () => this.toggleShowEditModal());
                    }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                    <span className="oi oi-x icon icon-add text-danger" title="Delete" onClick={() => {
                      this.setState({ selectedEntry: entry }, () => this.toggleShowDeleteModal());
                    }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {this.props.patients.length > 0 && (
          <div className="row" style={{borderTop: "thin solid gray", paddingTop: 5, marginTop: 10}}>
            <div className="col-1">
            </div>
            <div className="col-lg-3">
              <label>Who:</label>
              <select id="newEntrySourceId" className="form-control" value={this.state.newEntrySourceId} onChange={this.updateField}>
                <option value={0}>Chaplain</option>
                {this.props.patients.map((p) => {
                  return (<option value={p.id} key={p.id}>{p.verbatimDisplay}</option>)
                })}
              </select>
            </div>
            <div className="col-lg-5">
              <label>Entry</label>
              <textarea className="form-control" id="newEntryText" value={this.state.newEntryText} rows={2} onChange={this.updateField} />
            </div>
            <div className="col-lg-2">
              <label>Entry Type</label>
              <select id="newEntryType" className="form-control" value={this.state.newEntryType} onChange={this.updateField}>
                <option value="dialog">Dialog</option>
                <option value="action">Action</option>
                <option value="thought">Thought</option>
                <option value="note">Note</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-lg-1">
              <span className="oi oi-plus icon icon-add" title="Add" onClick={this.addEntry} />
            </div>
          </div>
        )}
        
        <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>Are you absolutely sure you want to delete the following entry? This cannot be undone!</p>
              <p>{this.state.selectedEntry.display}: {this.state.selectedEntry.verbatimText}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteEntry}>Delete Entry</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
        
        <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
                <label>Who</label>
                <select id="sourceId" className="form-control" value={this.state.selectedEntry.sourceId} onChange={this.updateEditField}>
                  <option value={0}>Chaplain</option>
                  {this.props.patients.map((p) => {
                    return (<option value={p.id} key={p.id}>{p.verbatimDisplay}</option>)
                  })}
              </select>
            </div>
            <div className="form-group">
              <label>Entry Type</label>
              <select id="entryType" className="form-control" value={this.state.selectedEntry.entryType} onChange={this.updateEditField}>
                <option value="dialog">Dialog</option>
                <option value="action">Action</option>
                <option value="thought">Thought</option>
                <option value="note">Note</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Entry</label>
              <textarea className="form-control" id="verbatimText" value={this.state.selectedEntry.verbatimText} rows={3} onChange={this.updateEditField} />
            </div>
            <div className="form-group">
              <label>Feedback</label>
              <textarea className="form-control" id="feedback" value={this.state.selectedEntry.feedback} rows={3} onChange={this.updateEditField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.editEntry}>Update Entry</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateEditField(e: any){
    const selectedEntry = this.state.selectedEntry;
    selectedEntry[e.target.id] = e.target.value;
    this.setState({ selectedEntry });
  }
  
  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }
  
  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private addEntry(){
    const text = this.state.newEntryText.trim();
    if(text === ""){
      return error("Text cannot be blank.");
    }
    const data = {
      source: this.state.newEntrySourceId === 0 ? "chaplain" : "patient",
      sourceId: parseInt(this.state.newEntrySourceId + "", 10),
      verbatimOrder: this.state.entries.length + 1,
      verbatimText: text,
      entryType: this.state.newEntryType,
    };
    this.setState({ loading: true }, async () => {
      try{
        const result = await VerbatimsAPI.createVerbatimEntry(this.props.verbatimId, data);
        this.setState({ loading: false, newEntrySourceId: 0, newEntryText: "", newEntryType: "dialog"});
        const entry = result.body.data;
        // we need to add in the display
        let display = "Chaplain";
        if(data.source === "patient"){
          for(const p of this.props.patients){
            if(p.id === data.sourceId){
              display = p.verbatimDisplay;
              break;
            }
          }
        }
        entry.display = display;
        this.props.onCreate(entry);
      }catch(err){
        error("Could not save that entry.");
        this.setState({ loading: false });
      }
    });
  }

  private editEntry(){
    const text = this.state.selectedEntry.verbatimText.trim();
    if(text === ""){
      return error("Text cannot be blank.");
    }
    const sourceId = parseInt(this.state.selectedEntry.sourceId + "", 10);
    const data = {
      source: sourceId === 0 ? "chaplain" : "patient",
      sourceId,
      verbatimOrder: this.state.selectedEntry.verbatimOrder,
      verbatimText: text,
      entryType: this.state.selectedEntry.entryType,
      feedback: this.state.selectedEntry.feedback,
    };
    this.setState({ loading: true }, async () => {
      try{
        const result = await VerbatimsAPI.updateVerbatimEntry(this.props.verbatimId, this.state.selectedEntry.id, data);
        const entry = result.body.data;
        const display = this.state.selectedEntry.display;
        entry.display = display;
        this.setState({ loading: false, selectedEntry: BlankVerbatimEntry, showEditModal: false }, () => {
          this.props.onUpdate(entry);
        });
      }catch(err){
        error("Could not save that entry.");
        this.setState({ loading: false });
      }
    });
  }

  private deleteEntry(){
    this.setState({ loading: true }, async () => {
      try{ 
        await VerbatimsAPI.deleteVerbatimEntry(this.props.verbatimId, this.state.selectedEntry.id);
        const se = this.state.selectedEntry;
        this.setState({ loading: false, selectedEntry: BlankVerbatimEntry}, () => {
          this.props.onDelete(se);
        });
      }catch(err){
        error("Could not delete that entry.");
        this.setState({ loading: false });
      }
    });
  }

  private moveEntryUp(index: number){
    if(index === 0){
      // wtf?
      return;
    }
    // swap them
    const entries = this.props.entries;
    [entries[index], entries[index - 1]] = [entries[index - 1], entries[index]];
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.updateVerbatimEntriesOrder(this.props.verbatimId, entries);
        this.setState({ loading: false });
        this.props.onSetEntries(entries);
      }catch(err){
        error("Could not reorder those entries.");
        this.setState({ loading: false });
      }
    });
  }

  private async moveEntryDown(index: number){
    // first, find the entry after it, then swap the order number
    // so we should only need to update two orders
    if(index >= this.props.entries.length -1){
      // wtf
      return;
    }
    // swap them
    const entries = this.props.entries;
    [entries[index], entries[index + 1]] = [entries[index + 1], entries[index]];
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.updateVerbatimEntriesOrder(this.props.verbatimId, entries);
        this.setState({ loading: false });
        this.props.onSetEntries(entries);
      }catch(err){
        error("Could not reorder those entries.");
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerbatimeEntryList);