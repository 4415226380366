import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { MilestonesAPI } from "src/api";
import { IMilestone } from "src/api/milestones";

import MilestoneList from "./MilestoneList";
import { Translator } from "src/utils/translator";

interface IMilestonesScreenProps {
  appActions: any;
  userState: any;
  history: any;
}

interface IMilestonesScreenState {
  loading: boolean;
  stats: IMilestoneStats;
  show: "all" | "user" | "contacts";
  allMilestones: IMilestone[];
  contactMilestones: IMilestone[];
  userMilestones: IMilestone[];
}

interface IMilestoneStats {
  occurred: IMilestoneCreatedStats;
  due: IMilestoneDueStats;
}

interface IMilestoneDueStats {
  next30: number;
  nextYear: number;
  total: number;
}

interface IMilestoneCreatedStats {
  last30: number;
  lastYear: number;
  total: number;
}

interface IMilestoneProcessReturn {
  stats: IMilestoneStats;
  userMilestones: IMilestone[];
  contactMilestones: IMilestone[];
}

const blankStats: IMilestoneStats = {
  occurred: {
    last30: 0,
    lastYear: 0,
    total: 0,
  },
  due: {
    next30: 0,
    nextYear: 0,
    total: 0,
  }
}

const milestonesHelpText = Translator.getHelpText("en", "milestones", "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.");

class MilestonesScreen extends React.Component<IMilestonesScreenProps, IMilestonesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      show: "user",
      stats: blankStats,
      allMilestones: [],
      contactMilestones: [],
      userMilestones: [],
    };

    this.updateField = this.updateField.bind(this);
    this.fetchMilestones = this.fetchMilestones.bind(this);
    this.handleMilestoneCreated = this.handleMilestoneCreated.bind(this);
    this.handleMilestoneDeleted = this.handleMilestoneDeleted.bind(this);
    this.handleMilestoneUpdated = this.handleMilestoneUpdated.bind(this);
  }

  componentDidMount(){
    this.fetchMilestones();
  }

  public render() {
    return (
      <Screen fileName="Milestones/MilestonesScreen.tsx">
        <div className="row">
          <div className="col-lg-3">
            <div className="row" style={{marginBottom: 20}}>
              <div className="col-12">
                <Card title="Occurred" loading={this.state.loading} help="">
                  <div className="row">
                    <div className="col-6">
                      Last 30 Days:
                    </div>
                    <div className="col-6">
                      {this.state.stats.occurred.last30}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      Last Year:
                    </div>
                    <div className="col-6">
                      {this.state.stats.occurred.lastYear}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      Total:
                    </div>
                    <div className="col-6">
                      {this.state.stats.occurred.total}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Card title="Upcoming Due" loading={this.state.loading} help="">
                  <div className="row">
                    <div className="col-6">
                      Next 30 Days:
                    </div>
                    <div className="col-6">
                      {this.state.stats.due.next30}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      Next Year:
                    </div>
                    <div className="col-6">
                      {this.state.stats.due.nextYear}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      Total:
                    </div>
                    <div className="col-6">
                      {this.state.stats.due.total}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <Card title="Milestones" loading={this.state.loading} help={milestonesHelpText}>
              <div className="row" style={{marginBottom: 20}}>
                <div className="col-2">
                  <label>Show:</label>
                </div>
                <div className="col-10">
                  <select className="form-control" id="show" value={this.state.show} onChange={this.updateField}>
                    <option value="all">All</option>
                    <option value="user">Just Mine</option>
                    <option value="contacts">Just Contacts</option>
                  </select>
                </div>
              </div>
              <MilestoneList
                milestones={this.state.show === "user" ? this.state.userMilestones : this.state.show === "contacts" ? this.state.contactMilestones : this.state.allMilestones}
                onMilestoneCreated={this.handleMilestoneCreated}
                onMilestoneDeleted={this.handleMilestoneDeleted}
                onMilestoneUpdated={this.handleMilestoneUpdated}
                contactId={0}
                key={this.state.show}
                firstName={this.props.userState.user.firstName}
                lastName={this.props.userState.user.lastName}
                />
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  private fetchMilestones(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await MilestonesAPI.getMilestones({type: "all"});
        const allMilestones: IMilestone[] = result.body.data;
        for(const m of allMilestones){
          m.dateOccurred = moment(m.dateOccurred);
          m.nextDue = moment(m.nextDue);
        }
        const processed = this.processMilestones(result.body.data);
        this.setState({
          loading: false,
          allMilestones: result.body.data,
          stats: processed.stats,
          userMilestones: processed.userMilestones,
          contactMilestones: processed.contactMilestones,
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private processMilestones(input: IMilestone[]): IMilestoneProcessReturn {
    let stats: IMilestoneStats = {
      occurred: {
        last30: 0,
        lastYear: 0,
        total: 0,
      },
      due: {
        next30: 0,
        nextYear: 0,
        total: 0,
      }
    };

    const userMilestones: IMilestone[] = [];
    const contactMilestones: IMilestone[] = [];
    const thirtyDaysAgo = moment().subtract(30, "days");
    const yearAgo = moment().subtract(1, "year");
    const thirtyFromNow = moment().add(30, "days");
    const yearFromNow = moment().add(1, "year");
    for(const c of input){
      stats.occurred.total++;
      const occurred = moment(c.dateOccurred);
      if(occurred.isSameOrAfter(thirtyDaysAgo)){
        stats.occurred.last30++;
      }
      if(occurred.isSameOrAfter(yearAgo)){
        stats.occurred.lastYear++;
      }
      // now check dues
      if(c.milestoneRepeat === "repeats"){
        stats.due.total++;
        const due = moment(c.nextDue);
        if(due.isSameOrBefore(thirtyFromNow)){
          stats.due.next30++;
        }        
        if(due.isSameOrBefore(yearFromNow)){
          stats.due.nextYear++;
        }        
      }
      // finally, add to the correct group
      if(c.milestoneType === "user"){
        userMilestones.push(c);
      }else{
        contactMilestones.push(c);
      }
    }
    
    return {
      stats,
      userMilestones,
      contactMilestones
    };
  }

  private handleMilestoneCreated(milestone: IMilestone){
    this.setState({ loading: true }, async () => {
      const allMilestones = this.state.allMilestones;
      allMilestones.push(milestone);
      const processed = this.processMilestones(allMilestones);
      this.setState({
        loading: false,
        allMilestones,
        stats: processed.stats,
        userMilestones: processed.userMilestones,
        contactMilestones: processed.contactMilestones,
      });
    });
  }

  private handleMilestoneUpdated(milestone: IMilestone){
    this.setState({ loading: true }, async () => {
      const allMilestones: IMilestone[] = [];
      for(const m of this.state.allMilestones){
        if(m.id === milestone.id){
          allMilestones.push(milestone);
        } else {
          allMilestones.push(m);
        }
      }
      const processed = this.processMilestones(allMilestones);
      this.setState({
        loading: false,
        allMilestones,
        stats: processed.stats,
        userMilestones: processed.userMilestones,
        contactMilestones: processed.contactMilestones,
      });
    });
  }

  private handleMilestoneDeleted(milestone: IMilestone){
    // find it and remove it
    // we will remove it from all then run the process
    this.setState({ loading: true }, async () => {
      const allMilestones: IMilestone[] = [];
      for(const m of this.state.allMilestones){
        if(m.id !== milestone.id){
          allMilestones.push(m);
        }
      }
      const processed = this.processMilestones(allMilestones);
      this.setState({
        loading: false,
        allMilestones,
        stats: processed.stats,
        userMilestones: processed.userMilestones,
        contactMilestones: processed.contactMilestones,
      });
    });
    
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilestonesScreen) as any);