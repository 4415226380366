
class Translation {

  public getHelpText(language: string = "en", key: string = "", defaultText: string = "") : string {
    return this.helpText[language] && this.helpText[language][key] ? this.helpText[language][key] : defaultText;
  }

  private helpText = {
    "en": {
      // quota
      "quotaGeneral": "Here you can see what you are allowed and what quota you have expended. To get more, you can upgrade your subscription",

      // dashboard
      "dashboardActivities": "Activities are events that you attended. You can track counts, time, and participants and generate reports for analysis and reporting requirements.",
      "dashboardReminders": "Reminders are things you want us to remind you about via email. They can be either daily or weekly reminders.",
      "dashboardContacts": "Contacts are individuals you need to stay in touch with. They are useful when linking to messages and tracking critical pieces of information through notes.",
      "dashboardMilestones": "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.",
    
      // profile
      "profileForm": "Use this form to update any of your profile information. Your Title, First Name, and Last Name will be attached to any messages you send out. Your Display Name will be used for contact forms and public pages (for example, message.voithosapp.com/displayname). You may optionally allow contacts from a custom public form without exposing your email or contact information.",
      "profilePaymentMethod": "In order to make full use of Voithos, you will want to subscribe. We use Stripe for subscription and payment management, so you can safely and securely update your payment information through this form.",
      "profileSubscription": "Subscriptions are available to take Voithos to the next level. Here you can see your current max quota as well as what benefits are included in upgrading.",

      // contacts
      "contactGroups": "You may optionally group your contacts into groups, such as by unit, service, or interactions.",
      "searchContacts": "You can search all of your contacts from here. Selecting the contact will bring you to their details.",
      "contactsList": "Contacts are people that you interact with. You can create notes on contacts, detailing critical information. Notes are heavily encrypted if you login with a secret key. If you provide their email or cell phone number, you can also schedule messages to send to them that they can view using our encrypted web app.",
      "contactMilestones": "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.",
      "contactActivities": "An Activity is something that you did, such as a counseling session or training event. Tags are used for analysis and reporting. You can optionally assign contacts to activities for tracking purposes.",


      // activities
      "activityStats": "Below is a break down of your activities by category.",
      "activitiesList": "Activities are events that you attended. You can track counts, time, and participants and generate reports for analysis and reporting requirements. Reports can also be generated based upon tags, allowing for customized tracking and viewing.",

      // messages
      "messagesList": "Messages are things you want to communicate to a contact. On the send date, a notification is sent (via email or SMS) to the contact letting them know they have a new message. It will contain your title, first name, and last name but NOT your email or cell phone number. They will receive a link with a code which will allow them to access and decrypt their secure message.",

      // reminders
      "remindersStats": "Below is a break down of your reminders by category and separately by status.",
      "remindersList": "Reminders are items you want us to remind you about on either a daily or weekly basis. You will receive the reminder information in an email, so make sure it is information you don't mind receiving in an email. You can group them by Severity for ease of tracking. We recommend closing reminders you don't want to receive anymore so that you have the historical records, but of course you are free to delete them if necessary.",

      // scheduled exports
      "exportsList": "Scheduled Exports are emails that get sent to an email address that provides a CSV of your selected data. In the case of contacts, it is useful for backing up and sharing. In the case of activities, it will send the number of events, participants, and minutes for your events by category and tag. You can choose to have exports sent weekly (in which case each export will have the previous week's data) or at the end of the month (in which case each export will contain the data through the end of the month). Contacts will always include all contacts regardless of frequency.",
      "exportsSent": "Below is a list of exports that have been delivered.",

      // milestones
      "milestones": "Milestones are single events in a user or contact's life. It can be a single event (birth of a child, wedding, etc) or a recurring event (report due, group registration, etc). This list shows milestones that have occurred recently.",
      
      // verbatims
      "verbatims": "A verbatim is a detailed record of a counseling session. It is used heavily in educational and training environments as a way to 'bring the session' to the instructor or group. After a session, the Chaplain tries to record as much information with exact wording as possible. Using Voithos, you can record, document additional information, and then export is either as a Word or PDF file for printing or editing.",
      "verbatimStatus": "Here you can change the status of the verbatim. This can be useful for remembering if this was a work in progress, a completed verbatim you wanted to review, or an archived verbatim you are done with. It will not remove the verbatim from your account.",
      "verbatimPatients": "Patients, sometimes referred to as care-seekers, are the non-Chaplain participants in the session who are seeking counsel from the Chaplain. The Display field will be used in the Verbatim Entries to show who is speaking.",
      "verbatimMetadata": "Often, there's extra information you want to associate with a verbatim, such as a location or reflection. These will be displayed when printing the verbatim and can be reordered in the final document.",
      "verbatimEntries": "Entries are the heart of the verbatim. An entry is a single set of dialog, often a sentence or two, in which a person speaks and another person responds. You can also record thoughts, actions, or notes and it will be inserted alongside the dialogs. A number will be assigned, in order, when the document is exported, for easy reference in a review setting.",
    },
  };
};


export const Translator = new Translation();