import * as React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { ExportsAPI } from "../../../api";
import { IExport } from "../../../api/exports";
import DatePicker from "../../structure/DatePicker";
import { error, success } from "../../structure/Alert";


interface IExportListItemProps {
  item: IExport;
  onDelete: any;
  onEdit: any;
}

interface IExportListItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;

  exportType: "activities" | "contacts";
  additionalEmails: string;
  frequency: "weekly" | "endOfMonth" | "daily" | "single";
  nextSend: moment.Moment;
}

export default class ExportListItem extends React.Component<IExportListItemProps, IExportListItemState> {

  constructor(props: IExportListItemProps){
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
      showEditModal: false,
      exportType: props.item.exportType,
      nextSend: moment(props.item.nextSend),
      additionalEmails: props.item.additionalEmails,
      frequency: props.item.frequency,
    };

    this.updateField = this.updateField.bind(this);
    this.updateNextSend = this.updateNextSend.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  public render() {
    return (
      <div key={this.props.item.id}>
          <div className="row list-row">
            <div className="col-lg-2 col-sm-12">
              {this.props.item.nextSend.format("MM/DD/YYYY")}
            </div>
            <div className="col-lg-2 col-sm-12">
              {this.displayFrequency(this.props.item.frequency)}
            </div>
            <div className="col-lg-2 col-sm-12">
              {this.props.item.exportType === "activities" ? "Activities" : "Contacts"}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.props.item.additionalEmails}
            </div>
            <div className="col-lg-1 col-sm-12">
              <span className="oi oi-pencil icon icon-primary" title="Edit Item" onClick={this.toggleEditModal} />
            </div>
            <div className="col-lg-1 col-sm-12">
              <span className="oi oi-x icon icon-danger" title="Delete Item" onClick={this.toggleDeleteModal} />
            </div>
        </div>

        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Update Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Send To This Email Address:</label>
              <input type="text" className="form-control" id="additionalEmails" value={this.state.additionalEmails} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Frequency</label>
              <select className="form-control" id="frequency" value={this.state.frequency} onChange={this.updateField}>
                <option value="weekly">Weekly</option>
                <option value="endOfMonth">End Of Month</option>
              </select>
            </div>
            <div className="form-group">
              <label>Starting</label>
              <DatePicker
                key={this.state.nextSend.format("YYYY-MM-DD")}
                date={this.state.nextSend}
                onDateSaved={this.updateNextSend}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.save}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete scheduled Export</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete the scheduled export?
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.delete}>Delete</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns: any = this.state;
    ns[id] = val;
    if(id === "frequency"){
      // we need to calculate a default
      if(val === "endOfMonth"){
        ns["nextSend"] = moment().endOf("month");
      } else if(val === "weekly"){
        ns["nextSend"] = moment().add(1, "day");
      }
    }
    this.setState(ns)
  }

  private updateNextSend(newDate: moment.Moment){
    this.setState({ nextSend: newDate});
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private save(){
    const data: any = {
      exportType: this.state.exportType.trim(),
      additionalEmails: this.state.additionalEmails.trim(),
      frequency: this.state.frequency.trim(),
      nextSend: this.state.nextSend.format("YYYY-MM-DD"),
    };
    if(data.exportType === "" || data.additionalEmails === "" || data.frequency === "") {
      return error("All fields are required");
    }
    if(this.state.nextSend.isBefore(moment())){
      return error("Next Send must be in the future");
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await ExportsAPI.updateExport(this.props.item.id, data);
        const item = result.body.data;
        item.createdOn = moment(item.createdOn);
        item.nextSend = moment(item.nextSend);
        success("Scheduled export updated");
        this.setState({ showEditModal: false, loading: false });
        this.props.onEdit(result.body.data);
      }catch(e){
        error("Could not update that scheduled export.");
        this.setState({loading: false});
      }
    });
  }

  private delete(){
    this.setState({ loading: true }, async () => {
      try{
        await ExportsAPI.deleteExport(this.props.item.id);
        success("Scheduled export deleted");
        this.setState({ showDeleteModal: false });
        this.props.onDelete(this.props.item);
      }catch(e){
        error("Could not delete that scheduled export. Contact support");
        this.setState({loading: false});
      }
    });
  }

  private displayFrequency(frequency: string): string {
    switch(frequency){
      case "weekly":
        return "Weekly";
      case "endOfMonth":
        return "End of the month";
      default:
        return "";
    }
  }

}
