import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import Screen from "src/components/structure/Screen";

const logo = "https://cdn.voithosapp.com/images/banner_transparent.png";

const env = process.env.REACT_APP_ENV || "local";

class Home extends React.Component<any, any> {
  public render() {
    if (this.props.userState.loggedIn) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <Screen>
          <div>
            <div className="row justify-content-center">
              <div className="col-12" style={{ textAlign: "center" }}>
                <img src={logo} alt="Voithos Logo" style={{ maxWidth: "80%" }} />
                <h3>The Modern App Helping Professionals Take Care of People</h3>
              </div>
            </div>
            {env === "demo" && (
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-sm-12" style={{
                  border: "thin solid black",
                  padding: 5,
                  backgroundColor: "#faf3c3"
                }}>
                  <p>Thank you for trying the Voithos demo account! This is the Provider Portal. If you are looking for the Client Portal, please go to <a href="https://democlient.voithosapp.com" target="_new">https://democlient.voithosapp.com</a>. This instance is <strong>wiped and restored daily with semi-randomized data.</strong> It is meant to give you an opportunity to explore many of the benefits Voithos can bring to your services. We have configured several different account configurations for you to try. Remember, this is a demonstration, and not all features are enabled. For example, actual emails and messages will not be sent to avoid abuse.</p>
                  <p>To get started, please <strong><Link to="/login" style={{textDecoration: "underline"}}>Login!</Link></strong></p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                Voithos is an integrated platform enabling care providers to track their efforts, clients, reports, and trends. Using modern technology, Voithos is an indispensible tool for Chaplains, Ministers, Mental Health Coaches, and Interventionists. Let us help you help others! It is completely online and provides activity tracking, client management, milestones, reminders, messaging, verbatims, and more!
              </div>
            </div>
            <div className="row justify-content-center" style={{ marginTop: 15 }}>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Easy to Use</h3>
                <p>Clean. Simple. Intuitive. We strive to have a great platform that enables you to be more effective. With a free tier that allows you to get up and running quickly, it is easy to quickly improve your helping potential!</p>
              </div>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Secure</h3>
                <p>We use state of the art technology to ensure a safe, stable, and secure environment for tracking your helping contacts, activities, notes, tools, and messages.</p>
              </div>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Open to All</h3>
                <p>Although we focus on professional helpers, such as Chaplains, Counselors, and Mental Health Professionals, it is easy to adapt our software for almost any situation that requires tracking of activities, contacts, and messages. With a free tier, it's easy to evaluate if we will fit your needs!</p>
              </div>
            </div>
  
          </div>
        </Screen>
      );
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home) as any);