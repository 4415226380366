import moment from "moment";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { JournalAPI } from "src/api";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";

interface IJournalEntriesScreenProps {
  clientId: number;
}

interface IJournalEntriesScreenState {
  loading: boolean;
  entries: any[];
  showViewModal: boolean;
  selectedEntry: any;
}

export default class JournalEntriesScreen extends React.Component<IJournalEntriesScreenProps, IJournalEntriesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      entries: [],
      showViewModal: false,
      selectedEntry: { themes: []}
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.toggleShowViewModal = this.toggleShowViewModal.bind(this);
    this.selectEntryForViewing = this.selectEntryForViewing.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="JournalEntriesScreen.tsx">
        <Card title="Shared Journal Entries" loading={this.state.loading} help="">
          
          {this.state.entries.map((entry: any) => {
            return (
              <div className="row" key={entry.id}>
                <div className="col-2">
                  {moment(entry.date).format("MM/DD/YY")}
                </div>
                <div className="col-2">
                  {entry.firstName} {entry.lastName}
                </div>
                <div className="col-4">
                  {entry.title}
                </div>
                <div className="col-3">
                  {entry.themes.map((th: any, i: number) => {
                    return (
                      <span key={i}>{i !== 0 && ", "}{th.display}</span>
                    )
                  })}
                </div>
                <div className="col-1">
                  <span className="oi oi-eye icon icon-primary actionable" title="View" onClick={() => {this.selectEntryForViewing(entry)}} />
                </div>
              </div>
            )
          })}
        </Card>

        <Modal show={this.state.showViewModal} onHide={this.toggleShowViewModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong>Entry on: </strong> {moment(this.state.selectedEntry.date).format("MM/DD/YYYY")}
            </div>
            <div className="form-group">
              <strong>{this.state.selectedEntry.title}</strong>
            </div>
            <div className="form-group" style={{whiteSpace: "pre-wrap"}}>
              {this.state.selectedEntry.body}
            </div>
            <div className="form-group">
              Themes: {this.state.selectedEntry.themes.map((th: any, i: number) => {
                    return (
                      <span key={i}>{i !== 0 && ", "}{th.display}</span>
                    )
                  })}
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-lg-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowViewModal}>Close</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        const res = await JournalAPI.getSharedEntriesByCreator(this.props.clientId);
        this.setState({ entries: res.body.data, loading: false });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private selectEntryForViewing(entry: any){
    this.setState({ selectedEntry: entry, showViewModal: true });
  }

  private toggleShowViewModal(){
    this.setState({ showViewModal: !this.state.showViewModal });
  }

}