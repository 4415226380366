import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Screen from "src/components/structure/Screen";
import DatePicker from "src/components/structure/DatePicker";
import { error, success } from "src/components/structure/Alert";

import * as AppActions from "src/reducers/appReducer";
import {MilestonesAPI} from "src/api";
import { IMilestone } from "src/api/milestones";
import MilestoneListItem from "./MilestoneListItem";
import moment from "moment";

interface IMilestoneListProps {
  appActions: any;
  milestones: IMilestone[];
  contactId: number;
  firstName: string;
  lastName: string;
  onMilestoneDeleted: any;
  onMilestoneUpdated: any;
  onMilestoneCreated: any;
}

interface IMilestoneListState {
  loading: boolean;
  showCreateModal: boolean;

  newMilestoneType: "user" | "contact";
  newMilestoneContactId: number;
  newMilestoneDateOccurred: moment.Moment;
  newMilestoneTitle: string;
  newMilestoneNotes: string;
  newMilestoneRepeat: "single" | "repeats";
  newMilestoneNextDue: moment.Moment;
}

class MilestoneList extends React.Component<IMilestoneListProps, IMilestoneListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showCreateModal: false,

      newMilestoneType: "user",
      newMilestoneContactId: 0,
      newMilestoneDateOccurred: moment(),
      newMilestoneTitle: "",
      newMilestoneNotes: "",
      newMilestoneRepeat: "single",
      newMilestoneNextDue: moment().add(1, "month"),
    };

    this.updateField = this.updateField.bind(this);
    this.updateDateOccurred = this.updateDateOccurred.bind(this);
    this.updateNextDue = this.updateNextDue.bind(this);
    this.createMilestone = this.createMilestone.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);

    this.handleMilestoneUpdated = this.handleMilestoneUpdated.bind(this);
    this.handleMilestoneDeleted = this.handleMilestoneDeleted.bind(this);
  }

  public render() {
    return (
      <Screen fileName="MilestoneList.tsx">
        <div className="row list-row-header">
          <div className="col-lg-3">
            Name
          </div>
          <div className="col-lg-3">
            Title
          </div>
          <div className="col-lg-2">
            Occurred
          </div>
          <div className="col-lg-2">
            Next Due
          </div>
          <div className="col-lg-2" />
        </div>

        {this.props.milestones.length === 0 && (
          <div className="row">
            <div className="col-lg-12" style={{fontWeight: "bold", marginTop: 10, marginBottom: 10, textAlign: "center", fontSize: 18}}>
              None
            </div>
          </div>
        )}
        {this.props.milestones.map((milestone: IMilestone) => {
          return (
            <MilestoneListItem 
              key={milestone.id} 
              milestone={milestone} 
              onMilestoneUpdated={this.handleMilestoneUpdated} 
              onMilestoneDeleted={this.handleMilestoneDeleted} 
              firstName={this.props.firstName}
              lastName={this.props.lastName}
              />)
        })}

        <div className="row" style={{marginTop: 20}}>
          <div className="col-lg-12">
            <button className="btn btn-block btn-primary" onClick={this.toggleCreateModal}>Create New Milestone</button>
          </div>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Create Milestone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ?
              (
                <div style={{ textAlign: "center" }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) :
              (
                <div>
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" id="newMilestoneTitle" value={this.state.newMilestoneTitle} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>Date Occurred</label>
                    <DatePicker
                      date={this.state.newMilestoneDateOccurred}
                      onDateSaved={this.updateDateOccurred}
                    />
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea className="form-control" id="newMilestoneNotes" value={this.state.newMilestoneNotes} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>Does This Repeat?</label>
                    <select className="form-control" id="newMilestoneRepeat" value={this.state.newMilestoneRepeat} onChange={this.updateField}>
                      <option value="single">No</option>
                      <option value="repeats">Yes, Next Due On:</option>
                    </select>
                    {this.state.newMilestoneRepeat === "repeats" && (
                      <DatePicker
                        date={this.state.newMilestoneNextDue}
                        onDateSaved={this.updateNextDue}
                      />
                    )}
                  </div>
                </div>
              )}

          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createMilestone}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateDateOccurred(newDate: moment.Moment){
    this.setState({ newMilestoneDateOccurred: newDate });
  }

  private updateNextDue(newDate: moment.Moment){
    this.setState({ newMilestoneNextDue: newDate });
  }

  private toggleCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal });
  }

  private createMilestone(){
    // we need to remember our context;
    // if contactId is not 0, then we are in a contact milestone and need to 
    // include that data
    const title = this.state.newMilestoneTitle.trim();
    const notes = this.state.newMilestoneNotes.trim();
    if(title === ""){
      return error("Title is required");
    }
    const data = {
      title,
      notes,
      dateOccurred: this.state.newMilestoneDateOccurred.format("YYYY-MM-DD"),
      milestoneType: this.props.contactId === 0 ? "user" : "contact",
      contactId: this.props.contactId,
      milestoneRepeat: this.state.newMilestoneRepeat,
      nextDue: this.state.newMilestoneNextDue.format("YYYY-MM-DD"),
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await MilestonesAPI.createMilestone(data);
        const milestone = result.body.data;
        milestone.firstName = this.props.firstName;
        milestone.lastName = this.props.lastName;
        this.props.onMilestoneCreated(milestone);
        this.setState({
          loading: false,
          showCreateModal: false,
          newMilestoneType: "user",
          newMilestoneContactId: 0,
          newMilestoneDateOccurred: moment(),
          newMilestoneTitle: "",
          newMilestoneNotes: "",
          newMilestoneRepeat: "single",
          newMilestoneNextDue: moment().add(1, "month"),
        });
        success("Milestone created!");
      }catch(err){
        error("Could not create that milestone.");
        this.setState({loading: false});
      }
    })

  }

  private handleMilestoneUpdated(milestone: IMilestone){
    this.props.onMilestoneUpdated(milestone);
  }

  private handleMilestoneDeleted(milestone: IMilestone){
    this.props.onMilestoneDeleted(milestone);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneList)