import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";

import { ContactsAPI, UserAPI } from "../../../api";

import * as UserActions from "../../../reducers/userReducer";
import * as ContactActions from "../../../reducers/contactsReducer";
import { bindActionCreators } from "redux";
import { PasswordInput } from "src/components/structure/PasswordInput";

interface ILoginScreenProps {
  alertsActions: any;
  userActions: any;
  contactActions: any;
  setupActions: any;
  userState: any;
  history: any;
}

interface ILoginScreenState {
  email: string;
  password: string;
  secretKey: string;
  loading: boolean;
  showSecretKeyModal: boolean;
  expired: boolean;
  env: string;
}

const helpText = ``;
class Login extends React.Component<ILoginScreenProps, ILoginScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      secretKey: "",
      loading: false,
      showSecretKeyModal: false,
      expired: false,
      env: "local",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.toggleShowSecretKeyModal = this.toggleShowSecretKeyModal.bind(this);
    this.copy = this.copy.bind(this);
  }

  componentDidMount() {
    // if we get here and the user is logged in AND the expired key is set, then we log them out
    if (this.props.userState.loggedIn) {
      if (window.localStorage.getItem("expired")) {
        this.props.userActions.logoutUser();
        this.setState({ expired: true });
      }
    }

    let env = process.env.REACT_APP_ENV || "local";
    if(env === "dev" || env === "develop"){
      env = "dev";
    } else if(env === "demo" || env === "demonstration"){
      env = "demo";
    }
    this.setState({ env });
  }

  public render() {
    return (
      <div className="row justify-content-md-center">
        {this.state.env === "demo" && (
          <div className="col-lg-4 col-sm-12">
            <Card title="Voithos Demo">
              <p>Thank you for trying the Voithos demo account! This is the Provider Portal. If you are looking for the Client Portal, please go to <a href="https://democlient.voithosapp.com" target="_new">https://democlient.voithosapp.com</a>. This instance is <strong>wiped and restored daily with semi-randomized data.</strong> It is meant to give you an opportunity to explore many of the benefits Voithos can bring to your services. We have configured several different account configurations for you to try. Remember, this is a demonstration, and not all features are enabled. For example, actual emails and messages will not be sent to avoid abuse.</p>
              <p>To get started, login with one of the following accounts.</p>
              <p>
                <strong className="actionable" onClick={() => this.copy("demo_provider_subscribed@voithosapp.com", "demo_provider_123")}>Subscribed Provider:</strong>
                <ul>
                  <li>Email: demo_provider_subscribed@voithosapp.com</li>
                  <li>Password: demo_provider_123</li>
                </ul>
              </p>
              <p>
                <strong className="actionable" onClick={() => this.copy("demo_provider_free@voithosapp.com", "demo_provider_123")}>Free Account Provider:</strong>
                <ul>
                  <li>Email: demo_provider_free@voithosapp.com</li>
                  <li>Password: demo_provider_123</li>
                </ul>
              </p>
            </Card>
          </div>
        )}
        <div className="col-lg-6 col-sm-12">
          {!this.props.userState.loggedIn ? (
            <Card title="Login" help={helpText}>
              {this.state.expired && (
                <div className="row">
                  <div className="col-md-12 bg-danger text-white" style={{ padding: 15, marginBottom: 20 }}>
                    <strong>You have been logged out as your authentication has expired. Please log back in.</strong>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <PasswordInput id="password" value={this.state.password} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>Secret Key <span onClick={this.toggleShowSecretKeyModal} style={{ marginLeft: 30, cursor: "pointer" }}> What's This?</span> </label>
                    <input type="password" id="secretKey" className="form-control" value={this.state.secretKey} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    {this.state.loading ? (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{ textAlign: "center", width: "100%" }} />) :
                      (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Login</button>)}

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pull-right">
                  <Link to="/signup">Signup</Link><br />
                </div>
                <div className="col-6 pull-right">
                  <Link to="/reset">Forgot?</Link><br />
                </div>
              </div>
            </Card>) : (
            <Card title="Login">
              <div className="row">
                <div className="col-md-6">
                  You are logged in!
                </div>
              </div>
            </Card>
          )}
        </div>

        <Modal show={this.state.showSecretKeyModal} onHide={this.toggleShowSecretKeyModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>About the Secret Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The Secret Key is a unique input that encrypts your data, similar to your password. It is not stored by us. This ensure that no one can see your data without this text. That includes Voithos staff! This extra layer of security provides you with the confidence that your data is safe, secure, and confidential.</p>

            <p>However, since it is not stored by Voithos, you must remember this Secret Key and enter it each time you login. If you use a different Secret Key, you will not be able to see previously entered material, as it will be encrypted with a different key. <strong>So, if you use a Secret Key, make sure you use the same one each time and on each device.</strong> Voithos team members cannot retrieve this key or decrypt your data so if you lose or forget your secret key, your information will remain encrypted.</p>

            <p><strong>We strongly recommend using a secret key and that you back it up securely.</strong></p>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.toggleShowSecretKeyModal}>OK</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleShowSecretKeyModal() {
    this.setState({
      showSecretKeyModal: !this.state.showSecretKeyModal,
    });
  }

  public async handleSubmit() {
    this.setState({ loading: true }, async () => {

      try {
        const result = await UserAPI.loginUser(this.state.email, this.state.password, this.state.secretKey);
        const user = result.body.data;
        user.jwt = "";
        user.secretKey = "";
        user.access_token = "";
        user.refresh_token = "";

        window.localStorage.user = JSON.stringify(user);

        const contactsResult = await ContactsAPI.getAllContactsForUser();
        const contacts = contactsResult.body.data;

        const contactGroupsResult = await ContactsAPI.getContactGroups();
        const contactGroups = contactGroupsResult.body.data;

        // set the action
        this.props.userActions.loginUser({ loggedIn: true, user });
        this.props.contactActions.setContacts(contacts);
        this.props.contactActions.setContactGroups(contactGroups);
        window.localStorage.removeItem("expired");
        const red = window.localStorage.getItem("redirect");
        if(!red || red === ""){
          window.location.href = "/dashboard";
        } else {
          window.localStorage.removeItem("redirect");
          window.location.href = red;
        }

      } catch (e) {
        error("Could not log you in.");
        this.setState({ loading: false });
      }
    });
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any) {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  private copy(email: string, password: string){
    this.setState({
      email,
      password,
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    contactActions: bindActionCreators(ContactActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as any);