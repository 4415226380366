import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { QuotaAPI } from "src/api";
import { IPlan, PlanBlank } from "src/api/quota";
import PaymentMethod from "./PaymentMethod";
import Subscription from "./Subscription";
import {PlanTable} from "src/components/structure/PlanTable";

interface IManageSubscriptionScreenProps {
  appActions: any;
}

interface IManageSubscriptionScreenState {
  loading: boolean;
  isSubscribed: boolean;
  showPromo: boolean;
  smsLeft: number;

  plans: IPlan[];
  currentPlan: IPlan;
  hasPaymentMethod: boolean;
}

const isDemo = process.env.REACT_APP_ENV === "demo";

class ManageSubscriptionScreen extends React.Component<IManageSubscriptionScreenProps, IManageSubscriptionScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      isSubscribed: false,
      showPromo: true,
      smsLeft: 0,

      plans: [],
      currentPlan: PlanBlank,
      hasPaymentMethod: true,
    };

    this.getSubscriptionStatus = this.getSubscriptionStatus.bind(this);
    this.toggleShowPromo = this.toggleShowPromo.bind(this);
    this.updateHasPaymentMethod = this.updateHasPaymentMethod.bind(this);
  }

  componentDidMount(){
    this.getSubscriptionStatus();
  }

  public render() {
    if(this.state.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    // if not subscribed, we want to show the promo material, then they click through
    if(this.state.showPromo && !this.state.isSubscribed){
      return (
        <Screen fileName="ManageSubscriptionScreen.tsx">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Card loading={this.state.loading} help="">
                <p style={{fontSize: 20}}><strong>Ready to take your Voithos experience to the next level? Checkout out these benefits of upgrading!</strong></p>
                <PlanTable />
                <div className="row">
                  <div className="col-lg-4 offset-lg-4" style={{textAlign: "center"}}>
                    {isDemo ? (
                      <a href="https://pro.voithosapp.com/subscription" className="btn btn-block btn-primary"> Get Started!</a>
                    ): (
                      <button className="btn btn-primary btn-block" onClick={this.toggleShowPromo}>Get Started!</button>
                    )}
                  </div>
                </div>
                <div className="row" style={{marginTop: 15}}>
                  <div className="col-12" style={{textAlign: "right"}}>
                    <span className="small">*500 included in subscription, does not renew. Option to purchase additional SMS credits as needed (Pay as you Go)</span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Screen>
      )
    }
    return (
      <Screen fileName="ManageSubscriptionScreen.tsx">
        <div className="row">
          <div className={this.state.hasPaymentMethod ? "col-lg-4" : "col-lg-4 offset-lg-4"}>
            <PaymentMethod title="Step 1: Enter a Payment Method" setPaymentMethodStatus={this.updateHasPaymentMethod} />
          </div>
          {this.state.hasPaymentMethod && (
            <div className="col-lg-8">
              <Subscription title="Step 2: Choose a Subscription" plans={this.state.plans} />
            </div>
          )}
        </div>
      </Screen>
    );
  }

  private getSubscriptionStatus(){
    this.setState({loading: true}, async () => {
      try{
        const plansResult = await QuotaAPI.getPlans();
        const subscriptionResult = await QuotaAPI.getCurrentSubscription();
        console.log(plansResult);
        console.log(subscriptionResult);

        this.setState({
          loading: false, 
          plans: plansResult.body.data,
          isSubscribed: subscriptionResult.body.data.isSubscribed,
          showPromo: !subscriptionResult.body.data.isSubscribed,
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private toggleShowPromo(){
    this.setState({ showPromo: !this.state.showPromo});
  }

  private updateHasPaymentMethod(hasPaymentMethod: boolean){
    this.setState({ hasPaymentMethod});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageSubscriptionScreen) as any);