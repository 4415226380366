import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { QuotaAPI } from "src/api";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import {SubscriptionBlock} from "src/components/structure/SubscriptionBlock";

import Todoist from "./Todoist";


const isDemo = process.env.REACT_APP_ENV === "demo";

interface IIntegrationsScreenProps {
  appActions: any;
}

interface IIntegrationsScreenState {
  loading: boolean;
  isSubscribed: boolean;
}

class IntegrationsScreen extends React.Component<IIntegrationsScreenProps, IIntegrationsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      isSubscribed: false
    };
    this.fetchQuota = this.fetchQuota.bind(this);
  }

  componentDidMount(){
    this.fetchQuota();
  }

  public render() {
    if(!this.state.isSubscribed){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <SubscriptionBlock featureDisplay="Third-Party Connections" />
          </div>
        </div>
      );
    }
    if(isDemo){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            Sorry, this is not available in the demo.
          </div>
        </div>
      );
    }
    return (
      <Screen fileName="ConnectionsScreen.tsx">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <Todoist />
          </div>
        </div>
      </Screen>
    );
  }

  private fetchQuota(){
    this.setState({loading: true}, async () => {
      try{
        const res = await QuotaAPI.getUserQuota();
        this.setState({
          loading: false,
          isSubscribed: res.body.data.isSubscribed,
        });
      }catch(e){
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IntegrationsScreen) as any);