import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dropzone from 'react-dropzone'
import moment from "moment";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { ActivitiesAPI, ImportsAPI, JobsAPI } from "src/api";
import { error, success } from "src/components/structure/Alert";
import Screen from "src/components/structure/Screen";
import { IJob, JobBlank } from "src/api/jobs";

const isDemo = process.env.REACT_APP_ENV === "demo";

interface IImportsScreenProps {
  appActions: any;
  history: any;
}

interface IImportsScreenState {
  loading: boolean;
  importType: "activities" | "contacts" | "";
  file: any;
  activityCategories: string[];
  jobs: IJob[];
  selectedJob: IJob;
  showJobDetails: boolean;
}

class ImportsScreen extends React.Component<IImportsScreenProps, IImportsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      importType: "",
      file: null,
      activityCategories: [],
      jobs: [],
      selectedJob: JobBlank,
      showJobDetails: false,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchActivityCategories = this.fetchActivityCategories.bind(this);
    this.fetchJobs = this.fetchJobs.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.toggleJobDetails = this.toggleJobDetails.bind(this);
  }

  componentDidMount(){
    this.setState({ loading: true }, async () => {
      await this.fetchActivityCategories();
      await this.fetchJobs();
    })
  }

  public render() {
    return (
     <Screen>
       <div className="row">
        <div className="col-lg-6">
          <Card title="Import" loading={this.state.loading} help="">
            <p>Here you may import a CSV file for a bulk upload of data. You will need a CSV with the data as specified in the import type.</p>
            <div className="form-group">
              <label>Select an import type</label>
              <select id="importType" className="form-control" value={this.state.importType} onChange={this.updateField}>
                <option value="">Select an import type</option>
                <option value="activities">Activities</option>
                <option value="contacts">Contacts</option>
              </select>
            </div>
            {this.state.importType === "activities" && this.getActivitiesDescription()}
            {this.state.importType === "contacts" && this.getContactsDescription()}
          </Card>
        </div>
        <div className="col-lg-6">
          <Card title="Import History" loading={this.state.loading} help="">
            <p>Below is a history of your imports. You may hit refresh to reload the list if you have an import pending.</p>
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.fetchJobs}>Refresh</button>
            </div>
            {this.state.jobs.length === 0 && (
              <div className="row">
                <div className="col-md-12">
                  <strong>No imports have been started</strong>
                </div>
              </div>
            )}
            <div className="row list-row-header">
              <div className="col">Started</div>
              <div className="col">Last Update</div>
              <div className="col">Status</div>
              <div className="col">View</div>
            </div>
            {this.state.jobs.map((job, index) => {
              return (
                <div className="row list-row" key={index}>
                  <div className="col">{job.created.format("MM/DD/YY h:mm A")}</div>
                  <div className="col">{job.lastUpdated.format("MM/DD/YY h:mm A")}</div>
                  <div className="col">{JobsAPI.translateJobStatus(job.status)}</div>
                  <div className="col" onClick={() => this.selectJob(job)}><span className="oi oi-eye icon icon-primary" title="View Details"/></div>
                </div>
              )
            })}
          </Card>
        </div>
        <Modal show={this.state.showJobDetails} onHide={this.toggleJobDetails}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Job Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row import-history-job-detail-row">
              <div className="col-12">
                <strong>Created</strong>: {this.state.selectedJob.created.format("MM/DD/YY h:mm A")}
              </div>
            </div>
            <div className="row import-history-job-detail-row">
              <div className="col-12">
                <strong>Last Updated</strong>: {this.state.selectedJob.lastUpdated.format("MM/DD/YY h:mm A")}
              </div>
            </div>
            <div className="row import-history-job-detail-row">
              <div className="col-12">
                <strong>Status</strong>: {JobsAPI.translateJobStatus(this.state.selectedJob.status)}
              </div>
            </div>
            <div className="row import-history-job-detail-row">
              <div className="col-12">
                <strong>More Information:</strong> {JobsAPI.translateJobStatusCodeToMessage(this.state.selectedJob.statusCode, this.state.selectedJob.statusMessage)}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-info" onClick={this.toggleJobDetails}>Close</button>
          </Modal.Footer>
        </Modal>
       </div>
     </Screen>
    );
  }

  private getActivitiesDescription(){
    return (
      <div>
        <div className="form-group">
            Activities have the following fields as headers (first row):
            <ul>
              <li><strong>name</strong>: The name of the activity, such as Group Counseling</li>
              <li><strong>date occurred / date</strong>: The date of the activity </li>
              <li><strong>participant count / participants</strong>: The number of participants</li>
              <li><strong>length in minutes / minutes</strong>: The length of time the activity took, in minutes</li>
              <li><strong>category</strong>: The Category of the event. Must be one of: 
                <ul>
                  {this.state.activityCategories.map((cat: string) => {
                    return (
                      <li key={cat}>{ActivitiesAPI.translateCategory(cat)}</li>
                    );
                  })}
                </ul>
              </li>
              <li><strong>notes</strong>: Any additional notes (OPTIONAL)</li>
              <li><strong>tags</strong>: A series of tags, separated by commas (OPTIONAL)</li>
              <li><strong>skip</strong>: If present and a non-empty value, this record will be skipped. Useful if you are uploading a "living" document</li>
              <li><strong>follow up</strong>: Whether a follow up is needed. Can be either "none", "active", or "completed" (OPTIONAL)</li>
              <li><strong>follow up on</strong>: A date that the system should remind you to follow up on this activity (OPTIONAL)</li>
              <li><strong>last followed up on</strong>: A date that the last follow up occurred on; defaults to dateOccurred if not provided (OPTIONAL)</li>
            </ul>
        </div>
        <div className="form-group">
          {this.getUploadForm()}
        </div>
      </div>
    );
  }


  private getContactsDescription(){
    return (
      <div>
        <div className="form-group">
            Contacts have the following fields as headers (first row):
            <ul>
              <li><strong>title</strong>: The contact's title, such as "Mr" or "Chaplain"</li>
              <li><strong>first name</strong>: The contact's first or given name</li>
              <li><strong>last name</strong>: The contact's last or family name</li>
              <li><strong>email</strong>: A good email address for the contact</li>
              <li><strong>cell / phone</strong>: The phone number for the contact</li>
              <li><strong>type / contact type</strong>: The type of contact, one of: "general", "manager", "direct_report"</li>
            </ul>
            <strong>Note: we do not currently support importing contacts into a specific group.</strong>
        </div>
        <div className="form-group">
          {this.getUploadForm()}
        </div>
      </div>
    );
  }

  private getUploadForm(){
    if(isDemo){
      return (
        <div className="row">
          <div className="col-12">
            Sorry, this is not available in the demo.
          </div>
        </div>
      )
    }
    return (
      <Dropzone onDrop={acceptedFiles => this.setState({ file: acceptedFiles[0]}, () => this.uploadFile())}>
        {({getRootProps, getInputProps}) => (
          <section className="dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} className="form-control"/>
              <p>Click or Drop a CSV here or click to select a file</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }

  private updateField(e: any){
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private uploadFile(){
    this.setState({loading: true}, async () => {
      try{
        await ImportsAPI.importFile(this.state.importType, this.state.file);
        success("Import has been uploaded and will be processed");
        this.setState({loading: false, importType: ""}, () => this.fetchJobs());
      }catch(e){
        error("We could not process that file. Please try again or contact support");
      }
    })
  }


  private fetchActivityCategories(){
    this.setState({loading: true}, async () => {
      try{
        const res = await ActivitiesAPI.getActivityCategories();
        const categories = res.body.data;
        this.setState({
          activityCategories: categories,
          loading: false,
        });
      }catch(e){
        // ?????
        this.setState({loading: false});
      }
    });
  }

  private fetchJobs(){
    this.setState({ loading: true }, async () => {
      try {
        const result = await JobsAPI.getJobs("imports", {count: 10});
        const jobs: IJob[] = [];
        for(const j of result.body.data){
          j.created = moment(j.created);
          j.lastUpdated = moment(j.lastUpdated);
          jobs.push(j);
        }
        this.setState({ jobs, loading: false});
      }catch(err){
        this.setState({loading: false});
      }
    })
  }

  private selectJob(job:IJob) {
    this.setState({selectedJob: job, showJobDetails: true});
  }

  private toggleJobDetails(){
    this.setState({ showJobDetails: !this.state.showJobDetails});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportsScreen) as any);