import { makeCall } from "./client";
import moment from "moment";

export interface ITimelineEntry {
  id: number;
  userId: number;
  jobType: "import" | "export";
  created: moment.Moment;
  lastUpdated: moment.Moment;
  status: "pending" | "need_approval" | "approved" | "completed" | "error";
  statusCode: string;
  statusMessage: string;
}

export class TimelineAPI {

  public getMyTimeline(data: any = {}): Promise<any> {
    return makeCall("get", `timeline/`, data);
  }

  public getUserTimeline(userId: number, data: any = {}): Promise<any> {
    return makeCall("get", `users/${userId}/timeline/`, data);
  }

  public generateEventFromEntry(entry: ITimelineEntry): ITimelineEvent {
    const eventMomentFormat = "MM/DD/YY hh:mm A";
    let event: ITimelineEvent = {
      id: entry.id,
      title: entry.display,
      createdAt: moment(entry.occurredOn).format(eventMomentFormat),
      icon: "check"
    }

    switch(entry.eventKey){
      // lifecycle
      case "signup":
        event.icon = "user-plus"
        break;
      case "login":
        event.icon = "sign-in-alt"
        break;
      case "changedPassword":
        event.icon = "lock"
        break;
      case "updatedProfile":
        event.icon = "user"
        break;
      
      // tools
      case "toolSubmit":
        event.icon = "file-alt"
        break;
      case "toolShared":
        event.icon = "share"
        break;
      case "toolRequest":
        event.icon = "retweet"
        break;


      // messages
      case "sentMessage":
        event.icon = "comment";
        break;


      // journals
      case "journalEntryCreated":
        event.icon = "book";
        break;
      case "journalEntryShared":
        event.icon = "book-open";
        break;
    }

    return event;
  }
}

export interface ITimelineEntry {
  connectionId: number;
  display: string;
  eventKey: string;
  id: number;
  occurredOn: moment.Moment;
  referenceId: number;
  referenceTable: string;
  userId: number;
  visibility: string;
}

export interface ITimelineEvent {
  id: number;
  title: string;
  createdAt: string;
  icon: string;
}