import * as React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";

import { UserAPI } from "../../../api";

import * as UserActions from "../../../reducers/userReducer";
import { bindActionCreators } from "redux";

interface ISignupVerifyScreenProps {
  alertsActions: any;
  schoolActions: any;
  userActions: any;
  setupActions: any;
  userState: any;
  history: any;
  location: any;
}

const helpText = ``;
class SignupVerify extends React.Component<ISignupVerifyScreenProps, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
      email: "",
      token: "",
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  public componentDidMount(){
    // parse the URL
    const params = qs.parse(this.props.location.search); 
    this.setState({
      email: params.email ? params.email : "",
      token: params.token ? params.token : "",
    });
  }

  public async handleSubmit() {
    const email = this.state.email.trim();
    const token = this.state.token.trim();
    if (email === "" || token === "") {
      return error("All fields are required!")
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.signupVerify(email, token);
        success("Account verified! Please login.");
        this.setState({ loading: false, step: 2 });

      } catch (e) {
        error("Could not verify that token in.");
        this.setState({ loading: false });
      }
    });
  }

  public render() {
    if (this.props.userState.loggedIn) {
      return (<Redirect
        to="/dashboard" />);
    }

    if (this.state.step === 2) {
      return (<Redirect
        to="/login" />);
    }

    return (
      <div className="row justify-content-md-center">
        <div className="col-lg-6 col-sm-12">
            <Card title="SignupVerify" help={helpText}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <label>Token</label>
                    <input type="text" id="token" className="form-control" value={this.state.token} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    {this.state.loading ? (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{ textAlign: "center", width: "100%" }} />) :
                      (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Verify</button>)}

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 pull-right">
                  <Link to="/login">Login</Link><br />
                </div>
                <div className="col-lg-6 col-sm-12 pull-right">
                  <Link to="/reset">Forgot?</Link><br />
                </div>
              </div>
            </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any) {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupVerify) as any);