import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Screen from "src/components/structure/Screen";
import ManageConnectionSubmissions from "./ManageConnectionSubmissions";
import { Tabs, Tab } from "react-bootstrap";
import { ToolsAPI } from "src/api";
import { ITool } from "src/api/tools";
import ClientTimeline from "./ClientTimeline";
import TrendsAnalysis from "./TrendsAnalysis";
import JournalEntriesScreen from "./JournalEntries";

interface IManageConnectionScreenProps {
  match: any;
}

interface IManageConnectionScreenState {
  loading: boolean;
  tools: ITool[];
  availableCategories: string[];
}

class ManageConnectionScreen extends React.Component<IManageConnectionScreenProps, IManageConnectionScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      tools: [],
      availableCategories: []
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ManageConnectionScreen.tsx">
        <Tabs defaultActiveKey="submissions" id="manageConnectionTabs">
          <Tab eventKey="submissions" title="Submissions">
            <ManageConnectionSubmissions clientId={this.props.match.params.clientId} tools={this.state.tools} availableCategories={this.state.availableCategories} />
          </Tab>
          <Tab eventKey="journal" title="Shared Journal Entries">
            <JournalEntriesScreen clientId={this.props.match.params.clientId} />
          </Tab>
          <Tab eventKey="timeline" title="Timeline">
            <ClientTimeline clientId={this.props.match.params.clientId} />
          </Tab>
          <Tab eventKey="trends" title="Trends"> 
            <TrendsAnalysis clientId={this.props.match.params.clientId} tools={this.state.tools} availableCategories={this.state.availableCategories} />
          </Tab>
        </Tabs>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        // ools
        const toolsResult = await ToolsAPI.getTools();
        const tools = toolsResult.body.data;
        const availableCategories: string[] = [];
        for(const c of toolsResult.body.data){
          if(availableCategories.indexOf(c.category) === -1){
            availableCategories.push(c.category);
          }
        }
        this.setState({ loading: false, tools, availableCategories });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageConnectionScreen) as any);