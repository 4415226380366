import { makeCall } from "./client";
import moment from "moment";

export interface IVerbatim {
  id: number;
  createdBy: number;
  createdOn: moment.Moment;
  lastUpdated: moment.Moment;
  dateOccurred: moment.Moment;
  notes: string;
  status: "draft" | "completed" | "archived";
  display: string;
}

export const BlankVerbatim: IVerbatim = {
  id: 0,
  createdBy: 0,
  createdOn: moment(),
  lastUpdated: moment(),
  dateOccurred: moment(),
  notes: "",
  status: "draft",
  display: "",
};

export interface IVerbatimPatient {
  id: number;
  verbatimId: number;
  contactId: number;
  firstName: string;
  lastName: string;
  verbatimDisplay: string;
}

export const BlankVerbatimPatient: IVerbatimPatient = {
  id: 0,
  verbatimId: 0,
  contactId: 0,
  firstName: "",
  lastName: "",
  verbatimDisplay: "",
}

export interface IVerbatimMetaData {
  verbatimId: number;
  field: string;
  value: string;
}

export interface IVerbatimEntry {
  id: number;
  verbatimId: number;
  source: "chaplain" | "patient";
  sourceId: number;
  verbatimOrder: number;
  verbatimText: string;
  entryType: "dialog" | "action" | "thought" | "note" | "other";
  feedback: string;
  display: string;
}

export const BlankVerbatimEntry: IVerbatimEntry = {
  id: 0,
  verbatimId: 0,
  source: "chaplain",
  sourceId: 0,
  verbatimOrder: 0,
  verbatimText: "",
  entryType: "dialog",
  feedback: "",
  display: "Chaplain",
};

export const verbatimEntryTypes = ["dialog", "action", "thought", "note", "other"];

export class VerbatimsAPI {

  /**
   * Get the verbatims for the account
   * @param data 
   * @returns 
   */
  public getVerbatims(data: any = {}): Promise<any> {
    return makeCall("get", `verbatims`, data);
  }

  /**
   * Create a new verbatim
   * @param data 
   * @returns 
   */
  public createVerbatim(data: any = {}): Promise<any> {
    return makeCall("post", `verbatims`, data);
  }

  /**
   * Get a single verbatim
   * @param id 
   * @param data 
   * @returns 
   */
  public getVerbatim(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("get", `verbatims/${verbatimId}`, data);
  }

  /**
   * Prints / downloads a verbatim in either PDF or Word format
   * @param verbatimId 
   * @param format 
   * @param otherData 
   * @returns 
   */
  public printVerbatim(verbatimId: number, format: "pdf" | "word", otherData: any = {}, options: any = {}): Promise<any> {
    const data = {
      ...otherData,
      format,
    }
    return makeCall("post", `verbatims/${verbatimId}/print`, data, options);
  }

  /**
   * Delete a verbatim
   * @param id 
   * @param data 
   * @returns 
   */
  public deleteVerbatim(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `verbatims/${verbatimId}`, data);
  }

  /**
   * Update a single verbatim
   * @param id 
   * @param data 
   * @returns 
   */
  public updateVerbatim(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `verbatims/${verbatimId}`, data);
  }

  /**
   * Get the patients
   * @param verbatimId 
   * @param data 
   * @returns 
   */
  public getVerbatimPatients(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("get", `verbatims/${verbatimId}/patients`, data);
  }

  /**
   * Create a new patient
   * @param verbatimId 
   * @param data 
   * @returns 
   */
  public createVerbatimPatient(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("post", `verbatims/${verbatimId}/patients`, data);
  }

  /**
   * Update a patient
   * @param verbatimId 
   * @param patientId 
   * @param data 
   * @returns 
   */
  public updateVerbatimPatient(verbatimId: number, patientId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `verbatims/${verbatimId}/patients/${patientId}`, data);
  }

  /**
   * Delete a patient
   * @param verbatimId 
   * @param patientId 
   * @param data 
   * @returns 
   */
  public deleteVerbatimPatient(verbatimId: number, patientId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `verbatims/${verbatimId}/patients/${patientId}`, data);
  }

  /**
   * Get the metadata
   * @param verbatimId 
   * @param data 
   * @returns 
   */
  public getVerbatimMetadata(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("get", `verbatims/${verbatimId}/meta`, data);
  }

  /**
   * Create or update a metadata field
   * @param verbatimId 
   * @param field 
   * @param value 
   * @param data 
   * @returns 
   */
  public saveVerbatimMetadata(verbatimId: number, field: string, value: string, data: any = {}): Promise<any> {
    const allData = {
      ...data,
      field,
      value
    }
    return makeCall("post", `verbatims/${verbatimId}/meta`, allData);
  }

  /**
   * Delete a metadata field
   * @param verbatimId 
   * @param field 
   * @param data 
   * @returns 
   */
  public deleteVerbatimMetadata(verbatimId: number, field: string, data: any = {}): Promise<any> {
    return makeCall("delete", `verbatims/${verbatimId}/meta/${field}`, data);
  }

  /**
   * Get the entries for a verbatim
   * @param verbatimId 
   * @param data 
   * @returns 
   */
  public getVerbatimEntries(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("get", `verbatims/${verbatimId}/entries`, data);
  }

  /**
   * Creates a new verbatim entry
   * @param verbatimId 
   * @param data 
   * @returns 
   */
  public createVerbatimEntry(verbatimId: number, data: any = {}): Promise<any> {
    return makeCall("post", `verbatims/${verbatimId}/entries`, data);
  }

  /**
   * Update a single entry
   * @param verbatimId 
   * @param entryId 
   * @param data 
   * @returns 
   */
  public updateVerbatimEntry(verbatimId: number, entryId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `verbatims/${verbatimId}/entries/${entryId}`, data);
  }

  /**
   * Reorders the entries; entries should be an array of {id: 0} and will be saved in the order the server gets them
   * @param verbatimId 
   * @param entries 
   * @param data 
   * @returns 
   */
  public updateVerbatimEntriesOrder(verbatimId: number, entries: any[], data: any = {}): Promise<any> {
    const order = {
      entries,
    }
    return makeCall("put", `verbatims/${verbatimId}/entries/order`, order);
  }

  /**
   * Delete an entry
   * @param verbatimId 
   * @param entryId 
   * @param data 
   * @returns 
   */
  public deleteVerbatimEntry(verbatimId: number, entryId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `verbatims/${verbatimId}/entries/${entryId}`, data);
  }
}