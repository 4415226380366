import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { UserAPI } from "src/api";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";
import moment from "moment";

interface IReferralsProps {
  appActions: any;
}

interface IReferralsState {
  loading: boolean;
  referredFirstName: string;
  referredLastName: string;
  referredEmail: string;
  anonymous: "yes" | "no";
  selectedReferral: any;
  showNewReferralModal: boolean;
  showDeleteReferralModal: boolean;

  referrals: any[];
}

class Referrals extends React.Component<IReferralsProps, IReferralsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      referrals: [],
      showDeleteReferralModal: false,
      showNewReferralModal: false,
      referredFirstName: "",
      referredLastName: "",
      referredEmail: "",
      anonymous: "no",
      selectedReferral: {},
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.toggleDeleteReferralModal = this.toggleDeleteReferralModal.bind(this);
    this.toggleNewReferralModal = this.toggleNewReferralModal.bind(this);
    this.selectReferralForDelete = this.selectReferralForDelete.bind(this);
    this.createReferral = this.createReferral.bind(this);
    this.deleteReferral = this.deleteReferral.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Referrals.tsx">
        <div className="row">
            <div className="col-lg-3">
                <Card title="About Referrals" loading={this.state.loading} help="">
                    <p>If you find Voithos useful, it would be incredibly helpful if you could refer other professionals to our platform! We primarily grow through referrals from users of our platform who find out features easy, comprehensive, and helpful to their services.</p>
                    <p>When you submit a referral, they will receive an email (optionally with your name) and a coupon if they decide to upgrade their account. Users who successfully refer new users may receive future rewards, including coupons and upgrades.</p>
                    <p>If you have any questions, comments, or suggestions, always feel free to contact us above. We truly thank you for taking the time to help us grow!</p>
                    <button className="btn btn-block btn-primary" onClick={this.toggleNewReferralModal}>New Referral</button>
                </Card>
            </div>
            <div className="col-lg-8">
                <Card title="My Referrals" loading={this.state.loading} help="">
                    <div className="row">
                        <div className="col-lg-3 list-row-header">Name</div>
                        <div className="col-lg-4 list-row-header">Email</div>
                        <div className="col-lg-2 list-row-header">Referred On</div>
                        <div className="col-lg-2 list-row-header">Status</div>
                        <div className="col-lg-1 list-row-header"></div>
                    </div>
                    {this.state.referrals.length === 0 && (
                        <div className="row">
                            <div className="col-12">
                                <p style={{textAlign: "center", fontWeight: "bold"}}>None</p>
                            </div>
                        </div>
                    )}
                    {this.state.referrals.map((ref) => {
                        return (
                            <div className="row" key={ref.id}>
                                <div className="col-lg-3">{ref.referredFirstName} {ref.referredLastName}</div>
                                <div className="col-lg-4">{ref.referredEmail}</div>
                                <div className="col-lg-2">{ref.referredOn}</div>
                                <div className="col-lg-2">{ref.status}</div>
                                <div className="col-lg-1"><span className="oi oi-x icon icon-danger" title="Delete" onClick={() => {
                                    this.selectReferralForDelete(ref);
                                }} /></div>
                            </div>
                        );
                    })}
                </Card>
            </div>
        </div>

        <Modal show={this.state.showNewReferralModal} onHide={this.toggleNewReferralModal}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Create New Referral</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label>First Name</label>
                    <input type="text" className="form-control" id="referredFirstName" value={this.state.referredFirstName} onChange={this.updateField} />
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" className="form-control" id="referredLastName" value={this.state.referredLastName} onChange={this.updateField} />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control" id="referredEmail" value={this.state.referredEmail} onChange={this.updateField} />
                </div>
                <div className="form-group">
                    <label>Refer Anonymously?</label>
                    <select className="form-control" id="anonymous" value={this.state.anonymous} onChange={this.updateField}>
                        <option value="no">No, Include My Name in the Referral</option>
                        <option value="yes">Yes, Do NOT Include My Name in the Referral</option>
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.createReferral}>Send Referral</button>
                <button className="btn btn-block btn-info" onClick={this.toggleNewReferralModal}>Cancel</button>
            </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteReferralModal} onHide={this.toggleDeleteReferralModal}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Delete Referral</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong className="text-danger">Warning: </strong> This cannot be undone! Are you sure you want to delete your referral for {this.state.selectedReferral.referredFirstName} {this.state.selectedReferral.referredLastName}? They likely already received the email referral at this time!
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.deleteReferral}>Yes, Delete Referral</button>
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteReferralModal}>Cancel</button>
            </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleNewReferralModal(){
    this.setState({ showNewReferralModal: !this.state.showNewReferralModal});
  }

  private toggleDeleteReferralModal(){
    this.setState({ showDeleteReferralModal: !this.state.showDeleteReferralModal});
  }

  private selectReferralForDelete(referral: any){
    this.setState({ selectedReferral: referral, showDeleteReferralModal: true});
  }

  private setup(){
    this.setState({ loading: true }, async () => {
        this.getReferrals();
    });
  }

  private getReferrals(){
    this.setState({ loading: true }, async () => {
      try{
        const refResult = await UserAPI.getReferrals();
        const referrals: any[] = [];
        for(const ref of refResult.body.data){
            referrals.push({
                ...ref,
                referredOn: moment(ref.referredOn).format("MM/DD/YY"),
                status: ref.status.charAt(0).toUpperCase() + ref.status.slice(1),
            })
        }
        this.setState({ referrals, loading: false });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private async createReferral(){
    this.setState({ loading: true }, async () => {
        const data = {
            referredFirstName: this.state.referredFirstName.trim(),
            referredLastName: this.state.referredLastName.trim(),
            referredEmail: this.state.referredEmail.trim(),
            anonymous: this.state.anonymous,
        }
        if(data.referredFirstName === "" || data.referredLastName === "" || data.referredEmail === ""){
            return error("All fields are required!");
        }
        try{
            await UserAPI.createReferral(data);
            success("Referral successfully created!");
            this.setState({
                referredFirstName: "",
                referredLastName: "",
                referredEmail: "",
                showNewReferralModal: false,
            }, async () => this.getReferrals())
        }catch(err){
            return error("Could not save that referral. They may already have an account, may already be referred, or there may some other error with the request.");
        }
    });
    
  }

  private async deleteReferral(){
    this.setState({ loading: true }, async () => {
        try{
            await UserAPI.deleteReferral(this.state.selectedReferral.id);
            success("Referral deleted!");
            this.setState({ selectedReferral: {}, showDeleteReferralModal: false }, async () => this.getReferrals());
        }catch(err){
            error("Could not delete that referral");
        }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Referrals) as any);