import * as React from "react";
import { Modal } from "react-bootstrap";

import { ContactsAPI } from "../../../api";
import { error } from "../../structure/Alert";

interface IContact {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  cell: string;
  contactType: "general" | "direct_report" | "manager";
}

interface IContactListItemProps {
  contact: IContact;
  onEditContact: any;
  onDeleteContact: any;
  onSelectContact: any;
  key: any;
}

interface IContactListItemState {
  loading: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  contact: IContact;
}

export default class ContactListItem extends React.Component<IContactListItemProps, IContactListItemState> {

  constructor(props: IContactListItemProps){
    super(props);
    this.state = {
      loading: false,
      showEditModal: false,
      showDeleteModal: false,
      contact: props.contact,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.saveContact = this.saveContact.bind(this);

    this.selectContact = this.selectContact.bind(this);
    this.onDeleteContact = this.onDeleteContact.bind(this);
  }

  public render() {
    return (
      <div key={this.props.contact.id} className="row list-row">
        <div className="col-lg-4 col-sm-12 cursor-link" onClick={this.selectContact}>
          {this.state.contact.title} {this.state.contact.firstName} {this.state.contact.lastName}
        </div>
        <div className="col-lg-3 col-sm-12">
          <a href={`mailto:${this.state.contact.email}`}>{this.state.contact.email}</a>
        </div>
        <div className="col-lg-3 col-sm-8">
          <a href={`tel:${this.state.contact.cell}`}>{this.state.contact.cell}</a>
        </div>
        <div className="col-lg-2 col-sm-4">
          <span className="oi oi-pencil icon icon-primary" title="Edit Contact" onClick={this.toggleEditModal} />
          <span className="oi oi-x icon icon-danger" title="Delete Contact" onClick={this.toggleDeleteModal} />
        </div>


        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" id="title" value={this.state.contact.title} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" className="form-control" id="firstName" value={this.state.contact.firstName} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" className="form-control" id="lastName" value={this.state.contact.lastName} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input type="text" className="form-control" id="email" value={this.state.contact.email} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>Cell Phone Number</label>
              <input type="text" className="form-control" id="cell" value={this.state.contact.cell} onChange={this.updateField}/>
            </div>
            <div className="form-group">
              <label>Contact Type</label>
              <select className="form-control" id="contactType" value={this.state.contact.contactType} onChange={this.updateField}>
                <option value="general">General</option>
                <option value="direct_report">Direct Report</option>
                <option value="manager">My Manager</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
            <   button className="btn btn-block btn-primary" onClick={this.saveContact}>Update Contact</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{`Delete ${this.state.contact.firstName} ${this.state.contact.lastName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong>You are about to delete the contact {this.state.contact.firstName} {this.state.contact.lastName}. This cannot be undone.
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.onDeleteContact}>Delete Contact</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const contact = this.state.contact;
    contact[id] = val;
    this.setState({contact});
  }

  private selectContact(){
    // redirect to the contact page
    this.props.onSelectContact(this.props.contact);
  }

  private saveContact(){
    const data = {
      title: this.state.contact.title,
      firstName: this.state.contact.firstName,
      lastName: this.state.contact.lastName,
      email: this.state.contact.email,
      cell: this.state.contact.cell,
      contactType: this.state.contact.contactType,
    };
    if(data.firstName === "" || data.lastName === ""){
      return error("First Name and Last Name are required");
    }
    this.setState({ loading: true}, async () => {
      try{
        const res = await ContactsAPI.updateContact(this.props.contact.id, data);
        this.setState({loading: false, showEditModal: false});
        return this.props.onEditContact(res.body.data);
      }catch(err){
        this.setState({loading: false, showEditModal: false});
        return error("Could not update that contact");
      }
    });
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private onDeleteContact(){
    this.setState({loading: true}, async () => {
      try{
        await ContactsAPI.deleteContact(this.props.contact.id);
        this.setState({loading: false, showDeleteModal: false});
        this.props.onDeleteContact(this.props.contact);
      }catch(err){
        error("Could not delete that contact");
        this.setState({loading: false, showDeleteModal: false});
      }
    });
  }

}