import * as React from "react";
import { IMessage } from "../../../api/messages";

interface IMessageListItemProps {
  message: IMessage;
  onDelete: any;
  onEdit: any;
  user: any;
  onSelectedForViewing: any;
  onSelectedForReply: any;
  onSelectedForEdit: any;
  onSelectedForDelete: any;
}

interface IMessageListItemState {
}

export default class MessageListItem extends React.Component<IMessageListItemProps, IMessageListItemState> {

  constructor(props: IMessageListItemProps){
    super(props);
    this.state = {
    };

    this.selectForDelete = this.selectForDelete.bind(this);
    this.selectForEdit = this.selectForEdit.bind(this);
    this.selectForReply = this.selectForReply.bind(this);
    this.selectForViewing = this.selectForViewing.bind(this);
  }


  public render() {
    return (
      <div className="row" style={{marginTop: 10}}>
        <div className="col-12">
          <div key={this.props.message.id} className="card activity-list-card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-1 col-sm-12">
                  {this.props.message.toId === this.props.user.id && this.props.message.status === "sent" && (
                    <span className="oi oi-star icon icon-success" title="New" />
                  )}
                </div>
                <div className="col-lg-2 col-sm-12">
                  <strong>{this.props.message.status === "pending" ? 
                    this.props.message.sendDate.format("MM/DD/YY") :
                    this.props.message.deliveredOn.format("MM/DD/YY h:mm A")}</strong>
                </div>
                <div className="col-lg-1 col-sm-12">
                  {this.props.message.status === "sent" ? "Sent" : 
                  this.props.message.status === "pending" ? "Pending" :
                  this.props.message.status === "viewed" || this.props.message.status === "hidden" ? 
                    (<span>Viewed</span>) :
                    (<span>{this.props.message.status}</span>)
                  }
                </div>
                <div className="col-lg-4 col-sm-12">
                  {this.props.message.toId === this.props.user.id ? (
                    <span>
                     From: {`${this.props.message.fromTitle} ${this.props.message.fromFirstName} ${this.props.message.fromLastName}`}
                    </span>
                  ): (
                    <span>
                      To: {`${this.props.message.toTitle} ${this.props.message.toFirstName} ${this.props.message.toLastName}`}
                    </span>
                  )}
                </div>
                <div className="col-1">
                  <span className="oi oi-eye icon icon-primary actionable" title="View" onClick={this.selectForViewing} />
                </div>
                <div className="col-lg-1 col-sm-12">
                  {(this.props.message.status === "sent" || this.props.message.status === "viewed") && this.props.message.toId === this.props.user.id && (
                    <span className="oi oi-action-undo icon icon-primary actionable" title="Reply" onClick={this.selectForReply} />
                  )} 
                </div>
                <div className="col-1">
                  {this.props.message.status === "pending" && (<span className="oi oi-pencil icon icon-primary actionable" title="Edit" onClick={this.selectForEdit} />)}
                </div>
                <div className="col-1">
                  {this.props.message.status === "pending" && (<span className="oi oi-x icon icon-danger actionable" title="Delete" onClick={this.selectForDelete} />)}
                </div>
              </div>
              <div className="row" style={{marginTop: 10, marginBottom: 10}}>
                <div className="col-lg-12 col-sm-12">
                  <span className="link actionable" onClick={this.selectForViewing}><strong>{this.props.message.subject}</strong></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }

  private selectForViewing(){
    this.props.onSelectedForViewing(this.props.message);
  }

  private selectForEdit(){
    this.props.onSelectedForEdit(this.props.message);
  }

  private selectForReply(){
    this.props.onSelectedForReply(this.props.message);
  }

  private selectForDelete(){
    this.props.onSelectedForDelete(this.props.message);
  }

}
