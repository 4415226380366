import * as React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from "recharts";

interface IBarGraphDataPoint {
  count: number;
  label?: string;
}

interface IBarGraphProps {
  data: IBarGraphDataPoint[];
  hideLegend?: boolean;
}

interface IBarGraphState {
  loading: boolean;
}

class BarGraph extends React.Component<IBarGraphProps, IBarGraphState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };

  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <BarChart data={this.props.data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
        <Bar dataKey="count" fill="#8884d8" />
          <YAxis />
          <XAxis dataKey="name" />
          <Tooltip />
          {!this.props.hideLegend && (<Legend />)}
        </BarChart>
      </ResponsiveContainer>
    );
  }

}

export default BarGraph;