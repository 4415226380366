import * as React from "react";
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from "recharts";

interface IPieChartProps {
  data: any;
  toolFormatter?: any;
}

interface IPieChartState {
  loading: boolean;
}


const pieColors = [
  "4A148C",
  "B71C1C",
  "311B92",
  "FFEB3B",
  "01579B",
  "1B5E20",
  "F57F17",
  "263238",
  "1DE9B6",
];

class PieGraph extends React.Component<IPieChartProps, IPieChartState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <PieChart>
          <Pie dataKey="count" nameKey="name" isAnimationActive={true} data={this.props.data} outerRadius={120} fill="#8884d8" label={false}>
            {this.props.data.map((entry: any, index: number) => {
              const colorIndex = index >= pieColors.length ? index - pieColors.length : index;
                return (<Cell key={`cell-${index}`} fill={entry.color ? "#"+entry.color : "#" + pieColors[colorIndex]} />);
              })}
          </Pie>
          <Tooltip formatter={this.props.toolFormatter ? this.props.toolFormatter : null}/>
        </PieChart>
      </ResponsiveContainer>
    );
  }

}


export default PieGraph;