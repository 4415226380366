import { Typeahead } from "react-bootstrap-typeahead";
import * as React from "react";
import { connect } from "react-redux";
import { UserAPI } from "src/api";

interface IConnectionSearchProps {
  connections: any[];
  selectedConnections: any[];
  onSelect: any;
  multiple?: boolean;
}

interface IConnectionSearchState {
  loading: boolean;
  connections: any[];
  selectedConnections: any;
}

class ConnectionSearch extends React.Component<IConnectionSearchProps, IConnectionSearchState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      connections: [],
      selectedConnections: [],
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.fetchConnections = this.fetchConnections.bind(this);
  }

  componentDidMount(){
    if(this.props.connections.length === 0){
      // none are selected, and we don't have any, so we make an API call
      this.fetchConnections();
    } else {
      // they already provided a list of connections, so we can just see who is selected
    }
  }

  public render() {
    return (
      <Typeahead
        id="connection-search"
        key={this.state.connections.length}
        multiple={this.props.multiple ? true : false}
        placeholder="Enter Connection Name"
        onChange={this.handleSelect}
        selected={this.state.selectedConnections}
        options={this.state.connections} />
    );
  }

  private fetchConnections(){
    this.setState({loading: true}, async () => {
      try{
        const res = await UserAPI.getUserConnections();
        const connections = this.formatConnections(res.body.data);
        this.setState({connections, loading: false});
      }catch(err){
        console.log(err);
      }
    })
  }

  private formatConnections(raw: any[]): any[]{
    const connections: any[] = [];
    raw.forEach((conn: any) => {
      if(conn.status !== "accepted"){
        return;
      }
      const label = `${conn.userFirstName} ${conn.userLastName}`;
      conn.label = label;
      connections.push(conn);
    })
    return connections;
  }

  private handleSelect(selectedContacts: any){
    if(this.props.multiple){
      this.props.onSelect(selectedContacts);
    } else {
      this.props.onSelect(selectedContacts[0]);
    }
    this.setState({selectedConnections: selectedContacts})
  }


}


const mapStateToProps = function map(s: any) {
  return {
    contactsState: s.contactsState
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionSearch);