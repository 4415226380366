import { makeCall } from "./client";

export class IntegrationsAPI {

  /**
   * Sets up a new third-party integration
   * @param source 
   * @param otherData 
   * @returns 
   */
  public setUpIntegration(source: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      source,
    }
    return makeCall("post", `integrations/setup`, data);
  }

  /**
   * Disconnects an integration, which may end up with deleted or orphaned data
   * @param source 
   * @param data 
   * @returns 
   */
  public disconnectIntegration(source: string, data: any = {}): Promise<any> {
    return makeCall("delete", `integrations/${source}`, data);
  }

  /**
   * Get all integrations
   * @param data 
   * @returns 
   */
  public getIntegrations(data: any = {}): Promise<any> {
    return makeCall("get", `integrations`, data);
  }

  /**
   * Get an integration status for a source
   * @param source 
   * @param otherData 
   * @returns 
   */
  public getIntegration(source: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    }
    return makeCall("get", `integrations/${source}`, data);
  }


}