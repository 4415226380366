import { makeCall } from "./client";
import moment from "moment";

export interface IMessage {
  id: number;
  replyToId: number;
  fromId: number;
  toId: number;
  subject: string;
  body: string;
  createdOn: moment.Moment;
  sendDate: moment.Moment;
  deliveredOn: moment.Moment;
  status: "pending" | "sent" | "error" | "viewed" | "replied" | "hidden";

  fromTitle: string;
  fromFirstName: string;
  fromLastName: string;
  fromEmail: string;
  fromCell: string;
  toTitle: string;
  toFirstName: string;
  toLastName: string;
  toEmail: string;
  toCell: string;
}

export class MessagesAPI {

  /**
   * Get the messages with a sendDate in a time window
   * @param start 
   * @param end 
   * @param otherData 
   */
  public getMessages(start: string, end: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end
    };
    return makeCall("get", `messages`, data);
  }

  /**
   * Create a new message
   * @param data 
   */
  public createMessage(data: any = {}): Promise<any> {
    return makeCall("post", `messages`, data);
  }

  /**
   * Update a message that is still pending
   * @param messageId 
   * @param data 
   */
  public updateMessage(messageId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `messages/${messageId}`, data);
  }

  /**
   * Delete a message that is still pending
   * @param messageId 
   * @param data 
   */
  public deleteMessage(messageId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `messages/${messageId}`, data);
  }

  /**
   * Allows a non-user to view a received message with a code
   * @param messageId 
   * @param code 
   * @param otherData 
   */
  public viewMessage(messageId: number, code: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      code
    };
    return makeCall("post", `messages/${messageId}/view`, data);
  }

}