import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";

import { Translator } from "../../../utils/translator";
import Card from "../../structure/Card";
import DatePicker from "../../structure/DatePicker";
import { success, error } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { ExportsAPI, QuotaAPI } from "../../../api";
import { IExport, IExportHistory } from "../../../api/exports";

import {SubscriptionBlock} from "src/components/structure/SubscriptionBlock";

import ExportListItem from "./ExportListItem";
import ExportsHistoryItem from "./ExportsHistoryItem";

const exportsListHelpText = Translator.getHelpText("en", "exportsList");
const sentExports = Translator.getHelpText("en", "exportsSent");

const isDemo = process.env.REACT_APP_ENV === "demo";

interface IExportsScreenProps {
  appActions: any;
  history: any;
}

interface IExportsScreenState {
  loading: boolean;
  showCreateModal: boolean;
  exports: IExport[];
  exportHistory: IExportHistory[];

  newExportType: "activities" | "contacts";
  newAdditionalEmails: string;
  newFrequency: "weekly" | "endOfMonth" | "daily" | "single";
  newNextSend: moment.Moment;
  newStartTime: moment.Moment;
  newEndTime: moment.Moment;

  isSubscribed: boolean;
}

class ExportsScreen extends React.Component<IExportsScreenProps, IExportsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showCreateModal: false,
      exports: [],
      exportHistory: [],

      newExportType: "activities",
      newAdditionalEmails: "",
      newFrequency: "weekly",
      newNextSend: moment().add(1, "days"),
      newStartTime: moment().subtract(1, "month"),
      newEndTime: moment().add(1, "days"),

      isSubscribed: false,
    };

    this.fetchQuota = this.fetchQuota.bind(this);
    this.fetchExports = this.fetchExports.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateNextSend = this.updateNextSend.bind(this);
    this.updateStartTime = this.updateStartTime.bind(this);
    this.updateEndTime = this.updateEndTime.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.createExport = this.createExport.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount(){
    this.fetchQuota();
    this.fetchExports();
  }

  public render() {
    if(!this.state.isSubscribed){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <SubscriptionBlock featureDisplay="Scheduled Exports" />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <Card title="Scheduled Exports" loading={this.state.loading} help={exportsListHelpText}>
              <div className="row list-row">
                <div className="col-lg-2 col-sm-12">
                  <strong>Next Send</strong>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <strong>Frequency</strong>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <strong>Export Type</strong>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <strong>Sending To</strong>
                </div>
                <div className="col-lg-2 col-sm-12" />
              </div>
              {this.state.exports.length === 0 && (<strong>No Exports are scheduled</strong>)}
              {this.state.exports.map((item: IExport) => {
                if(item.frequency === "single"){
                  return null;
                }
                return (
                  <ExportListItem
                    key={item.id}
                    item={item}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete} />
                );
              })}
              <div className="row" style={{marginTop: 20}}>
                <div className="col-12">
                  {isDemo ? (
                    <button className="btn btn-primary btn-block" disabled={true}>Not Available in the Demo</button>
                  ): (
                    <button className="btn btn-primary btn-block" onClick={this.toggleCreateModal}>New</button>
                  )}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-md-4">
            <Card title="History" loading={this.state.loading} help={sentExports}>
              {this.state.exportHistory.length === 0 && (<strong>No exports sent</strong>)}
              {this.state.exportHistory.map((item: IExportHistory) => {
                return (
                  <ExportsHistoryItem key={item.id} history={item} />
                )
              })}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Export</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              By default, all exports are sent to you as well as any additional emails. Exports that are sent only to you and are not scheduled do not count against your quota.
            </div>
            <div className="form-group">
              <label>Also send To This Email Address:</label>
              <input type="text" className="form-control" id="newAdditionalEmails" value={this.state.newAdditionalEmails} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Frequency</label>
              <select className="form-control" id="newFrequency" value={this.state.newFrequency} onChange={this.updateField}>
                <option value="single">Send Immediately</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="endOfMonth">End Of Month</option>
              </select>
            </div>
            <div className="form-group">
              <label>Send My</label>
              <select className="form-control" id="newExportType" value={this.state.newExportType} onChange={this.updateField}>
                <option value="activities">Activities</option>
                <option value="contacts">Contacts</option>
              </select>
            </div>
            {this.state.newFrequency === "single" && this.state.newExportType === "activities" && (
              <div>
                <div className="form-group">
                  <label>Activities Between</label>
                  <DatePicker
                    key={this.state.newStartTime.format("YYYY-MM-DD")}
                    date={this.state.newStartTime}
                    onDateSaved={this.updateStartTime}
                  />
                </div>
                <div className="form-group">
                  <label>And</label>
                  <DatePicker
                    key={this.state.newEndTime.format("YYYY-MM-DD")}
                    date={this.state.newEndTime}
                    onDateSaved={this.updateEndTime}
                  />
                </div>
              </div>
            )}
            {this.state.newFrequency !== "single" && (
              <div className="form-group">
                <label>Next Send Date</label>
                <DatePicker
                  key={this.state.newNextSend.format("YYYY-MM-DD")}
                  date={this.state.newNextSend}
                  onDateSaved={this.updateNextSend}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createExport}>Create</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns: any = this.state;
    ns[id] = val;
    if(id === "newFrequency"){
      // we need to calculate a default
      if(val === "endOfMonth"){
        ns["newNextSend"] = moment().endOf("month");
      } else if(val === "weekly"){
        ns["newNextSend"] = moment().add(1, "day");
      } else if(val === "daily"){
        ns["newNextSend"] = moment().add(1, "day");
      } else if(val === "single"){
        ns["newNextSend"] = moment();
      }
    }
    this.setState(ns);
  }

  private updateNextSend(newDate: moment.Moment){
    this.setState({ newNextSend: newDate });
  }

  private updateStartTime(newDate: moment.Moment){
    this.setState({ newStartTime: newDate });
  }

  private updateEndTime(newDate: moment.Moment){
    this.setState({ newEndTime: newDate });
  }

  private fetchQuota(){
    this.setState({loading: true}, async () => {
      try{
        const res = await QuotaAPI.getUserQuota();
        this.setState({
          loading: false,
          isSubscribed: res.body.data.isSubscribed,
        });
      }catch(e){
        this.setState({loading: false});
      }
    });
  }


  private fetchExports(){
    this.setState({ loading: true }, async () => {
      try {
        const exportsRes = await ExportsAPI.getExports();
        const exports = exportsRes.body.data;
        const exportHistoryRes = await ExportsAPI.getExportHistoryForUser();
        const exportHistory = exportHistoryRes.body.data;

        // convert all of the dates to moment object
        for(const i of exports){
          i.createdOn = moment(i.createdOn);
          i.nextSend = moment(i.nextSend);
        }
        for(const i of exportHistory){
          i.sentOn = moment(i.sentOn);
        }
        this.setState({loading: false, exports, exportHistory});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private toggleCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private createExport(){
    const data: any = {
      exportType: this.state.newExportType.trim(),
      additionalEmails: this.state.newAdditionalEmails.trim(),
      frequency: this.state.newFrequency.trim(),
    };
    if(data.exportType === "" || data.frequency === "") {
      return error("All fields are required");
    }
    if(this.state.newNextSend.isBefore(moment().hour(0).minute(0).second(0))){
      return error("Next Send must be in the future");
    }
    data.nextSend = this.state.newNextSend.format("YYYY-MM-DD");
    if(this.state.newFrequency === "single"){
      data.sendNow = true;
    }
    data.startTime = this.state.newStartTime;
    data.endTime = this.state.newEndTime;
    this.setState({ loading: true }, async () => {
      try{
        await ExportsAPI.createExport(data);
        success("Export scheduled!");
        this.setState({ 
          loading: false, 
          showCreateModal: false,
          
          newExportType: "activities",
          newAdditionalEmails: "",
          newFrequency: "weekly",
          newNextSend: moment().add(1, "days"), 
        }, () => {
          this.fetchExports();
          this.fetchQuota();
        });
      }catch(err){
        error("Could not create that scheduled export.");
        this.setState({ loading: false });
      }
    });
  }


  private handleDelete(item: IExport){
    const exports = [];
    for(const i of this.state.exports){
      if(i.id !== item.id){
        exports.push(i);
      }
    }
    this.setState({ exports }, () => { this.fetchQuota(); });
  }

  private handleEdit(item: IExport){
    const exports = [];
    for(const i of this.state.exports){
      if(i.id === item.id){
        exports.push(item);
      } else {
        exports.push(i);
      }
    }
    this.setState({ exports }, () => { this.fetchQuota(); });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportsScreen) as any);