import * as React from "react";
import { ToolsAPI } from "src/api";
import { ITool } from "src/api/tools";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";

import SpiritualFitnessInventoryAnalysis from "./IndividualTools/spiritualFitnessInventoryAnalysis";

interface ITrendsAnalysisProps {
  clientId: number;
  availableCategories: string[];
  tools: ITool[];
}

interface ITrendsAnalysisState {
  loading: boolean;
  selectedCategory: string;
  selectedToolType: string;
  submissions: any;
}

export default class TrendsAnalysis extends React.Component<ITrendsAnalysisProps, ITrendsAnalysisState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      selectedCategory: "",
      selectedToolType: "",
      submissions: [],
    };

    this.updateField = this.updateField.bind(this);
    this.generateAnalysis = this.generateAnalysis.bind(this);
    this.getTool = this.getTool.bind(this);
  }

  public render() {
    return (
      <Screen fileName="TrendsAnalysis.tsx">
        <Card title="TrendsAnalysis" loading={this.state.loading} help="">
          <p><strong>Note:</strong> Not all tools support trends analysis. As always, ensure you understand the use and analysis of the tool and are trained on how to interpret the results of a tool.</p>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <label>Category</label>
              <select id="selectedCategory" className="form-control" value={this.state.selectedCategory} onChange={this.updateField}>
                <option value="">Select a Category</option>
                {this.props.availableCategories.map((cat) => {
                  return (
                    <option value={cat} key={cat}>{cat}</option>
                  )
                })}
              </select>
            </div>
            {this.state.selectedCategory !== "" && (
              <div className="col-lg-4 col-sm-6">
                <label>Tool</label>
                <select id="selectedToolType" className="form-control" value={this.state.selectedToolType} onChange={this.updateField}>
                  <option value="">Select a Tool</option>
                    {this.props.tools.map((tool) => {
                      if(tool.active && tool.category === this.state.selectedCategory && tool.trendsAnalysis !== "none"){
                        return <option key={tool.toolType} value={tool.toolType}>{tool.display}</option>
                      }
                      return null;
                    })}
                </select>
              </div>
            )}
            {this.state.selectedToolType !== "" && (
              <div className="col-lg-4 col-12">
                <button className="btn btn-block btn-primary" onClick={this.generateAnalysis} style={{marginTop: 32}}>Generate Analysis</button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              {this.state.selectedToolType !== "" && this.state.submissions.length !== 0 && (
                <div style={{marginTop: 20}}>{this.getTool()}</div>
              )}
              {this.state.selectedToolType !== "" && this.state.submissions.length === 0 && (
                <p />
              )}
            </div>
          </div>
        </Card>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private generateAnalysis(){
    this.setState({ loading: true }, async () => {
      try{
        const submissionsResult = await ToolsAPI.getClientSubmissionsForTool(this.state.selectedToolType, this.props.clientId);
        this.setState({ loading: false, submissions: submissionsResult.body.data });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private getTool(){
    switch(this.state.selectedToolType){
      case "spiritualFitnessInventory":
        return <SpiritualFitnessInventoryAnalysis clientId={this.props.clientId} submissions={this.state.submissions} />
      default:
        return null;
    }
  }

}