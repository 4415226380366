import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import Screen from "src/components/structure/Screen";
import { error } from "../../structure/Alert";
import { ContactsAPI } from "../../../api";
import * as ContactActions from "../../../reducers/contactsReducer";

import ContactNoteListItem from "./ContactNoteListItem";

interface IContactNotesProps {
  contactActions: any;
  contactState: any;
  contactId: number;
}

interface IContactNotesState {
  loading: boolean;

  notes: any,
  newNote: string;

  showCreateNoteModal: boolean;
}

class ContactNotes extends React.Component<IContactNotesProps, IContactNotesState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      notes: [],

      newNote: "",
      showCreateNoteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchContactNotes = this.fetchContactNotes.bind(this);
    this.createNote = this.createNote.bind(this);
    this.toggleCreateNoteModal = this.toggleCreateNoteModal.bind(this);

    this.onDeleteNote = this.onDeleteNote.bind(this);
    this.onEditNote = this.onEditNote.bind(this);
  }

  componentDidMount(){
    this.fetchContactNotes();
  }

  public render() {
    return (
      <Screen fileName="src/components/screens/Contacts/ContactNotes.tsx">
      <div className="row">
        <div className="col-12">
          <Card title="Notes" loading={this.state.loading} help="">
            {this.state.notes.length === 0 && (<strong>No notes have been created</strong>)}
            {this.state.notes.map((note: any) => {
              return (
                <ContactNoteListItem 
                  note={note} 
                  contactId={this.props.contactId}
                  key={note.id} 
                  onDelete={this.onDeleteNote} 
                  onUpdate={this.onEditNote}
                />
              );
            })}

            <button className="btn btn-block btn-primary" onClick={this.toggleCreateNoteModal}>New Note</button>
          </Card>
        </div>

        <Modal show={this.state.showCreateNoteModal} onHide={this.toggleCreateNoteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Notes</label>
              <textarea rows={4} className="form-control" id="newNote" value={this.state.newNote} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createNote}>Create</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateNoteModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
      </Screen>
    );
  }


  private updateField(e: any) {
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private fetchContactNotes(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await ContactsAPI.getContactNotes(this.props.contactId);
        this.setState({
          notes: result.body.data,
          loading: false,
        });
      }catch(err){
        // we don't need to do anything here
      }
    });
  }

  private toggleCreateNoteModal(){
    this.setState({ showCreateNoteModal: !this.state.showCreateNoteModal });
  }

  private createNote(){
    const data = {
      notes: this.state.newNote.trim(),
    };
    if(data.notes === ""){
      return error("Notes cannot be blank");
    }
    this.setState({ loading: true}, async () => {
      try{
        await ContactsAPI.createContactNote(this.props.contactId, data);
        this.setState({loading: false, newNote: "", showCreateNoteModal: false}, () => { this.fetchContactNotes(); });
      }catch(err){
        this.setState({loading: false, showCreateNoteModal: false});
        return error("Could not create that note");
      }
    });
  }

  private onDeleteNote(note: any){
    const notes: any = [];
    for(const n of this.state.notes){
      if(n.id !== note.id){
        notes.push(n);
      }
    }
    this.setState({notes});
  }

  private onEditNote(note: any){
    const notes: any = [];
    for(const n of this.state.notes){
      if(n.id === note.id){
        notes.push(note);
      } else {
        notes.push(n);
      }
    }
    this.setState({notes});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    contactState: s.contactsState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    contactActions: bindActionCreators(ContactActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactNotes);