import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { Translator } from "src/utils/translator";
import Card from "../../structure/Card";
import DatePicker from "../../structure/DatePicker";
import { success, error } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";

import { QuotaAPI, RemindersAPI } from "../../../api";
import {IReminder} from "../../../api/reminders";

import ReminderListItem from "./ReminderListItem";
import { SubscriptionBlock } from "src/components/structure/SubscriptionBlock";

const statsHelpText = Translator.getHelpText("en", "remindersStats");
const remindersHelpText = Translator.getHelpText("en", "remindersList");

interface IRemindersScreenProps {
  appActions: any;
  history: any;
}

interface IRemindersScreenState {
  loading: boolean;
  allItems: IReminder[];
  filteredItems: IReminder[];
  status: "all" | "open" | "closed";
  severity: "all" | "normal" | "severe" | "critical";
  frequency: "all" | "daily" | "weekly";

  newReminder: string;
  newNextReminder: moment.Moment;
  newSeverity: "all" | "normal" | "severe" | "critical";
  newFrequency: "all" | "daily" | "weekly";
  
  showCreateModal: boolean;
  isSubscribed: boolean;

}

class RemindersScreen extends React.Component<IRemindersScreenProps, IRemindersScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      severity: "all",
      status: "all",
      frequency: "all",
      allItems: [],
      filteredItems: [],


      newReminder: "",
      newNextReminder: moment().add(1, "weeks"),
      newSeverity: "normal",
      newFrequency: "weekly",

      showCreateModal: false,
      isSubscribed: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateNewNextReminder = this.updateNewNextReminder.bind(this);
    this.fetchQuota = this.fetchQuota.bind(this);
    this.fetchAll = this.fetchAll.bind(this)
    this.filter = this.filter.bind(this);

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.createItem = this.createItem.bind(this);

    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount(){
    this.fetchQuota();
  }

  public render() {
    if(!this.state.isSubscribed){
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <SubscriptionBlock featureDisplay="Reminders" />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <Card title="Stats" loading={this.state.loading} help={statsHelpText}>
              Normal: {this.state.allItems.reduce((acc, item) => { return acc + (item.severity === "normal" ?  1 : 0); }, 0)}<br />
              Severe: {this.state.allItems.reduce((acc, item) => { return acc + (item.severity === "severe" ?  1 : 0); }, 0)}<br />
              Critical: {this.state.allItems.reduce((acc, item) => { return acc + (item.severity === "critical" ?  1 : 0); }, 0)}<br />
              <hr /> 
              Open: {this.state.allItems.reduce((acc, item) => { return acc + (item.status === "open" ?  1 : 0); }, 0)}<br />
              Closed: {this.state.allItems.reduce((acc, item) => { return acc + (item.status === "closed" ?  1 : 0); }, 0)}<br />
            </Card>
          </div>
          <div className="col-lg-9 col-sm-12">
            <Card title="Reminders" loading={this.state.loading} help={remindersHelpText}>
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <label>Status</label>
                  <select className="form-control" id="status" value={this.state.status} onChange={this.updateField}>
                    <option value="all">Show: All</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Severity</label>
                  <select className="form-control" id="severity" value={this.state.severity} onChange={this.updateField}>
                    <option value="all">Show: All</option>
                    <option value="normal">Normal</option>
                    <option value="severe">Severe</option>
                    <option value="critical">Critical</option>
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Frequency</label>
                  <select className="form-control" id="frequency" value={this.state.frequency} onChange={this.updateField}>
                    <option value="all">Show: All</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <button className="btn btn-primary btn-block" onClick={this.toggleCreateModal} style={{marginTop: 32}}>New</button>
                </div>
              </div>
              <hr />
              {this.state.filteredItems.length === 0 && (<strong>No reminders match that criteria</strong>)}
              {this.state.filteredItems.map((item: IReminder) => {
                return (
                  <ReminderListItem
                    key={item.id}
                    reminder={item}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete} />
                );
              })}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Reminder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Reminder Text</label>
              <input type="text" className="form-control" id="newReminder" value={this.state.newReminder} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Severity</label>
              <select className="form-control" id="newSeverity" value={this.state.newSeverity} onChange={this.updateField}>
                <option value="normal">Normal</option>
                <option value="severe">Severe</option>
                <option value="critical">Critical</option>
              </select>
            </div>
            <div className="form-group">
              <label>Frequency</label>
              <select className="form-control" id="newFrequency" value={this.state.newFrequency} onChange={this.updateField}>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
            <div className="form-group">
              <label>Remind me next on</label>
              <DatePicker 
                date={this.state.newNextReminder}
                onDateSaved={this.updateNewNextReminder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createItem}>Create</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleCreateModal}>Cancel</button>
              </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }


  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "severity" || id === "status" || id === "frequency"){
        this.filter();
      }
    });
  }

  private updateNewNextReminder(newDate: moment.Moment){
    this.setState({ newNextReminder: newDate });
  }

  private fetchAll(){
    this.setState({ loading: true }, async () => {
      try {
        const itemsResult = await RemindersAPI.getReminders();
        const allItems = itemsResult.body.data;
        // convert all of the dates to moment object
        for(const i of allItems){
          i.dateAdded = moment(i.dateAdded);
          i.nextReminder = moment(i.nextReminder);
        }
        this.setState({loading: false, allItems}, () => {
          this.filter();
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private filter(){
    this.setState({loading: true}, () => {
      let filteredItems: IReminder[] = this.state.allItems;
      // check for frequency
      if(this.state.frequency !== "all"){
        filteredItems = filteredItems.filter((item) => {
          return item.frequency === this.state.frequency;
        });
      }
      // severity
      if(this.state.severity !== "all"){
        filteredItems = filteredItems.filter((item) => {
          return item.severity === this.state.severity;
        });
      }
      // status
      if(this.state.status !== "all"){
        filteredItems = filteredItems.filter((item) => {
          return item.status === this.state.status;
        });
      }
      this.setState({ loading: false, filteredItems});
    });
  }

  private toggleCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private createItem(){
    const data = {
      severity: this.state.newSeverity.trim(),
      frequency: this.state.newFrequency.trim(),
      reminder: this.state.newReminder.trim(),
      nextReminder: this.state.newNextReminder.format("YYYY-MM-DD"),
    }
    if(data.reminder === ""){
      return error("You must provide reminder details");
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await RemindersAPI.createReminder(data);
        // we need to add it to all, then run the filter
        const items = this.state.allItems;
        const item = result.body.data;
        item.dateAdded = moment(item.dateAdded);
        item.nextReminder = moment(item.nextReminder);
        items.push(item);
        success("Reminder added!");
        items.sort((a, b) => {
          return a.nextReminder > b.nextReminder ? -1 : 1;
        })
        this.setState({ 
          loading: false, 
          showCreateModal: false, 
          allItems: items,

          newReminder: "",
          newNextReminder: moment().add(1, "weeks"),
          newSeverity: "normal",
          newFrequency: "weekly"
        }, () => {
          this.filter();
        })
      }catch(err){
        this.setState({ loading: false, showCreateModal: false});
      }
    });
  }

  private handleDelete(item: IReminder){
    const allItems = [];
    for(const i of this.state.allItems){
      if(i.id !== item.id){
        allItems.push(i);
      }
    }
    this.setState({ allItems }, () => { this.filter(); });
  }

  private handleEdit(item: IReminder){
    const allItems = [];
    for(const i of this.state.allItems){
      if(i.id === item.id){
        allItems.push(item);
      } else {
        allItems.push(i);
      }
    }
    this.setState({ allItems }, () => { this.filter(); });
  }


  private fetchQuota(){
    this.setState({loading: true}, async () => {
      try{
        const res = await QuotaAPI.getUserQuota();
        this.setState({
          isSubscribed: res.body.data.isSubscribed,
        }, () => this.fetchAll());
      }catch(e){
        this.setState({loading: false}, () => this.fetchAll());
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemindersScreen) as any);