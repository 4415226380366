import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

const logo = "https://cdn.voithosapp.com/images/banner_transparent.png"

interface IMenuProps {
  appState: any;
  userState: any;
  appActions: any;
  userActions: any;
  history: any;
}

interface IMenuState {
  open: boolean;
}

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: any) {
    super(props);
    const defaultState: IMenuState = {
      open: props.open ? props.open : true,
    };
    this.state = defaultState;

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onTransition = this.onTransition.bind(this);
    this.logout = this.logout.bind(this);
  }
  
  public toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  public onTransition() {
    this.props.appActions.toggleMenu();
  }

  public logout(e: any) {
    e.preventDefault();
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
    this.props.appActions.toggleMenu();
  }

  public toggleDebug(){
    const newDebug = !this.props.appState.debug;
    this.props.appActions.setDebug(newDebug);
  }

  get header() {
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" /></div>
        {this.props.children}
      </div>
    );
  }
  get links() {
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" style={{width: "100%"}}/></div>
        <div style={styles.content}>
          <div style={styles.divider} />

          <Link to="/dashboard" style={styles.sidebarLink} onClick={this.onTransition} id="menu_dashboard">Dashboard</Link>
          <Link to="/contacts" style={styles.sidebarLink} onClick={this.onTransition} id="menu_contacts">Contacts</Link>
          <Link to="/activities" style={styles.sidebarLink} onClick={this.onTransition} id="menu_activities">Activities</Link>
          <Link to="/reports" style={styles.sidebarLink} onClick={this.onTransition} id="menu_reports">Reports</Link>
          <Link to="/messages" style={styles.sidebarLink} onClick={this.onTransition} id="menu_messages">Messages</Link>
          <Link to="/reminders" style={styles.sidebarLink} onClick={this.onTransition} id="menu_reminders">Reminders</Link>
          <Link to="/verbatims" style={styles.sidebarLink} onClick={this.onTransition} id="menu_verbatims">Verbatims</Link>
          <Link to="/milestones" style={styles.sidebarLink} onClick={this.onTransition} id="menu_milestones">Milestones</Link>
          <Link to="/exports" style={styles.sidebarLink} onClick={this.onTransition} id="menu_exports">Scheduled Exports</Link>
          <Link to="/imports" style={styles.sidebarLink} onClick={this.onTransition} id="menu_imports">Import Data</Link>
          <Link to="/integrations" style={styles.sidebarLink} onClick={this.onTransition} id="menu_integrations">Integrations</Link>
          <Link to="/connections" style={styles.sidebarLink} onClick={this.onTransition} id="menu_connections">Connections</Link>
          <Link to="/page/settings" style={styles.sidebarLink} onClick={this.onTransition} id="menu_page_settings">Page</Link>

          <div style={styles.divider} />

          <span style={styles.linkHeader}>Account</span>
          <Link to="/me" style={styles.sidebarLink} onClick={this.onTransition} id="menu_profile">My Profile</Link>
          <Link to="/subscription" style={styles.sidebarLink} onClick={this.onTransition} id="menu_subscriptions">My Subscription</Link>
          <Link to="/payments" style={styles.sidebarLink} onClick={this.onTransition} id="menu_payments">Payment History</Link>
          <Link to="/branding" style={styles.sidebarLink} onClick={this.onTransition} id="menu_branding">My Links</Link>
          <Link to="/referrals" style={styles.sidebarLink} onClick={this.onTransition} id="menu_referrals">My Referrals</Link>
          <Link to="#" style={styles.sidebarLink} onClick={this.logout} id="menu_logout">Logout</Link>

          {this.props.userState && this.props.userState.loggedIn && this.props.userState.user.systemRole === "admin" && 
            <div>
              <div style={styles.divider} />
              <Link to="#" style={styles.sidebarLink} onClick={this.toggleDebug} id="menu_logout">Toggle Debug {this.props.appState.debug ? "Off" : "On"}</Link>
            </div>

          }
        </div>
      </div>
    );
  }

  public render() {
    return (
      <Sidebar
        sidebar={this.links}
        open={this.props.appState.showMenu}
        onSetOpen={this.onTransition}>
        {this.props.children}
      </Sidebar>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Menu));


const styles: any = {
  sidebar: {
    width: 256,
    height: "100%",
  },
  sidebarLink: {
    display: "block",
    paddingLeft: "8px",
    paddingTop: "2px",
    color: "#757575",
    textDecoration: "none",
  },
  linkHeader: {
    display: "block",
    paddingTop: "12px",
    color: "#757575",
    textDecoration: "none",
    fontWeight: "bold"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#e0e0e0",
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "#f2f2f2",
  },
  root: {
    fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: 300,
  },
  header: {
    backgroundColor: "#f2f2f2",
    color: "white",
    padding: "16px",
    fontSize: "1.5em",
    width: "100%"
  },
};