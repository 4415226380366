import * as React from "react";


interface IPasswordInputProps {
  value: string;
  id: string;
  onChange: any;
}

interface IPasswordInputState {
  showPassword: boolean;
}

export class PasswordInput extends React.Component<IPasswordInputProps, IPasswordInputState> {

  constructor(props: any){
    super(props);
    this.state = {
      showPassword: false,
    };
    this.toggle = this.toggle.bind(this);
  }


  public render() {
    return (
      <div className="input-group">
        <input className="form-control" type={this.state.showPassword ? "text" : "password"} id={this.props.id} value={this.props.value} onChange={this.props.onChange} />
        <div className="input-group-addon password-view-toggle-container" onClick={this.toggle}>
          <i className={`fas ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"} password-view-toggle-icon`} aria-hidden={true} />
        </div>
      </div>
    );
  }

  private toggle(){
    this.setState({showPassword: !this.state.showPassword});
  }
}