import { makeCall } from "./client";
import moment from "moment";

export interface IActivity {
  id: number;
  name: string;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  participantCount: number;
  lengthInMinutes: number;
  dateOccurred: moment.Moment;
  category: string;
  notes: string;
  tags: string[];
  followUp: "none" | "active" | "completed";
  followUpOn: moment.Moment;
  lastFollowedUpOn: moment.Moment;

  // this is used for bulk creating
  errorMessage?: string;
}

export const ActivityBlank: IActivity = {
  id: 0,
  name: "",
  createdOn: "",
  updatedOn: "",
  createdBy: 0,
  participantCount: 0,
  lengthInMinutes: 0,
  dateOccurred: moment(),
  category: "",
  notes: "",
  tags: [],
  followUp: "none",
  followUpOn: moment().add(7, "days"),
  lastFollowedUpOn: moment(),
  errorMessage: "",
}

export class ActivitiesAPI {

  /**
   * Get the activity categories
   */
  public getActivityCategories(): Promise<any> {
    return makeCall("get", "activities/categories");
  }

  /**
   * Gets the activities for a user, optionally filtered by category, start, and end
   * @param category 
   */
  public getActivities(category: string = "", start: string = "", end: string = "", otherData: any = {}): Promise<any> {
    const data: any = {
      ...otherData
    };
    if(category !== ""){
      data.category = category;
    }
    if(start !== ""){
      data.start = start;
    }
    if(end !== ""){
      data.end = end;
    }
    return makeCall("get", "activities", data);
  }

  /**
   * Creates a new activity
   * @param name 
   * @param otherData 
   */
  public createActivity(name: string, otherData = {}): Promise<any> {
    const data = {
      ...otherData,
      name
    };
    return makeCall("post", "activities", data);
  }

  /**
   * Updates a single activity
   * @param activityId 
   * @param otherData 
   */
  public updateActivity(activityId: number, otherData = {}): Promise<any> {
    return makeCall("patch", `activities/${activityId}`, otherData);
  }

  /**
   * Deletes a single activity
   * @param activityId 
   * @param otherData 
   */
  public deleteActivity(activityId: number, otherData = {}): Promise<any> {
    return makeCall("delete", `activities/${activityId}`, otherData);
  }


  public addContactToActivity(activityId: number, contactId: number, otherData = {}): Promise<any> {
    return makeCall("put", `activities/${activityId}/contacts/${contactId}`, otherData);
  }

  public removeContactFromActivity(activityId: number, contactId: number, otherData = {}): Promise<any> {
    return makeCall("delete", `activities/${activityId}/contacts/${contactId}`, otherData);
  }

  public removeAllContactsFromActivity(activityId: number, otherData = {}): Promise<any> {
    return makeCall("delete", `activities/${activityId}/contacts`, otherData);
  }

  public getAllContactsOnActivity(activityId: number, otherData = {}): Promise<any> {
    return makeCall("get", `activities/${activityId}/contacts`, otherData);
  }

  public getAllActivitiesForContact(contactId: number, otherData = {}): Promise<any> {
    return makeCall("get", `contacts/${contactId}/activities`, otherData);
  }

  /**
   * A helper function to translate the server-side enumeration for a category
   * @param input 
   */
  public translateCategory(input: string): string {
    switch(input){
      case "general":
        return "General"; 
      case "other":
        return "Other"; 
      case  "religious_service":
        return "Religious Service"; 
      case  "service":
        return "Service"; 
      case  "counseling":
        return "Counseling"; 
      case  "presentation":
        return "Presentation"; 
      case  "visit":
        return "Visit"; 
      case  "crisis_intervention":
        return "Crisis Intervention"; 
      case  "suicide_intervention":
        return "Suicide Intervention"; 
      case  "training":
        return "Training";
      case  "session":
        return "Client Session";
    }
    return "";
  }

}