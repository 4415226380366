import { makeCall } from "./client";
import moment from "moment";

export interface IExport {
  id: number;
  userId: number;
  createdOn: moment.Moment;
  exportType: "activities" | "contacts";
  additionalEmails: string;
  frequency: "weekly" | "endOfMonth" | "daily" | "single";
  nextSend: moment.Moment;
  sendNow: boolean;
  startTime?: moment.Moment;
  endTime?: moment.Moment;
}

export interface IExportHistory {
  id: number;
  userId: number;
  exportId: number;
  sentOn: moment.Moment;
  sentTo: string;
  frequency: "weekly" | "endOfMonth" | "daily" | "single";
  exportType: "activities" | "contacts";
}

export const ExportHistoryBlank: IExportHistory = {
  id: 0,
  userId: 0,
  exportId: 0,
  sentOn: moment(),
  sentTo: "unknown",
  frequency: "weekly",
  exportType: "activities",
}

export class ExportsAPI {

  public translateFrequency(frequency: string, capitalize: boolean): string {
    switch(frequency){
      case "daily":
        if(capitalize){
          return "Daily";
        }
        return "daily";
      case "weekly":
        if(capitalize){
          return "Weekly";
        }
        return "weekly";
      case "endOfMonth":
        if(capitalize){
          return "End of Month";
        }
        return "end of month";
      case "single":
        if(capitalize){
          return "One Time";
        }
        return "one time";
      default:
        return frequency;
    }
  }

  public getExports(otherData: any = {}): Promise<any> {
    return makeCall("get", `exports`, otherData);
  }

  public createExport(otherData: any = {}): Promise<any> {
    return makeCall("post", `exports`, otherData);
  }

  public getExportHistoryForUser(otherData: any = {}): Promise<any> {
    return makeCall("get", `exports/history`, otherData);
  }

  public getExportById(exportId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `exports/${exportId}`, otherData);
  }

  public updateExport(exportId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `exports/${exportId}`, otherData);
  }

  public deleteExport(exportId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `exports/${exportId}`, otherData);
  }

  public getExportHistory(exportId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `exports/${exportId}/history`, otherData);
  }
}