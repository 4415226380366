import * as React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import DatePicker from "src/components/structure/DatePicker";
import ContactSearch from "src/components/structure/ContactSearch";
import { ActivitiesAPI } from "src/api";
import { error, success } from "src/components/structure/Alert";
import { IActivity } from "src/api/activities";
import { IContact } from "src/api/contacts";

interface ISingleActivityFormProps {
  show: boolean;
  mode: "create" | "update";
  initialContact?: IContact;
  initialActivity?: any;
  switchToBulk?: any;
  onSaveActivity: any;
  onToggleForm: any;
  activityCategories: any[];
}

interface ISingleActivityFormState {
  loading: boolean;
  name: string,
  participantCount: string;
  lengthInMinutes: string;
  dateOccurred: moment.Moment;
  category: string;
  notes: string;
  tags: string;
  followUp: "none" | "active" | "completed";
  followUpOn: moment.Moment;
  contacts: any[];
}

export default class SingleActivityForm extends React.Component<ISingleActivityFormProps, ISingleActivityFormState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      participantCount: "0",
      lengthInMinutes: "0",
      dateOccurred: moment(),
      category: "General",
      notes: "",
      tags: "",
      followUp: "none",
      followUpOn: moment().add(1, "weeks"),
      contacts: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateDateOccurred = this.updateDateOccurred.bind(this);
    this.updateFollowUpOn = this.updateFollowUpOn.bind(this);
    this.updateContactsOnActivity = this.updateContactsOnActivity.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.toggleSingleActivityModal = this.toggleSingleActivityModal.bind(this);
    this.fetchContactsOnActivity = this.fetchContactsOnActivity.bind(this);
  }

  componentDidMount() {
    if (this.props.mode === "update") {
      this.fetchContactsOnActivity();
    } else if(this.props.initialContact && this.props.initialContact.id !== 0){
      // we need to see the initial contact
      this.setState({ contacts: [this.props.initialContact] })
    }
  }

  public render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.toggleSingleActivityModal} dialogClassName="modal-x-large" >
          <Modal.Header closeButton={true}>
            <Modal.Title>Save Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ?
              (
                <div style={{ textAlign: "center" }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) :
              (
                <div>
                  { this.props.mode === "create" && !this.props.initialContact && (<div className="row">
                    <div className="col-2 offset-10 d-none d-md-block">
                      <button className="btn-block btn-primary" onClick={this.props.switchToBulk}>Add Several Activities</button>
                    </div>
                  </div>)}
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.updateField} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Date Occurred</label>
                        <DatePicker
                          date={this.state.dateOccurred}
                          onDateSaved={this.updateDateOccurred}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Category</label>
                        <select className="form-control" id="category" value={this.state.category} onChange={this.updateField}>
                          {this.props.activityCategories.map((cat: any) => {
                            return (<option key={cat} value={cat}>{ActivitiesAPI.translateCategory(cat)}</option>)
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Length in Minutes</label>
                        <input type="text" className="form-control" id="lengthInMinutes" value={this.state.lengthInMinutes} onChange={this.updateField} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Tags (Separate by Comma)</label>
                        <input type="text" className="form-control" id="tags" value={this.state.tags} onChange={this.updateField} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Number of Participants</label>
                        <input type="text" className="form-control" id="participantCount" value={this.state.participantCount} onChange={this.updateField} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Notes</label>
                        <textarea className="form-control" id="notes" value={this.state.notes} onChange={this.updateField} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Follow Up Needed</label>
                        <select className="form-control" id="followUp" value={this.state.followUp} onChange={this.updateField}>
                          <option value="none">No</option>
                          <option value="active">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Add Contact to Activity</label>
                        <ContactSearch
                          onSelect={this.updateContactsOnActivity}
                          selectedContacts={this.state.contacts}
                          multiple={true}
                        />
                        <p className="small"><strong>Note:</strong> the fact that a contact is attached to an activty cannot be obfuscated through encryption.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      {this.state.followUp === "active" && (
                        <div className="form-group">
                          <label>Follow Up On</label>
                          <DatePicker
                            date={this.state.followUpOn}
                            onDateSaved={this.updateFollowUpOn}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

          </Modal.Body>
          <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.saveActivity}>Save</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleSingleActivityModal}>Cancel</button>
              </div>            
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private updateDateOccurred(newDate: moment.Moment) {
    this.setState({ dateOccurred: newDate });
  }

  private updateFollowUpOn(newDate: moment.Moment) {
    this.setState({ followUpOn: newDate });
  }

  private updateContactsOnActivity(contacts: any[]) {
    this.setState({ contacts: contacts });
  }

  private toggleSingleActivityModal() {
    this.props.onToggleForm();
  }

  private fetchContactsOnActivity() {
    this.setState({ loading: true }, async () => {
      let contacts: any[] = [];
      let tags: string = this.props.initialActivity.tags.join(', ');
      try {
        const cRes = await ActivitiesAPI.getAllContactsOnActivity(this.props.initialActivity.id);
        contacts = cRes.body.data;
      } catch (err) { }
      finally {
        const initialActivity = this.props.initialActivity;
        if(initialActivity){
          if(initialActivity.followUp === "none"){
            initialActivity.followUpOn = moment().add(1, "weeks");
          }
        }
        this.setState({
          ...initialActivity,
          contacts,
          tags,
          dateOccurred: moment(this.props.initialActivity.dateOccurred),
          followUpOn: moment(this.props.initialActivity.followUpOn),
          loading: false,
        });
      }
    });
  }

  private async saveActivity() {
    if (this.state.name.trim() === "") {
      return error("Activity name cannot be blank");
    }
    const participantCount = parseInt(this.state.participantCount, 10);
    if (isNaN(participantCount)) {
      return error("Please provide a valid number for the participant count");
    }
    const lengthInMinutes = parseInt(this.state.lengthInMinutes, 10);
    if (isNaN(lengthInMinutes)) {
      return error("Please provide a valid number for the event length in minutes");
    }
    const tags = this.state.tags.split(",");
    this.setState({ loading: true }, async () => {
      const data = {
        participantCount,
        lengthInMinutes,
        category: this.state.category,
        notes: this.state.notes,
        tags,
        dateOccurred: this.state.dateOccurred.format("YYYY-MM-DD"),
        followUp: this.state.followUp,
        name: this.state.name,
      }
      if (this.state.followUp === "active") {
        data["followUpOn"] = this.state.followUpOn.format("YYYY-MM-DD");
      }
      try {
        // split on create / update
        let actResult: any = {};
        if (this.props.mode === "create") {
          actResult = await ActivitiesAPI.createActivity(this.state.name.trim(), data);
        } else {
          actResult = await ActivitiesAPI.updateActivity(this.props.initialActivity.id, data);
          await ActivitiesAPI.removeAllContactsFromActivity(this.props.initialActivity.id);
        }
        const activity: IActivity = actResult.body.data;
        activity.lastFollowedUpOn = moment(activity.lastFollowedUpOn);
        activity.followUpOn = moment(activity.followUpOn);
        activity.dateOccurred = moment(activity.dateOccurred);
        success("Saved that activity!");
        // now loop and add any contacts
        for (const c of this.state.contacts) {
          await ActivitiesAPI.addContactToActivity(activity.id, c.id);
        }

        // we only want to clear out the data IF this is a new one
        this.props.onSaveActivity(activity);
        if(this.props.mode === "create"){
          this.setState({
            loading: false,
            name: "",
            category: this.props.activityCategories[0],
            lengthInMinutes: "0",
            notes: "",
            participantCount: "0",
            tags: "",
          });
        } else {
          this.setState({ loading: false });
        }
      } catch (e) {
        // ?????
        error("Could not save that activity. Please try again later");
        this.setState({ loading: false });
      }
    });
  }
}
