import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { IVerbatimMetaData } from "src/api/verbatims";
import { error, success } from "src/components/structure/Alert";
import { VerbatimsAPI } from "src/api";
import { Modal } from "react-bootstrap";
import { Translator } from "src/utils/translator";

interface IVerbatimMetaDataEditProps {
  appActions: any;
  verbatimId: number;
  metadata: IVerbatimMetaData[];
  loading: boolean;
  onDelete: any;
  onCreate: any;
  onUpdate: any;
}

interface IVerbatimMetaDataEditState {
  loading: boolean;
  field: string;
  value: string;
  showEditModal: boolean;
  showDeleteModal: boolean;
  showCreateModal: boolean;
}

const metaHelp = Translator.getHelpText("en", "verbatimMetadata");

class VerbatimMetaDataEdit extends React.Component<IVerbatimMetaDataEditProps, IVerbatimMetaDataEditState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      field: "",
      value: "",
      showCreateModal: false,
      showDeleteModal: false,
      showEditModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);

    this.createMetadata = this.createMetadata.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
    this.deleteField = this.deleteField.bind(this);
  }

  public render() {
    return (
      <div>

        <Card title="Metadata" loading={this.state.loading} help={metaHelp}>
          {this.props.metadata.length === 0 && (<strong>No metadata values have been assigned to this verbatim.</strong>)}
          {this.props.metadata.map((m) => {
              return(
                <div className="row" key={m.field}>
                  <div className="col-md-8">
                    {m.field}
                  </div>
                  <div className="col-md-1">
                    <span className="oi oi-pencil icon icon-primary" title="Edit" onClick={() => {
                      this.setState({field: m.field, value: m.value}, () => {
                        this.toggleShowEditModal();
                      })
                    }} />
                  </div>
                  <div className="col-md-1">
                    <span className="oi oi-x icon icon-danger" title="Delete" onClick={() => {
                      this.setState({field: m.field, value: m.value}, () => {
                        this.toggleShowDeleteModal();
                      })
                    }}
                    />
                  </div>
                </div>
              );
            })}
          <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateModal}>Create</button>
        </Card>

      <Modal show={this.state.showCreateModal} onHide={this.toggleShowCreateModal} >
        <Modal.Header closeButton={true}>
          <Modal.Title>Create Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="field">Field</label>
            <input type="text" id="field" value={this.state.field} onChange={this.updateField} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="value">Value</label>
            <input type="text" id="value" value={this.state.value} onChange={this.updateField} className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.createMetadata}>Create Metadata</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowCreateModal}>Cancel</button>
              </div>
        </Modal.Footer>
      </Modal>

      

      <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal} >
        <Modal.Header closeButton={true}>
          <Modal.Title>Update Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="field">Field</label>
            <input type="text" disabled={true} id="field" value={this.state.field} onChange={this.updateField} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="value">Value</label>
            <input type="text" id="value" value={this.state.value} onChange={this.updateField} className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-primary" onClick={this.updateMetadata}>Update Metadata</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowEditModal}>Cancel</button>
              </div>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} >
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <p>Are you absolutely sure you want to delete {`${this.state.field}`}? This cannot be
            undone.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
              <div className="col-6">
                <button className="btn btn-block btn-danger" onClick={this.deleteField}>Delete Field</button>
              </div>
              <div className="col-6">
                <button className="btn btn-block btn-info" onClick={this.toggleShowDeleteModal}>Cancel</button>
              </div>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleShowCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private createMetadata(){
    const field = this.state.field;
    const value = this.state.value;
    if(field === "" || value === ""){
      return error("Field and Value are required.");
    }
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.saveVerbatimMetadata(this.props.verbatimId, field, value);
        success("Field created!");
        this.setState({loading: false, field: "", value: "", showCreateModal: false });
        this.props.onCreate(field, value);
      }catch(err){
        error("Could not create that field.");
        this.setState({ loading: false });
      }
    });
  }

  private updateMetadata(){
    const field = this.state.field;
    const value = this.state.value;
    if(field === "" || value === ""){
      return error("Field and Value are required.");
    }
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.saveVerbatimMetadata(this.props.verbatimId, field, value);
        success("Field updated!");
        this.setState({loading: false, field: "", value: "", showEditModal: false });
        this.props.onUpdate(field, value);
      }catch(err){
        error("Could not update that field.");
        this.setState({ loading: false });
      }
    });
  }

  private deleteField(){
    const field = this.state.field;
    this.setState({ loading: true }, async () => {
      try{
        await VerbatimsAPI.deleteVerbatimMetadata(this.props.verbatimId, field);
        success("Field deleted!");
        this.setState({loading: false, field: "", value: "", showDeleteModal: false });
        this.props.onDelete(field);
      }catch(err){
        error("Could not delete that field.");
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerbatimMetaDataEdit);