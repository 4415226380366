import { makeCall } from "./client";
import moment from "moment";

export interface IJob {
  id: number;
  userId: number;
  jobType: "import" | "export";
  created: moment.Moment;
  lastUpdated: moment.Moment;
  status: "pending" | "need_approval" | "approved" | "completed" | "error";
  statusCode: string;
  statusMessage: string;
}

export const JobBlank: IJob = {
  id: 0,
  userId: 0,
  jobType: "import",
  created: moment(),
  lastUpdated: moment(),
  status: "pending",
  statusCode: "",
  statusMessage: "",
}

export class JobsAPI {

  public translateJobStatus(status: string): string {
    switch(status){
      case "completed":
        return "Completed";
      case "error":
        return "Error"
      case "approved":
        return "Approved - Pending";
      case "needs_approval":
        return "Needs Approval";
      case "pending":
      default:
        return "Pending"
    }
  }

  /**
   * Used when we want to intercept the status code for our own message
   * @param statusCode 
   * @param statusMessage 
   */
  public translateJobStatusCodeToMessage(statusCode: string, statusMessage: string): string {
    switch(statusCode){
      case "data_error":
        return `There was something wrong with your data. Specifically, the uploader reported: ${statusMessage}. You will need to check your data and try again, or contact support if you believe this is an error with the application.`;
      case "quota_exceeded":
        return "Processing this import would have caused you to exceed your current quota. You may upgrade your plan and retry or contact support."
    }
    return statusMessage;
  }

  public getJobs(jobType: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      jobType,
    }
    return makeCall("get", `jobs/`, data);
  }
}