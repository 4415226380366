import moment from "moment";
import { makeCall } from "./client";

export class ToolsAPI {

  /**
   * Get a list of the available tools
   * @param data 
   * @param options 
   * @returns 
   */
  public getTools(data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", "tools", data, options);
  }


  public getSharedToolSubmissions(data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", "tools/submissions/shared", data, options);
  }

  /**
   * Get the tool's information
   * @param toolType 
   * @param data 
   * @param options 
   * @returns 
   */
  public getToolInformation(toolType: string, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `tools/${toolType}`, data, options);
  }

  /**
   * Get all tool submissions for a user
   * @param data 
   * @param options 
   * @returns 
   */
  public getSubmissions(data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `tools/submissions`, data, options);
  }

  /**
   * Get the submissions for a tool type
   * @param toolType 
   * @param data 
   * @param options 
   * @returns 
   */
  public getSubmissionsForTool(toolType: string, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `tools/${toolType}/submissions`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param clientId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getClientSubmissionsForTool(toolType: string, clientId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `/tools/client/${clientId}/submissions/${toolType}`, data, options);
  }

  //"/tools/client/{clientID}/submissions/{toolType}
  /**
   * Submit a tool
   * @param toolType 
   * @param data 
   * @param options 
   * @returns 
   */
  public submitNewSubmission(toolType: string, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("post", `tools/${toolType}/submissions`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param submissionId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getSubmissionById(toolType: string, submissionId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `tools/${toolType}/submissions/${submissionId}`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param submissionId 
   * @param data 
   * @param options 
   * @returns 
   */
  public deleteSubmissionById(toolType: string, submissionId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("delete", `tools/${toolType}/submissions/${submissionId}`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param submissionId 
   * @param providerId 
   * @param data 
   * @param options 
   * @returns 
   */
  public shareASubmission(toolType: string, submissionId: number, providerId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("post", `tools/${toolType}/submissions/${submissionId}/share/provider/${providerId}`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param submissionId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getSubmissionShares(toolType: string, submissionId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `tools/${toolType}/submissions/${submissionId}/shares`, data, options);
  }

  /**
   * 
   * @param data 
   * @param options 
   * @returns 
   */
  public getAllSubmissionSharesForClient(clientId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `/tools/client/${clientId}/submissions/shared`, data, options);
  }

  /**
   * 
   * @param clientId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getRequests(clientId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `/tools/client/${clientId}/requests`, data, options);
  }

  /**
   * 
   * @param clientId 
   * @param requestId 
   * @param data 
   * @param options 
   * @returns 
   */
  public deleteRequest(clientId: number, requestId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("delete", `/tools/client/${clientId}/requests/${requestId}`, data, options);
  }

  /**
   * 
   * @param toolType 
   * @param clientId 
   * @param data 
   * @param options 
   * @returns 
   */
  public requestSubmission(toolType: string, clientId: number,  data: any = {}, options: any = {}): Promise<any> {
    return makeCall("post", `/tools/${toolType}/request/client/${clientId}`, data, options);
  }


  public translateToolTypeToDisplay(input: string, tools: ITool[] = []): string {
    if(tools.length > 0){
      for(const t of tools){
        if(t.toolType === input){
          return t.display;
        }
      }
    }
    switch(input){
      case "spiritualFitnessInventory":
        return "Spiritual Fitness Inventory";
      case "fiveColumnThoughtRecord":
        return "5 Column Thought Record";
      case "therapyAssessment":
        return "General Assessment";
      default:
        return input;
    }
  }

}

export interface ITool {
  active: boolean;
  category: "Spiritual Fitness" | "CBT" | "";
  description: string;
  display: string;
  toolType: "fiveColumnThoughtRecord" | "spiritualFitnessInventory" | "";
  trendsAnalysis: "full" | "partial" | "none"
}

export const BlankTool: ITool = {
  active: true,
  category: "",
  description: "",
  display: "",
  toolType: "",
  trendsAnalysis: "none",
}

export interface IToolShare {
  creatorFirstName: "",
  creatorId: 0,
  creatorLastName: "",
  role: "",
  sharedOn: moment.Moment,
  submissionId: 0
  submittedOn: moment.Moment,
  toolType: "",
  userFirstName: "",
  userId: 0,
  userLastName: "",
}

export interface IToolSubmission {
  creatorId: number;
  headline: string;
  id: number;
  notes: string;
  submittedOn: moment.Moment;
  toolSubmissionId: number;
  toolType: string;
}

export interface IToolRequest {
  dueOn: moment.Moment;
  id: number;
  providerFirstName: string;
  providerId: number;
  providerLastName: string;
  requestedOn: moment.Moment;
  scheduleType: string;
  status: string;
  toolType: string;
  userFirstName: string;
  userId: number;
  userLastName: string;
}