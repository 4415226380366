import * as React from "react";
import { TimelineAPI } from "src/api";
import { ITimelineEvent } from "src/api/timeline";
import { Timeline, TimelineEvent } from "react-event-timeline";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import moment from "moment";
import DatePicker from "src/components/structure/DatePicker";

interface IClientTimelineProps {
  clientId: number;
}

interface IClientTimelineState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  events: ITimelineEvent[];
}

export default class ClientTimeline extends React.Component<IClientTimelineProps, IClientTimelineState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      start: moment().subtract(90, "days"),
      end: moment().add(1, "day"),
      events: [],
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Timeline.tsx">
        <Card title="Timeline" loading={this.state.loading} help="">
          <div className="row" style={{marginBottom: 20}}>
            <div className="col-lg-4 col-sm-6">
              <label>Show From</label>
              <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
            </div>
            <div className="col-lg-4 col-sm-6">
              <label>Through</label>
              <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
            </div>
          </div>
          {this.state.events.length === 0 ? 
          (<strong>No timeline events occurred in that date range</strong>)
          :
          (
            <div className="row">
              <div className="col-lg-6 col-lg-offset-3 col-sm-12">
                <Timeline>
                  {this.state.events.map((event) => {
                    return (
                      <TimelineEvent 
                        key={event.id} 
                        title={event.title} 
                        createdAt={event.createdAt} 
                        icon={(<i className={`fas fa-${event.icon}`} />)}>
                      </TimelineEvent>
                    )
                  })}
                </Timeline>
              </div>
            </div>
            )}
        </Card>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateStart(newDate: moment.Moment){
    this.setState({start: newDate}, () => {
      this.setup();
    })
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({end: newDate}, () => {
      this.setup();
    })
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          start: this.state.start.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.end.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
        }
        const timelineResult = await TimelineAPI.getUserTimeline(this.props.clientId, data);
        const events: ITimelineEvent[] = [];
        for(const e of timelineResult.body.data){
          events.push(TimelineAPI.generateEventFromEntry(e));
        }
        this.setState({ loading: false, events });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

}