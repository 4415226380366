import * as React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { ActivitiesAPI } from "../../../api";
import { error, success } from "../../structure/Alert";
import { IActivity } from "src/api/activities";
import SingleActivityForm from "./SingleActivityForm";

interface IActivityListItemProps {
  activity: IActivity;
  categories: string[];
  onDelete: any;
  onEdit: any;
}

interface IActivityListItemState {
  loading: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
}

export default class ActivityListItem extends React.Component<IActivityListItemProps, IActivityListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showEditModal: false,
      showDeleteModal: false,
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.handleActivitySaved = this.handleActivitySaved.bind(this);
    
  }

  public render() {
    return (
      <div key={this.props.activity.id} className={this.props.activity.followUp === "active" ? "card activity-list-card activity-list-card-followup" : "card activity-list-card"}>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <strong>{this.props.activity.name}</strong>
            </div>
            <div className="col-lg-3 col-sm-12">
              {ActivitiesAPI.translateCategory(this.props.activity.category)}
            </div>
            <div className="col-lg-3 col-sm-12">
              {moment(this.props.activity.dateOccurred).format("MM/DD/YY")}
            </div>            
          </div>

          {this.props.activity.followUp !== "none" && (
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <span>Last Follow Up: {moment(this.props.activity.lastFollowedUpOn).format("MM/DD/YY")}</span>   
              </div>
              <div className="col-lg-4 col-sm-12">
                {this.props.activity.followUp === "completed" ? (<span>Follow up completed</span>) : (<span>Next Follow Up: {moment(this.props.activity.followUpOn).format("MM/DD/YY")}</span>)}  
              </div>
            </div>
          )}
          <div className="row" style={{marginTop: 10, marginBottom: 10}}>
            <div className="col-12">
              {this.props.activity.notes}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <strong>{this.props.activity.participantCount} participants</strong>
            </div>
            <div className="col-lg-3 col-sm-12">
              {this.props.activity.lengthInMinutes} minutes
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.props.activity.tags && this.props.activity.tags.join(", ")}
            </div>
            <div className="col-lg-1 col-sm-6">
              <span className="oi oi-pencil icon icon-primary" title="Edit Activity" onClick={this.toggleEditModal} />
            </div>
            <div className="col-lg-1 col-sm-6">
              <span className="oi oi-x icon icon-danger" title="Delete Activity" onClick={this.toggleDeleteModal} />
            </div>
          </div>
        </div>


        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete the activity named <strong>{this.props.activity.name}</strong>? This cannot be undone!
          </Modal.Body>
          <Modal.Footer>
            <div className="col-6">
              <button className="btn btn-block btn-danger" onClick={this.deleteActivity}>Delete</button>
            </div>
            <div className="col-6">
              <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
            </div>
          </Modal.Footer>
        </Modal>

        <SingleActivityForm
          show={this.state.showEditModal}
          mode="update"
          initialActivity={this.props.activity}
          onToggleForm={this.toggleEditModal}
          onSaveActivity={this.handleActivitySaved}
          activityCategories={this.props.categories}
        />
      </div>
    );
  }


  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private deleteActivity(){
    this.setState({ loading: true }, async () => {
      try{
        await ActivitiesAPI.deleteActivity(this.props.activity.id);
        success("Activity deleted");
        this.setState({ showDeleteModal: false });
        this.props.onDelete(this.props.activity);
      }catch(e){
        error("Could not delete that activity. Contact support");
        this.setState({loading: false});
      }
    });
  }

  private handleActivitySaved(activity: any){
    this.setState({
      showEditModal: false,
    }, () => {this.props.onEdit(activity)});
  }

}
